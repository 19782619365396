export class VendorDialogViewModel {
  isOpen: boolean;
  isUpdate: boolean;

  id: number;
  vendorName: string;
  vendorDescription: string;

  originalData: VendorDialogViewModel;

  isVendorNameEmpty: boolean;
}
