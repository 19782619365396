import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AlertModalService {
  private modalRef: NgbModalRef = {} as NgbModalRef;
  constructor(private modalService: NgbModal) { }

  openAlert(header: string, message: string, styleClass: string){
    try {
      this.modalRef.componentInstance.close ();
    } catch (error) {
      // Do nothing
    }
    this.modalRef = this.modalService.open(AlertModalComponent,{backdrop: 'static',
                                            size: 'sm', 
                                            backdropClass: 'translucent-backdrop',
                                            centered: true});
    this.modalRef.componentInstance.header = header;
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.styleClass = styleClass;
    this.modalRef.result.then((result)=>{
    }).catch((error)=>{
    });
  }
}
