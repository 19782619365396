import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { catchError } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VesselProductService {

  appReqHeader = new AppHttpHeader();
  accessPointUrl = this.appReqHeader.DomainURL + 'api/VesselProduct';
  
  constructor(private http: HttpClient, private shellService: ShellService) { }

  loadVesselProduct(searchObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/SearchVesselProduct', searchObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err)));
    
  }

  addVesselProductDetails(vesselProductViewModel: any) {
    return this.http.post<any>(this.accessPointUrl, vesselProductViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateVesselProduct(id: number, patchVesselProductViewModel: any) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.patch<any>(this.accessPointUrl + query, patchVesselProductViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteVesselProduct(id: number) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.delete<any>(this.accessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
