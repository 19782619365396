import { Component, OnInit } from '@angular/core';
//import { Table, TableModule } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { UserService, Book } from '../services/user.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  books: Book[] = new Array;
  // Grid
  cols:any[] = [];
  loading : boolean = false;
  pageno = 1;
  pagesize = 10;
  rowscustomno = 10;
  first = 0;
  pagenoexport = 0;
  pagesizeexport = 10;
  totalRecords : any = null;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.getUsersByQuerySet();
    
  }

  getUsersByQuerySet (field?:string,order?:number) {
    //this.querySet.receivedDate = this.InventoryLogs.value.FromDate;
    //this.querySet.toDate = this.InventoryLogs.value.ToDate;
    this.loading = true;
    this.userService.getBooks()//(this.querySet,this.pageno,this.pagesize,field,order)
    //.subscribe((data:PaginatedResult<Book[]>)=>{
      .then(books =>{  
    this.cols = [
        {field:'name',header:'Name',width: '120px'},
        {field:'author',header:'Author',width: '100px'},
        {field:'price',header:'Price($)',width: '100px', isNumber: true},
        //{field:'receivedDate',header:'Date Recieved',width: '120px', data: true, format: 'MM/dd/yyyy'},
        
      ];
      if(books){
        this.totalRecords = books.length;
        //this.totalRecords = data.pagination.TotalCount;
      }
      else{
        this.totalRecords = null;
      }
      this.books = books;
      this.loading = false;
    });
  }
  onLazyLoad(event:LazyLoadEvent){
    //determine the pageeeno and send that to backend 
    //this.pageno=Math.ceil(event.first/event.rows)+1 ;
    //this.pagesize=event.rows;
    if (event.multiSortMeta != null && event.multiSortMeta != undefined) {
     //this.getUsersByQuerySet ( event.multiSortMeta[0].field, event.multiSortMeta[0].order);
    } else {
     
      // call full data
    }
    
  }
}
