export class ZoneDialogViewModel {
  isOpen: boolean;
  isUpdate: boolean;

  id: number;
  zoneName: string;
  zoneDescription: string;
  
  originalData: ZoneDialogViewModel;

  isZoneNameEmpty: boolean;
}