import { Component, OnInit } from '@angular/core';
import { ShellService } from '../../services/shell.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {
  errorMessage: string = ""
  constructor(private shellService: ShellService) { }

  ngOnInit(): void {
    this.errorMessage = this.shellService.errorMessage;
    if (this.errorMessage == "" || this.errorMessage == undefined) {
      this.errorMessage = "Access Denied! Contact your system administrator."
    }
  }

}
