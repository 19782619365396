import { Compiler, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-future-outage-setup',
  templateUrl: './future-outage-setup.component.html',
  styleUrls: ['./future-outage-setup.component.css']
})
export class FutureOutageSetupComponent implements OnInit {
  @ViewChild("wizardContainer", { read: ViewContainerRef }) wizardContainer: any;
  
  page: string = "";
  routes = [
    'outage',
    'estimate-refractory-materials'
  ]
  constructor(private route: ActivatedRoute, private compiler: Compiler) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params.comp === pg)) {
        this.page = params.comp;
      }
    });
  }

  ngOnInit(): void {
  }
}
