import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ShellService } from 'src/app/shared/services/shell.service';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { Vendor } from '../models/vendor';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  appReqHeader = new AppHttpHeader();
  vendorAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/Vendor';
  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchVendors(searchObject: any) {
    return this.http.post<Vendor[]>(this.vendorAccessPointUrl +'/SearchVendor', searchObject, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addVendor(vendorObj: any) {
    return this.http.post<any>(this.vendorAccessPointUrl, vendorObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(vendorId: number, patchVendorViewModel: any) {
    var query = "";
    if (vendorId) {
      query = "/" + vendorId;
    }
    return this.http.patch<any>(this.vendorAccessPointUrl + query, patchVendorViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
