import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Site } from 'src/app/module/utilities/models/site';
import { Unit } from 'src/app/module/utilities/models/unit';
import { Outage } from 'src/app/module/utilities/utilities.module';
import { environment } from 'src/environments/environment';
import { roles } from '../../core/auth/app-role.const';
import { AppHttpHeader } from '../helper/app-http-header';
import { catchError, delay, takeUntil, timeout } from 'rxjs/operators';
import { errorMessage } from '../constant';
import { AlertModalService } from './alert-modal.service';

@Injectable({
  providedIn: 'root'
})

export class ShellService {
  appReqHeader = new AppHttpHeader();
  isSidebarVisible: boolean = false;
  errorMessage = "";
  globalLoadingSubject = new Subject<boolean>();
  preventUserAccess = new Subject<string>();
  //site : string = "NULL";
  globalSiteUnitOutageObject: GlobalSiteUnitOutage | any = null;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
  resetDropDownSubject = new Subject<any>();
  refreshFooterSubject = new Subject<Outage>();

  globalSiteUnitOutageUnset: Subject<boolean> = new Subject<boolean>();
  globalSiteUnitOutageSet: Subject<GlobalSiteUnitOutage> = new Subject<GlobalSiteUnitOutage>();

  constructor(private http: HttpClient, private alertService: AlertModalService, private injector: Injector) {
    this.alertService = this.injector.get(AlertModalService);
    this.sidebarVisibilityChange.subscribe((value) => {
      this.isSidebarVisible = value
    });
  }

  getStaticMenuItems() {
    return this.http.get<any>('assets/menu-schema.json')
      .toPromise()
  }

  showLoadingIndicator(show: boolean) {
    this.globalLoadingSubject.next(show);
  }

  toggleSidebarVisibility(close: boolean = false) {
    this.sidebarVisibilityChange.next(close ? false : !this.isSidebarVisible);
  }

  refreshFooter(outage: Outage) {
    this.refreshFooterSubject.next(outage);
  }

  setSite(site: Site) {
    this.globalSiteUnitOutageObject.siteId = site.id;
    this.globalSiteUnitOutageObject.siteName = site.name;
    this.globalSiteUnitOutageObject.countryId = site.countryId;
    this.globalSiteUnitOutageObject.unitId = 0;
    this.globalSiteUnitOutageObject.unitName = "";
    this.globalSiteUnitOutageObject.outageId = 0;
    this.globalSiteUnitOutageObject.outageName = "";
    this.saveGlobalSiteUnitOutage();
  }

  setUnit(unit: Unit) {
    this.globalSiteUnitOutageObject.unitId = unit.unitId;
    this.globalSiteUnitOutageObject.unitName = unit.unitName;
    this.globalSiteUnitOutageObject.outageId = 0;
    this.globalSiteUnitOutageObject.outageName = "";
    this.saveGlobalSiteUnitOutage();
  }

  setOutage(outage: Outage) {
    this.globalSiteUnitOutageObject.outageId = outage.id;
    this.globalSiteUnitOutageObject.outageName = outage.name;
    this.saveGlobalSiteUnitOutage();
  }

  setGlobalDetails(globalObj: GlobalSiteUnitOutage) {
    this.globalSiteUnitOutageObject = globalObj;
    this.saveGlobalSiteUnitOutage();
    this.siteUnitOutageModalClosed();
  }

  loadGlobalSiteUnitOutage(hidePrompt: boolean = false, outageRequired: boolean = true): GlobalSiteUnitOutage {
    if (this.globalSiteUnitOutageObject == undefined || this.globalSiteUnitOutageObject == null) {
      let json_data = localStorage.getItem('siteUnitOutageObj');
      this.globalSiteUnitOutageObject = JSON.parse(json_data ? json_data : '{}');
    }
    if ((this.globalSiteUnitOutageObject.siteId == undefined || this.globalSiteUnitOutageObject.siteId == 0 ||
        this.globalSiteUnitOutageObject.unitId == undefined || this.globalSiteUnitOutageObject.unitId == 0 ||
        (outageRequired && (this.globalSiteUnitOutageObject.outageId == undefined || this.globalSiteUnitOutageObject.outageId == 0))) &&
        !hidePrompt) {
        this.globalSiteUnitOutageUnset.next(outageRequired);
    }
    return this.globalSiteUnitOutageObject;
  }

  saveGlobalSiteUnitOutage(): void {
    localStorage.setItem('siteUnitOutageObj', JSON.stringify(this.globalSiteUnitOutageObject));
  }

  siteUnitOutageModalClosed(): void {
    this.globalSiteUnitOutageSet.next(this.globalSiteUnitOutageObject);
  }

  resetConfig(autoOpenModal: boolean = false, outageRequired: boolean = true) {
    localStorage.removeItem("siteUnitOutageObj");
    this.globalSiteUnitOutageObject = new GlobalSiteUnitOutage();
    // console.log(this.globalSiteUnitOutageObject)
    this.resetDropDownSubject.next();
    if (autoOpenModal) {
      this.globalSiteUnitOutageUnset.next(outageRequired);
    }
  }

  getClickEvent(): Observable<any> {
    return this.resetDropDownSubject.asObservable();
  }

  //set roles and access throughout the application
  role: string;
  setRoles(value: string) {
    this.role = value;
  }

  isAdmin() {
    if (this.role) {
      if (this.role == roles.Admin) { //check if given role matches with admin
        return true;
      }
    }
    return false;
  }

  private evaluateAccessLevel(role: string) : AccessLevel {
    switch (role) {
      case roles.ReadOnly:
        return AccessLevel.ReadOnly;
      case roles.Contractor:
        return AccessLevel.Contractor;
      case roles.BasicUser:
        return AccessLevel.BasicUser;
      case roles.Admin:
        return AccessLevel.Administrator;
    }
    return 0;
  }

  getAccessLevel(): Promise<AccessLevel> {
    var tries = 5;
    var exit_condition = () => this.role != undefined;
    return new Promise((resolve, reject) => {
      if (exit_condition()) {
        if (this.role != "") {
          resolve(this.evaluateAccessLevel(this.role));
        } else {
          this.showErrorScreen(errorMessage.rolesUndefined);
        }
      } else {
        var retry_loop = () => {
          if (tries > 0) {
            if (exit_condition()) {
              if (this.role != "") {
                resolve(this.evaluateAccessLevel(this.role));
              } else {
                this.showErrorScreen(errorMessage.rolesUndefined);
              }
            } else {
              tries--;
              setTimeout(retry_loop, 1000);
            }
          } else {
            this.showErrorScreen(errorMessage.msalTimeout);
          }
        }
        retry_loop();
      }
    })
  }

  showErrorScreen(message: string) {
    this.errorMessage = message;
    this.preventUserAccess.next(message);
  }

  createPatchArray(columnName: string, value: any) {
    return [
      {
        "value": value,
        "path": "/" + columnName,
        "op": "replace"
      }
    ]
  }

  showAlert(status: string, message: string) {
    this.alertService.openAlert(status, message, 'error')
  }

  handleError(errorResponse: HttpErrorResponse) {
    // console.log(errorResponse)
    if (errorResponse.error instanceof ProgressEvent) {
      if (errorResponse.status == 0) {
        this.showAlert("Aw snap!", "Connection to API server failed!");
      } else {
        this.showAlert(errorResponse.statusText, errorResponse.message);
      }
      return throwError(errorResponse);
    } else if (errorResponse.error instanceof ErrorEvent) {
      this.showAlert("Something went wrong!", "RMS app ran into an unexpected problem! A browser refresh is recommended.")
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(errorResponse.error.message);
    } else {
      var titleText = ""
      switch (errorResponse.status) {
        case 400:
          titleText = "Invalid Input!"
          break;
        case 401:
          titleText = "Access Denied!"
          break;
        case 403:
          titleText = "Forbidden Operation!"
          break;
        case 405:
          titleText = "Operation Not Allowed!"
          break;
        case 408:
          titleText = "Request Timed Out!"
          break;
        case 429:
          titleText = "API Server is Busy"
          break;
        case 500:
          titleText = "API Server Error!"
          break;
        case 501:
          titleText = "Feature Not Available"
          break;
        case 502:
          titleText = "Gateway Error!"
          break;
        case 503:
          titleText = "API Server Unavailable"
          break;
        default:
          titleText = "Error " + errorResponse.status;
      }
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,      
      this.showAlert(titleText, errorResponse.error)
      return throwError(errorResponse.error);
    }
  }

  ping() {
    return this.http.get(environment.WEBAPIURL + "api/Generic/Ping", { headers: this.appReqHeader.Headers }).pipe(
      timeout(10000),
      catchError(() => {
        return new Observable<null>()
      })
    )
  }
}

export class GlobalSiteUnitOutage {
  siteId: number
  siteName: string = ""

  countryId: number

  unitId: number
  unitName: string = ""

  outageId: number
  outageName: string = ""
}

export enum AccessLevel {
  NoAccess = 0,
  ReadOnly = 1,
  Contractor = 2,
  BasicUser = 3,
  Administrator = 4
}
