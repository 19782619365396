import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessLevel, ShellService } from 'src/app/shared/services/shell.service';

@Component({
  selector: 'app-calciner-setup',
  templateUrl: './calciner-setup.component.html',
  styleUrls: ['./calciner-setup.component.css']
})

export class CalcinerSetupComponent implements OnInit {
  page: string = "";
  routes = [
    'site',
    'unit',
    'unit-vessel',
    'unit-vessel-zone',
    'nominal-lining'
  ]

  accessLevel: AccessLevel;

  constructor(private route: ActivatedRoute, private shellService: ShellService) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params.comp === pg)) {
        this.page = params.comp;
      }
    });
  }

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
  }
}
