import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Outage } from '../models/outage';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShellService } from 'src/app/shared/services/shell.service';
import { PaginatedResult } from '../models/paginatedResult';
import { VesselEstimateRevision } from '../models/vesselEstimateRevision';
import { Vessel } from '../models/vessel';


@Injectable({
  providedIn: 'root'
})
export class OutageService {
  appReqHeader = new AppHttpHeader();
  URL = this.appReqHeader.DomainURL;

  //emit events to components which injected the service
  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getOutageDates() {
    var query = '';
    return this.http.get<Outage[]>(this.URL + 'Unit/Outage' + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getOutage(filterData: any, pageNumber?: number, pageSize?: number, sortField?: string, sortOrder?: number) {
    const paginatedResult:PaginatedResult<Outage[]> =new PaginatedResult<Outage[]>();
    let params=new HttpParams();
    if(pageNumber!=null&&pageSize!=null){
      params=params.append('PageNumber',pageNumber.toString());
      params=params.append('PageSize',pageSize.toString());
    }
    if(sortField!=null&&sortOrder!=null){
      params=params.append('SortField',sortField.toString());
      params=params.append('SortOrder',sortOrder.toString());
    }
    return this.http.post<Outage[]>(this.URL + "Outage/SearchOutage", filterData, {observe:'response', params, headers: this.appReqHeader.Headers}).pipe(
      map(response=>{
        paginatedResult.result=response.body != null ? response.body : [];
        if(response.headers.get('X-Pagination')!=null) {
          paginatedResult.pagination=JSON.parse(response.headers.get('X-Pagination') || '{}');
        }
        
        return paginatedResult;
      }), catchError (this.shellService.handleError)
    );
  }

  getOutageById(outageId: number) {
    return this.http.get<Outage>(this.URL + "Outage/" + outageId.toString(), { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(outageId: number, patchModel: any) {
    var query = "";
    if (outageId) {
      query = "/" + outageId;
    }
    return this.http.patch<any>(this.URL + "Outage" + query, patchModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addOutage(outage: Outage) {
    return this.http.post<any>(this.URL + "Outage/", outage, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getRevisions(outageId: number) {
    return this.http.get<VesselEstimateRevision[]>(this.URL + "Outage/" + outageId.toString() + "/Revisions",
    { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
