import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { interval, Observable, Subscription } from 'rxjs';
import { UniversalTimeService } from 'src/app/shared/services/universal-time.service';
import { AlertModalService } from 'src/app/shared/shared.module';
import { ShellService } from '../../../shared/services/shell.service';
import { ProductService } from '../../utilities/services/product.service';
import { UnitVesselZoneService } from '../../utilities/services/unit-vessel-zone.service';
import { UnitService } from '../../utilities/utilities.module';
import { BulkLoaderService } from '../services/bulk-loader.service';
import { DailyUsageService } from '../services/daily-usage.service';

@Component({
  selector: 'bulk-loader',
  templateUrl: './bulk-loader.component.html',
  styleUrls: ['./bulk-loader.component.css']
})
export class BulkLoaderComponent implements OnInit {
  vessels: any;
  zones: any;
  products: any;
  siteUnitOutageObj: any;
  uploadMode: string = "daily-usage";
  shifts: any;
  productTypes: any;
  jobTypes: any;
  bulkLoaderLogs: any[];
  selectedFile: any;
  selectedFilename: string = "";
  cols: any[];
  loading: boolean = false;

  vesselLoaded = false;
  zoneLoaded = false;
  productLoaded = false;
  productTypeLoaded = false;
  jobTypeLoaded = false;
  shiftLoaded = false;

  continuousPollingSub: Subscription
  allowContinuousPolling: boolean;
  continuousPollLimit: number;

  // Pagination
  pageNo: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  first: number = 0;

  constructor(private unitService: UnitService, private bulkLoaderService: BulkLoaderService,
    private shellService: ShellService, private zoneService: UnitVesselZoneService,
    private productService: ProductService,private dailyUsageService: DailyUsageService,
    private alertService: AlertModalService, private timeService: UniversalTimeService) { }

  ngOnInit(): void {
    this.siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage(false, false);
    this.shellService.showLoadingIndicator(true);
    this.getVessels(this.siteUnitOutageObj.unitId); 
    this.getZones();
    this.getProducts();
    this.getShifts();
    this.getProductTypes();
    this.getJobTypes();
    this.headerInitialize();
    this.loadBulkLoaderLogsGridData(); //#todo

    this.continuousPollLimit = 10;
    // this.continuousPollingSub = interval(30000).subscribe(() => {
    //   if (this.allowContinuousPolling &&
    //     this.pageNo == 1 &&
    //     this.continuousPollLimit > 0 &&
    //     this.bulkLoaderLogs &&
    //     this.bulkLoaderLogs.length > 0 &&
    //     (this.bulkLoaderLogs[0].status == 'Pending' ||
    //     this.bulkLoaderLogs[0].status == 'Validating')) this.pullLatestLog();
    // });
  }

  ngOnDestroy() {
    //this.continuousPollingSub.unsubscribe();
  }

  pullLatestLog() {
    this.continuousPollLimit--;
    this.bulkLoaderService.getLatestBulkLoaderLog().subscribe((response) => {
      if (response) {
        this.bulkLoaderLogs[0] = response;
      }
    })
  }

  onLazyLoad(event:LazyLoadEvent)
  {
    if (event != undefined && event.first != undefined && event.rows != undefined) {
      this.pageNo=Math.ceil(event.first/event.rows)+1 //determine the pageeeno and send that to backend 
      this.pageSize=event.rows;
        this.loadBulkLoaderLogsGridData ();
    }
  }

  checkIfAllDataLoaded() {
    if (this.vesselLoaded && this.zoneLoaded &&
      this.productLoaded && this.productTypeLoaded &&
      this.jobTypeLoaded && this.shiftLoaded) {
        this.shellService.showLoadingIndicator(false);
      }
  }

  //get only those vessel which associated to the selected unit
  getVessels(unitId: number) {
    this.vessels = [];
    this.unitService.getVesselByUnitId(unitId).subscribe(response => {
      if (response) {
        this.vessels = response;
      }
      this.vesselLoaded = true;
      this.checkIfAllDataLoaded();
    }, error => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  getZones() {
    this.zoneService.getZones().subscribe(response => {
      this.zones = response;
      this.zoneLoaded = true;
      this.checkIfAllDataLoaded();
    }, error => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  getProducts() {
    var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
    this.productService.getProducts(siteUnitOutageObj.countryId).subscribe(response => {
      this.products = response;
      this.productLoaded = true;
      this.checkIfAllDataLoaded();
    }, error => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  getShifts() {
    this.dailyUsageService.getShifts().subscribe((response) => {
      this.shifts = response;
      this.shiftLoaded = true;
      this.checkIfAllDataLoaded();
    }, error => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  getProductTypes(): void {
    this.productService.getProductTypes().subscribe((response) => {
      this.productTypes = response;
      this.productTypeLoaded = true;
      this.checkIfAllDataLoaded();
    }, error => {
      this.shellService.showLoadingIndicator(false);
    });
  }

  getJobTypes() {
    this.dailyUsageService.getJobTypes().subscribe((response) => {
      this.jobTypes = response;
      this.jobTypeLoaded = true;
      this.checkIfAllDataLoaded();
    }, error => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  downloadExcelSample() {
    var list = {
      vessels: this.vessels,
      zones: this.zones,
      shifts: this.shifts,
      products: this.products,
      productTypes: this.productTypes,
      jobTypes: this.jobTypes
    }
     
    var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
    this.bulkLoaderService.createWorkbook(list, siteUnitOutageObj, this.uploadMode);
  }

  scrollDown(event: Event | null) {
    (function smoothscroll() {
      var scrollTarget = document.getElementById('scroll-target');
      scrollTarget?.scrollIntoView({ behavior: 'smooth'});
    })();
    if (event != null) event.stopPropagation();
  }

  scrollUp(event: Event) {
    (function smoothscroll() {
      var scrollTarget = document.getElementById('scroll-target-top');
      scrollTarget?.scrollIntoView({ behavior: 'smooth'});
    })();
    event.stopPropagation();
  }

  uploadedFiles: any;

  getSelectedFileDetails(event: any) {
    this.uploadedFiles = [];
    var type = event.files[0].type;
    var size = event.files[0].size;
    size = Math.round(size / 1024); //get size in kb
    if (size > 7168) {  //7 mb is 7168  kb
      this.alertService.openAlert("Error!", "Maximum size limit exceeded!", "error");
      this.uploadedFiles = [];
      this.clear()
      return;
    }
    if (type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.selectedFile = event.files[0];
      this.selectedFilename = this.selectedFile.name;
      for (let file of event.files) {
        this.uploadedFiles.push(file);
      }
    } else {
      this.uploadedFiles = [];
      this.alertService.openAlert("Error!", "Not a valid excel file!", "error");
      this.clear()
    }
  }
 
  upload(): void {    
    if (this.uploadedFiles.length > 0) {
      var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
      var bulkLoaderLogs = {
        outageId: siteUnitOutageObj.outageId,
        bulkLoaderType: this.uploadMode,
        loadDate: new Date()
      }

      this.scrollDown(null);

      this.bulkLoaderService.uploadBulkLoaderExcel(this.uploadedFiles, bulkLoaderLogs).subscribe((response: any) => {
        this.loadBulkLoaderLogsGridData();
        this.alertService.openAlert("Uploaded!", "The file has been uploaded successfully", "success")
        this.clear();
        this.continuousPollLimit = 10;
      })
    }       
  }

  download(id: any) {
    this.bulkLoaderService.downloadBulkLoaderExcel(id);
  }

  clear(): void {
    this.selectedFilename = "";
    this.selectedFile = undefined;
    this.uploadedFiles = [];
  }
  //#region logs gridSiteName
  headerInitialize() {
    this.cols = [
      { field: 'bulkLoaderType', header: 'Type', width: '12%' },
      { field: 'siteName', header: 'Site', width: '8%' },
      { field: 'unitName', header: 'Unit', width: '8%' },
      { field: 'outageName', header: 'Outage', width: '14%' },
      { field: 'fileName', header: 'FileName', width: '36%', text_overflow: true },
      { field: 'status', header: 'Status', width: '18%' }];
  }

  getFormattedDate(date: any, format: string) {
    if (!date) {
      return ""
    }
    return this.timeService.convertFromUTC(date, format);
  }

  getFormattedResult(text: string, td: any) {
    var sanitizedText = text.replace(/<[^>]>?/gm, '')
    td.innerHTML = sanitizedText.replace(/ *; */g, '<br>')
  }

  loadBulkLoaderLogsGridData() {
    this.loading = true;
    this.allowContinuousPolling = false;
    this.bulkLoaderService.getBulkLoaderLogs(this.pageNo, this.pageSize).subscribe(response => {
      if (response) {
        this.bulkLoaderLogs = [];
        this.bulkLoaderLogs = response.result;
        this.totalCount = response.pagination.TotalCount;
      }
      this.loading = false;
      this.allowContinuousPolling = true;
    }, error => {
      this.loading = false;
      this.allowContinuousPolling = true;
    })
  }
  //#endregion
}
