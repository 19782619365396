import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertModalService } from '../../../shared/services/alert-modal.service';
import { AccessLevel, ShellService } from '../../../shared/services/shell.service';
import { Site } from '../models/site';
import { Unit } from '../models/unit';
import { SiteService } from '../services/site.service';
import { UnitService } from '../services/unit.service';
import { UnitVesselService } from '../services/unit-vessel.service';
import { SiteUnitVesselViewModel, Vessel } from '../models/vessel';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'unit-vessel',
  templateUrl: './unit-vessel.component.html',
  styleUrls: ['./unit-vessel.component.css']
})
export class UnitVesselComponent implements OnInit {
  //#region variable declaration
  vessels: SiteUnitVesselViewModel[];
  vesselsDropdownData: Vessel[];
  cols: any[];
  loading: boolean = false;
  units: Unit[];
  filterUnits: Unit[];
  sites: Site[];
  selectedSite: any;
  selectedUnit: any;
  selectedFilterSite: any;
  selectedFilterUnit: any;
  selectedVessel: any;
  vesselInput: string = "";
  vesselId: number;
  vesselName: string = "";
  isFilterModeOn: boolean = false;
  isActive: boolean = true; //default is true
  deleteObj: any;
  accessLevel: AccessLevel;
  startLoading: boolean = false;

  // Pagination
  pageNo: number = 1;
  pageSize: number = 8;
  totalCount: number = 0;
  first: number = 0;
  @ViewChild('dt') table_component: Table;
  //#endregion

  constructor(private vesselService: UnitVesselService,
    private alertService: AlertModalService,
    private siteService: SiteService,
    private shellService: ShellService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
    this.getSites();
    this.headerInitialize();
    this.loadVesselDropdown();

    this.shellService.globalSiteUnitOutageSet.subscribe((data) => {
      this.getSites();
    });
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'vesselName', header: 'Vessel' },
      { field: 'siteName', header: 'Site' },
      { field: 'unitName', header: 'Unit' },
    ];
  }

  loadVesselDropdown() {
    this.vesselService.getVessel().subscribe(response => {
      if (response) {
        this.vesselsDropdownData = response;
      }
    })
  }

  onLazyLoad(event:LazyLoadEvent)
  {
    if (event != undefined && event.first != undefined && event.rows != undefined) {
      this.pageNo=Math.ceil(event.first/event.rows)+1 //determine the pageeeno and send that to backend 
      this.pageSize=event.rows;
        this.filterRecords ();
    }
  }

  deleteRow(obj: any) {
    this.deleteObj = {};
    this.deleteObj = {
      vesselId: obj.vesselId,
      unitId: obj.siteUnitId
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this link? This will also delete the associated zones links, outages and nominal lining.',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        if (this.deleteObj.vesselId) {
          this.shellService.showLoadingIndicator(true);
          this.vesselService.deleteVessel(this.deleteObj.vesselId, this.deleteObj.unitId).subscribe(response => {
            this.shellService.showLoadingIndicator(false);
            this.alertService.openAlert("Success!", "Unit Vessel link deleted successfully!", "success");
            if(this.table_component) { this.table_component.reset(); }
          }, error => {
            this.shellService.showLoadingIndicator(false);
          })
        }
      }
    });
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this vessel?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }

        var vesselViewModel = patchArrayObj;
        if (vesselViewModel.length > 0) {
          this.shellService.showLoadingIndicator(true);
          this.vesselService.updateVesselUnitMap(obj.vesselId, obj.siteUnitId, vesselViewModel).subscribe(response => { //need to confirm for siteunitid
            this.shellService.showLoadingIndicator(false);
            this.alertService.openAlert("Activated!", "Unit-vessel link activated successfully!", "success");
            if (this.table_component) { this.table_component.reset(); }
          }, error => {
            this.shellService.showLoadingIndicator(false);
            if (this.table_component) { this.table_component.reset(); }
          })
        }
      }
    });
  }

  editRow(obj: any) {
    this.vesselId = obj.vesselId;
    if (obj.siteName) { //check if sitename exists for vessel row
      let index = this.sites.findIndex(x => x.name == obj.siteName);
      this.selectedSite = this.sites[index];
      var siteId = this.sites[index].id;
      this.getUnits(siteId,'dialog', obj.unitName);   //setting up data into units dropdown based on site      
    } else {
      this.selectedSite = [];
      this.selectedUnit = [];
    }
    this.vesselName = obj.vesselName;
    this.vesselDialog.isUpdate = true;
    this.vesselDialog.opened = true;
  }

  vesselDialog = {
    opened: false,
    isSiteNameEmpty: false,
    isUnitEmpty: false,
    isUpdate: false,
    isVesselNameEmpty: false,
  }

  toggleVesselDialog(type: string) {
    this.vesselDialog.opened = !this.vesselDialog.opened;
    if (type == 'save') {
      this.vesselDialog.isUpdate = false;
      this.reset();
    }
  }

  reset() {
    this.selectedSite = [];
    this.selectedUnit = [];
    this.selectedVessel = [];
    this.vesselDialog.isSiteNameEmpty = false;
    this.vesselDialog.isUnitEmpty = false;
    this.vesselDialog.isVesselNameEmpty = false;
    this.vesselDialog.isUpdate = false;

  }

  addUnitVessel() {
    if (!this.validateVesselForm()) {
      return;
    }
    var vesselObj = {
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      siteUnitId: this.selectedUnit ? this.selectedUnit.unitId : 0,
      active: true
    }
    this.shellService.showLoadingIndicator(true);
    this.vesselService.addVesselUnitMap(vesselObj).subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      this.vesselDialog.opened = false;
      this.alertService.openAlert("Success!", "Saved successfully!", "success");
      if(this.table_component) { this.table_component.reset(); }
    }, error => {
      this.shellService.showLoadingIndicator(false);
      this.vesselDialog.opened = false;
    })
  }

  updateUnitVessel() {
    if (!this.validateVesselForm()) {
      return;
    }
    var siteUnitId = this.selectedUnit ? this.selectedUnit.unitId : 0;
    var tempPatchArrayObj = [];
    var patchArrayObj = [];
    tempPatchArrayObj.push(this.shellService.createPatchArray("vesselId", this.vesselId))
    tempPatchArrayObj.push(this.shellService.createPatchArray("siteUnitId", siteUnitId))

    for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
      patchArrayObj.push(tempPatchArrayObj[i][0]);
    }

    var vesselViewModel = patchArrayObj;
    if (vesselViewModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.vesselService.updateVesselUnitMap(this.vesselId, this.siteUnitId, vesselViewModel).subscribe(response => { //need to confirm for siteunitid
        this.shellService.showLoadingIndicator(false);
        this.toggleVesselDialog("");
        this.alertService.openAlert("Updated!", "Unit-vessel link updated successfully!", "success");
        if (this.table_component) { this.table_component.reset(); }
      }, error => {
        this.shellService.showLoadingIndicator(false);
        this.toggleVesselDialog("");
        if (this.table_component) { this.table_component.reset(); }
      })
    }
  }

  validateVesselForm() {
    var isValid = true;

    if (this.vesselDialog.isUpdate == false) {
      var vessalName = this.selectedVessel ? this.selectedVessel.name : "";
      if (vessalName) {
        this.vesselDialog.isVesselNameEmpty = false;
      } else {
        this.vesselDialog.isVesselNameEmpty = true;
        isValid = false;
      }
    } else {
      this.vesselDialog.isVesselNameEmpty = false;
    }

    var siteName = this.selectedSite ? this.selectedSite.name : "";
    if (siteName) {
      this.vesselDialog.isSiteNameEmpty = false;
    } else {
      this.vesselDialog.isSiteNameEmpty = true;
      isValid = false;
    }

    var unitName = this.selectedUnit ? this.selectedUnit.unitName : "";
    if (unitName) {
      this.vesselDialog.isUnitEmpty = false;
    } else {
      this.vesselDialog.isUnitEmpty = true;
      isValid = false;
    }
    return isValid;
  }

  //#region dropdown binding
  getSites() {
    this.shellService.showLoadingIndicator(true);
    this.siteService.getSites().subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      if (response) {
        this.sites = response;
        let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.siteId > 0) {
          this.selectedFilterSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
          this.getUnits(this.selectedFilterSite.id,"filter");
        } else {
          this.startLoading = true;
        }
      }
    }, error => {
      this.shellService.showLoadingIndicator(false);
    });
  }

  siteUnitId: number;
  getUnits(siteId: number, type: string, unitName?: string, isSiteChange?: boolean) {
    if (type == "filter") {
      this.filterUnits = [];
      this.selectedFilterUnit = {};
      this.vessels = [];
    } else {
      this.units = [];
      this.selectedUnit = {};
    }   
    this.siteService.getUnitBySiteId(siteId).subscribe(response => {
      if (response) {
        if (type == "filter") {
          this.filterUnits = response;
        } else {
          this.units = response;
        }
        if (unitName) {
          let rowIndex = this.units.findIndex(x => x.unitName == unitName);
          this.selectedUnit = this.units[rowIndex];
          this.siteUnitId = this.units[rowIndex].unitId;
        }
        else if (!isSiteChange) {
          let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
          if (siteUnitOutage.unitId > 0) {
            this.selectedFilterUnit = this.filterUnits.filter(u => u.unitId == siteUnitOutage.unitId)[0];
          }
          this.startLoading = true;
        }
      } 
    })
  }
  //#endregion

  //#region filter methods
  filterRecords() {
    this.vessels = [];
    let vesselViewModel = {
      vesselName: this.vesselInput,
      unitId: this.selectedFilterUnit ? this.selectedFilterUnit.unitId : 0,
      siteId: this.selectedFilterSite ? this.selectedFilterSite.id : 0,
      active: this.isActive,
    }
    this.loading = true;
    this.first = 0;
    this.vesselService.searchVessels(vesselViewModel, this.pageNo, this.pageSize).subscribe(response => {
      if (response) {
        // console.log(response.result)
        this.vessels = response.result;
        this.totalCount = response.pagination.TotalCount;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  resetFilterControls() {
    this.selectedFilterSite = [];
    this.selectedFilterUnit = [];
    this.isActive = true;
    if(this.table_component) { this.table_component.reset(); }
  }

  //show filter container
  showFilter() {
    this.isFilterModeOn = true;
  }

  //close filter container
  closeFilter() {
    this.isFilterModeOn = false;
  }

  //to fetch the units based on site selection
  onSiteChange(event: any, type: string) {
    if (event) {
      this.getUnits(event.value.id, type,"",true);
    }
  }
  //#endregion
}
