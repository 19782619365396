import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessLevel, ShellService } from '../../../shared/services/shell.service';
import { AlertModalService } from '../../../shared/shared.module';
import { Site } from '../models/site';
import { Unit } from '../models/unit';
import { Vessel } from '../models/vessel';
import { UnitVesselZoneViewModel, VesselZoneResponseViewModel } from '../models/zone';
import { SiteService } from '../services/site.service';
import { UnitVesselZoneService } from '../services/unit-vessel-zone.service';
import { UnitService } from '../services/unit.service';
import { UnitVesselService } from '../services/unit-vessel.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'unit-vessel-zone',
  templateUrl: './unit-vessel-zone.component.html',
  styleUrls: ['./unit-vessel-zone.component.css']
})

export class UnitVesselZoneComponent implements OnInit {

  zones: UnitVesselZoneViewModel[];
  cols: any[];
  selectedSite: any;
  selectedUnit: any;
  selectedVessel: any;
  isFilterModeOn: boolean = false;
  isActive: boolean = true; //default is true
  sites: Site[];
  units: Unit[];
  unitDropdownData: Unit[];
  vessels: Vessel[];
  vesselDropdownData: Vessel[];
  zonesDropdownData: Zone[];
  zoneInput: string = "";
  selectedSiteValue: any;
  selectedUnitValue: any;
  selectedVesselValue: any;
  zoneObj: UnitVesselZoneViewModel;
  isFilterDropdownLoaded: boolean = false;
  accessLevel: AccessLevel
  selectedZone: any;
  gridRowData: any;
  active: boolean = true;
  inactiveDate: Date = new Date();
  loading: boolean = false;
  startLoading: boolean = false;

  // Pagination
  pageNo: number = 1;
  pageSize: number = 8;
  totalCount: number = 0;
  first: number = 0;
  @ViewChild('dt') table_component: Table;

  constructor(private unitService: UnitService,
    private siteService: SiteService,
    private alertService: AlertModalService,
    private shellService: ShellService, private vesselService: UnitVesselService, private zoneService: UnitVesselZoneService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
    this.zoneObj = new UnitVesselZoneViewModel();   
    this.loadZoneDropdown();
    this.headerInitialize();
    this.getSites();
    this.shellService.globalSiteUnitOutageSet.subscribe((data) => {
      this.startLoading = false;
      this.getSites();
    });
  }

  headerInitialize() {
    this.cols = [
      { field: 'zoneName', header: 'Zone', width: '12%' },
      { field: 'siteName', header: 'Site', width: '4%' },
      { field: 'unitName', header: 'Unit', width: '14%' },
      { field: 'vesselName', header: 'Vessel', width: this.accessLevel == AccessLevel.Administrator ? '10%' : '13%'},
      { field: 'operatingTemperature', header: 'Operating Temperature', width: this.accessLevel == AccessLevel.Administrator ? '10%' : '12%' },
      { field: 'particulateLoading', header: 'Particulate Loading', width: this.accessLevel == AccessLevel.Administrator ? '13%' : '14%'},
      { field: 'velocity', header: 'Velocity', width: '5%' },
      { field: 'shellTemperature', header: 'Shell Temperature', width: '8%' },
      { field: 'hfGeometricCalcFactor', header: 'Hotface GCF', width: '10%' },
      { field: 'bkGeometricCalcFactor', header: 'Backup GCF', width: '8%' },
    ]
  }
  
  loadZoneDropdown() {
    this.zoneService.getZones().subscribe(response => {
      this.zonesDropdownData = response;
    })
  }
  
  //#region dropdown binding
  getSites() {
    this.siteService.getSites().subscribe(response => {
      if (response) {
        this.sites = response;
        let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.siteId > 0) {
          this.selectedSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
          this.getUnits(this.selectedSite.id);
        } else {
          this.startLoading = true;
        }
      }
      this.isFilterDropdownLoaded = true;
    });
  }

  getUnits(siteId: number) {
    this.units = [];
    this.siteService.getUnitBySiteId(siteId).subscribe(response => {
      if (response) {
        this.units = response;    
        let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.unitId > 0) {
          this.selectedUnit = this.units.filter(u => u.unitId == siteUnitOutage.unitId)[0];
          this.getVessel(this.selectedUnit.unitId);
        }    
        this.startLoading = true;
      }
    })
  }

  //get only those vessel which associated to the selected unit
  getVessel(unitId: number) {
    this.vessels = [];
    this.unitService.getVesselByUnitId(unitId).subscribe(response => {
      if (response) {
        this.vessels = response;
      }
    })
  }
  //#endregion

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }
 
  filterRecords() {
    let zoneSearchObj = {
      siteName: this.selectedSite ? this.selectedSite.name:"",
      unitName: this.selectedUnit ? this.selectedUnit.unitName : "",
      vesselName: this.selectedVessel ? this.selectedVessel.name : "",
      zoneName: this.zoneInput,
      active: this.isActive
    }    

    this.loading = true;
    this.first = 0;
    this.zoneService.search(zoneSearchObj, this.pageNo, this.pageSize).subscribe(response => {
      if (response) {
        // console.log(response)
        this.bindVesselZoneGrid(response.result);
        this.totalCount = response.pagination.TotalCount;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  //manipulate json API response data to show in grid
  tempArrayList: any = [];
  bindVesselZoneGrid(response: VesselZoneResponseViewModel[]) {
    this.tempArrayList = [];
    this.zones = [];
    if (response) {
      for (var i = 0; i < response.length; i++) {
        if (response[i].vesselUnit) {
          this.tempArrayList.push([{
            id: response[i].id,
            zoneId: response[i].vesselUnit.zoneId,
            zoneName: response[i].vesselUnit.zoneName,
            zoneActive: response[i].vesselUnit.zoneActive,
            siteId: response[i].vesselUnit.siteId,
            siteName: response[i].vesselUnit.siteName,
            siteActive: response[i].vesselUnit.siteActive,
            unitId: response[i].vesselUnit.unitId,
            unitName: response[i].vesselUnit.unitName,
            unitActive: response[i].vesselUnit.unitActive,
            vesselId: response[i].vesselUnit.vesselId,
            vesselName: response[i].vesselUnit.vesselName,
            vesselActive: response[i].vesselUnit.vesselActive,
            operatingTemperature: response[i].vesselUnit.operatingTemperature,
            particulateLoading: response[i].vesselUnit.particulateLoading,
            velocity: response[i].vesselUnit.velocity,
            shellTemperature: response[i].vesselUnit.shellTemperature,
            hfGeometricCalcFactor: response[i].vesselUnit.hfGeometricCalcFactor,
            bkGeometricCalcFactor: response[i].vesselUnit.bkGeometricCalcFactor,
            mor: response[i].vesselUnit.mor,
            coldCrush: response[i].vesselUnit.coldCrush,
            plc: response[i].vesselUnit.plc,
            abrasion: response[i].vesselUnit.abrasion,
            density: response[i].vesselUnit.density,
            thermalShock: response[i].vesselUnit.thermalShock,
            thermalExpansion: response[i].vesselUnit.thermalExpansion,
            active: response[i].vesselUnit.active
          }])
        } 
      }
    }
    if (this.tempArrayList.length > 0) {
      for (var i = 0; i < this.tempArrayList.length; i++) {
        this.zones.push(this.tempArrayList[i][0]);
      }
    }
    this.headerInitialize();
  }

  resetFilterControls() {
    this.selectedSite = [];
    this.selectedUnit = [];
    this.selectedVessel = [];
    this.zoneInput = "";
    this.isActive = true;
    this.loadZonesGridData()
  }

  onSiteChange(event: any) {
    if (event) {
      this.getUnits(event.value.id);
    }
  }

  onUnitChange(event: any) {
    if (event) {
      this.getVessel(event.value.unitId); 
    }
  }

  editRow(obj: any) {
    this.resetZoneDialog();  
    if (obj.zoneName) { //check if zone name exists
      let index = this.zonesDropdownData.findIndex(x => x.name == obj.zoneName);
      this.selectedZone = this.zonesDropdownData[index];
    }
    if (obj.siteName) { //check if sitename exists
      let index = this.sites.findIndex(x => x.name == obj.siteName);
      this.selectedSiteValue = this.sites[index];
      if (index > 0) {
        var siteId = this.sites[index].id;
        this.getUnitDropdownData(siteId, obj.unitName,obj.vesselName);  //this loads the unit/vessel data and autoselect in dropdown controls      
      }
    } else {
      this.selectedSite = [];
      this.selectedUnit = [];
    }
    this.zoneObj = obj;
    if (obj.active == false) {
      this.active = obj.active;
    }
    this.zoneDialog.isUpdate = true;
    this.zoneDialog.opened = true;
    this.setRowData(obj);
  }

  toggleZoneDialog() {
    this.zoneDialog.isUpdate = false;
    this.zoneDialog.opened = !this.zoneDialog.opened;
    if (this.isFilterDropdownLoaded == false) { //checks if site dropdown data is loaded initially
      this.getSites();
    }
    this.resetZoneDialog();  
  }

  zoneDialog = {
    opened: false,
    isSiteNameEmpty: false,
    isUnitEmpty: false,
    isZoneEmpty: false,
    isVesselEmpty: false,
    isUpdate: false,
  }

  onSiteDropdownChange(obj: any) {
    this.selectedUnitValue = [];
    if (obj.value.id) {
      this.getUnitDropdownData(obj.value.id);
    }    
  }

  getUnitDropdownData(siteId: number, unitName?: string, vesselName?: string) {
    this.unitDropdownData = [];
    this.siteService.getUnitBySiteId(siteId).subscribe(response => {
      if (response) {
        this.unitDropdownData = response;  
        this.selectedUnitValue = undefined;    
        if (unitName) {
          let rowIndex = this.unitDropdownData.findIndex(x => x.unitName == unitName);
          this.selectedUnitValue = this.unitDropdownData[rowIndex];
          let unitId = this.unitDropdownData[rowIndex].unitId;
          this.getVesselDropdownData(unitId, vesselName);
        }
      }
    })
  }

  onUnitDropdownChange(obj: any) {
    this.selectedVesselValue = [];
    if (obj.value.unitId) {
      this.getVesselDropdownData(obj.value.unitId);
    }    
  }

  getVesselDropdownData(unitId: number, vesselName?: string) {
    this.vesselDropdownData = [];
    this.unitService.getVesselByUnitId(unitId).subscribe(response => {
      if (response) {
        this.vesselDropdownData = response;
        this.selectedVesselValue = undefined
        if (vesselName) { //to autoselect vessel name in dropdown
          let rowIndex = this.vesselDropdownData.findIndex(x => x.name == vesselName);
          this.selectedVesselValue = this.vesselDropdownData[rowIndex];         
        }
      }
    })
  }

  resetZoneDialog() {
    this.zoneObj = new UnitVesselZoneViewModel();
    this.selectedZone= [];
    if (!this.zoneDialog.isUpdate) {
      this.selectedSiteValue= [];
      this.selectedUnitValue = [];
      this.selectedVesselValue = [];
    }
    this.zoneDialog.isSiteNameEmpty = false;
    this.zoneDialog.isUnitEmpty = false;
    this.zoneDialog.isVesselEmpty = false;
    this.zoneDialog.isZoneEmpty = false;
    this.zoneDialog.isVesselEmpty = false;
    this.gridRowData = {};
    this.active = true;   
  }

  //link the zone with vessel/unit
  addVesselZoneMap() {
    if (!this.validateZone()) {
      return;
    }
    let zoneViewModel= {
      unitId: this.selectedUnitValue ? this.selectedUnitValue.unitId : "",
      unitName: this.selectedUnitValue ? this.selectedUnitValue.unitName:"",
      vesselId: this.selectedVesselValue ? this.selectedVesselValue.id : "",
      vesselName: this.selectedVesselValue?this.selectedVesselValue.name:"",     
      zoneId: this.selectedZone ? this.selectedZone.id : 0,
      zoneName: this.selectedZone ? this.selectedZone.name : "",
      operatingTemperature: this.zoneObj.operatingTemperature,
      particulateLoading: this.zoneObj.particulateLoading,
      velocity: this.zoneObj.velocity,
      shellTemperature: this.zoneObj.shellTemperature,
      hFGeometricCalcFactor: this.zoneObj.hfGeometricCalcFactor,
      bKGeometricCalcFactor: this.zoneObj.bkGeometricCalcFactor,
      mor: this.zoneObj.mor,
      coldCrush: this.zoneObj.coldCrush,
      plc: this.zoneObj.plc,
      abrasion: this.zoneObj.abrasion,
      density: this.zoneObj.density,
      thermalShock: this.zoneObj.thermalShock,
      thermalExpansion: this.zoneObj.thermalExpansion,
      active: true, //default is true
      inactiveDate:null,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.zoneService.addZone(zoneViewModel).subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      this.alertService.openAlert("Saved!", "Unit-Vessel-Zone link added successfully!", "success");
      this.zoneDialog.opened = false;
      this.loadZonesGridData();
      this.resetZoneDialog();
    }, error => {
      this.shellService.showLoadingIndicator(false);
      this.zoneDialog.opened = false;
      this.loadZonesGridData();
    })
  }

  //sets the original grid data 
  setRowData(data: any) {
    this.gridRowData = {
      siteId: data.siteId,
      siteName: data.siteName,
      unitId: data.unitId,
      unitName: data.unitName,
      vesselId: data.vesselId,
      vesselName: data.vesselName,
      zoneId: data.zoneId,
      zoneName: data.zoneName,
      operatingTemperature: data.operatingTemperature,
      particulateLoading: data.particulateLoading,
      velocity: data.velocity,
      shellTemperature: data.shellTemperature,
      hFGeometricCalcFactor: data.hfGeometricCalcFactor,
      bKGeometricCalcFactor: data.bkGeometricCalcFactor,
      mor: data.mor,
      coldCrush: data.coldCrush,
      plc: data.plc,
      abrasion: data.abrasion,
      density: data.density,
      thermalShock: data.thermalShock,
      thermalExpansion: data.thermalExpansion,
    }
  }  
 
  //create array only for modified fields
  tempPatchArrayObj: any = [];
  isZoneChange: any;
  bindZonePatchObject(zone: any) {
    this.isZoneChange = false;
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (zone.siteId != this.gridRowData.siteId && zone.siteId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("siteId", zone.siteId))
    }
    if (zone.siteName != this.gridRowData.siteName && zone.siteName != "") {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("siteName", zone.siteName))
    }    
    if (zone.unitId != this.gridRowData.unitId && zone.unitId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("unitId", zone.unitId))
    }
    if (zone.unitName != this.gridRowData.unitName && zone.unitName != "") {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("unitName", zone.unitName))
    }
    if (zone.vesselId != this.gridRowData.vesselId && zone.vesselId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("vesselId", zone.vesselId))
    }
    if (zone.vesselName != this.gridRowData.vesselName && zone.vesselName != "") {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("vesselName", zone.vesselName))
    }
    if (zone.zoneId != this.gridRowData.zoneId && zone.zoneId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("zoneId", zone.zoneId))
      this.isZoneChange = true;
    }
    if (zone.zoneName != this.gridRowData.zoneName && zone.zoneName != "") {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("zoneName", zone.zoneName))
      this.isZoneChange = true;
    }
    if (this.zoneObj.operatingTemperature != this.gridRowData.operatingTemperature) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("operatingTemperature", this.zoneObj.operatingTemperature))
    }
    if (this.zoneObj.particulateLoading != this.gridRowData.particulateLoading) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("particulateLoading", this.zoneObj.particulateLoading))
    }    
    if (this.zoneObj.velocity != this.gridRowData.velocity) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("velocity", this.zoneObj.velocity))
    }
    if (this.zoneObj.shellTemperature != this.gridRowData.shellTemperature) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("shellTemperature", this.zoneObj.shellTemperature))
    }
    if (this.zoneObj.hfGeometricCalcFactor != this.gridRowData.hFGeometricCalcFactor) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("hFGeometricCalcFactor", this.zoneObj.hfGeometricCalcFactor))
    }
    if (this.zoneObj.bkGeometricCalcFactor != this.gridRowData.bKGeometricCalcFactor) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("bKGeometricCalcFactor", this.zoneObj.bkGeometricCalcFactor))
    }
    if (this.zoneObj.mor != this.gridRowData.mor) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("mor", this.zoneObj.mor))
    }
    if (this.zoneObj.coldCrush != this.gridRowData.coldCrush) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("coldCrush", this.zoneObj.coldCrush))
    }
    if (this.zoneObj.plc != this.gridRowData.plc) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("plc", this.zoneObj.plc))
    }
    if (this.zoneObj.abrasion != this.gridRowData.abrasion) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("abrasion", this.zoneObj.abrasion))
    }
    if (this.zoneObj.density != this.gridRowData.density) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("density", this.zoneObj.density))
    }
    if (this.zoneObj.thermalShock != this.gridRowData.thermalShock) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("thermalShock", this.zoneObj.thermalShock))
    }
    if (this.zoneObj.thermalExpansion != this.gridRowData.thermalExpansion) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("thermalExpansion", this.zoneObj.thermalExpansion))
    }   
    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("active", this.active))
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  updateVesselZoneMap() {
    debugger;
    if (!this.validateZone()) {
      return;
    }
    let zone = {
      siteId: this.selectedSiteValue ? this.selectedSiteValue.id : "",
      siteName: this.selectedSiteValue ? this.selectedSiteValue.name : "",
      unitId: this.selectedUnitValue ? this.selectedUnitValue.unitId : "",
      unitName: this.selectedUnitValue ? this.selectedUnitValue.unitName : "",
      vesselId: this.selectedVesselValue ? this.selectedVesselValue.id : "",
      vesselName: this.selectedVesselValue ? this.selectedVesselValue.name : "",
      zoneId: this.selectedZone ? this.selectedZone.id : 0,
      zoneName: this.selectedZone ? this.selectedZone.name : "",
    }
    var zonePatchViewModel = this.bindZonePatchObject(zone);
    if (zonePatchViewModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.zoneService.updateZone(this.gridRowData, zonePatchViewModel, this.isZoneChange).subscribe(response => {
        this.shellService.showLoadingIndicator(false);
        this.alertService.openAlert("Saved!", "Unit-Vessel-Zone link updated successfully!", "success");
        this.zoneDialog.opened = false;        
        this.resetZoneDialog();
        this.loadZonesGridData();
      }, error => {
        this.shellService.showLoadingIndicator(false);
        this.resetZoneDialog();
        this.zoneDialog.opened = false;
        this.loadZonesGridData();
      })
    } else {
      this.alertService.openAlert("Update Failed!", "There's nothing to update", "warning")
      this.zoneDialog.opened = false;
    }
  }

  loadZonesGridData() {
    this.first = 0
    // var siteName = this.selectedSite ? this.selectedSite.name : "";
    // if (siteName || this.zoneInput) { 
    //   if (this.startLoading == true) {
    //     this.startLoading = false;
    //     setTimeout(() => {
    //       this.startLoading = true;
    //     }, 200);
    //   }
    // } else { //reset the grid if there's no filter selection 
    //   this.zones = [];
    // }

    if (this.startLoading == true) {
      this.startLoading = false;
      setTimeout(() => {
        this.startLoading = true;
      }, 200);
    }
  }

  onLazyLoad(event:LazyLoadEvent)
  {
    if (event != undefined && event.first != undefined && event.rows != undefined) {
      this.pageNo=Math.ceil(event.first/event.rows)+1 //determine the pageeeno and send that to backend 
      this.pageSize=event.rows;
        this.filterRecords ();
    }
  }

  //validate zone dialog controls
  validateZone() {
    var isValid = true;
    var siteName = this.selectedSiteValue ? this.selectedSiteValue.name : "";
    if (siteName) {
      this.zoneDialog.isSiteNameEmpty = false;
    } else {
      if (this.zoneDialog.isUpdate && !this.zoneObj.siteActive) {
        this.zoneDialog.isSiteNameEmpty = false;
      } else {
        this.zoneDialog.isSiteNameEmpty = true;
        isValid = false;
      }
    }

    var unitName = this.selectedUnitValue ? this.selectedUnitValue.unitName : "";
    if (unitName) {
      this.zoneDialog.isUnitEmpty = false;
    } else {
      if (this.zoneDialog.isUpdate && !this.zoneObj.unitActive) {
        this.zoneDialog.isUnitEmpty = false;
      } else {
        this.zoneDialog.isUnitEmpty = true;
        isValid = false;
      }
    }

    var vesselName = this.selectedVesselValue ? this.selectedVesselValue.name : "";
    if (vesselName) {
      this.zoneDialog.isVesselEmpty = false;
    } else {
      if (this.zoneDialog.isUpdate) {
        this.zoneDialog.isVesselEmpty = false;
      } else {
        this.zoneDialog.isVesselEmpty = true;
        isValid = false;
      }
    }

    var zone = this.selectedZone ? this.selectedZone.name : "";
    if (zone) {
      this.zoneDialog.isZoneEmpty = false;
    } else {
      if (this.zoneDialog.isUpdate && !this.zoneObj.zoneActive) {
        this.zoneDialog.isZoneEmpty = false;
      } else {
        this.zoneDialog.isZoneEmpty = true;
        isValid = false;
      }
    }    
    return isValid;
  }

  params: any;
  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this zone map?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];

        this.params = {};
        this.params = {
          vesselId: obj.vesselId,
          unitId: obj.unitId,
          zoneId: obj.zoneId
        }
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }

        var zoneViewModel = patchArrayObj;
        if (zoneViewModel.length > 0) {
          this.shellService.showLoadingIndicator(true);
          this.zoneService.updateZone(this.params,zoneViewModel,false).subscribe(response => {
            this.shellService.showLoadingIndicator(false);
            this.alertService.openAlert("Activated!", "Unit-vessel-zone map activated successfully!", "success");
            if (this.table_component) { this.table_component.reset(); }
          }, error => {
            this.shellService.showLoadingIndicator(false);
            if (this.table_component) { this.table_component.reset(); }
          })
        }
      }
    });
  }

  deleteObj: any;
  deleteZoneMapRow(obj: any) {
    this.deleteObj = {};
    this.deleteObj = {
      vesselId: obj.vesselId,
      unitId: obj.unitId,
      zoneId:obj.zoneId
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this unit vessel zone map?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        if (this.deleteObj.zoneId) {
          this.shellService.showLoadingIndicator(true);
          this.zoneService.deleteZoneMap(this.deleteObj.unitId, this.deleteObj.vesselId, this.deleteObj.zoneId).subscribe(response => {
            this.shellService.showLoadingIndicator(false);
            this.alertService.openAlert("Success!", "Unit Vessel Zone map deleted successfully!", "success");
            if (this.table_component) { this.table_component.reset(); }
          }, error => {
            this.shellService.showLoadingIndicator(false);
          })
        }
      }
    });
  }
}
