import  {  environment  }  from  '../../../environments/environment';
import  {  HttpHeaders,  HttpRequest  }  from  '@angular/common/http';

export  class AppHttpHeader  {

    public DomainURL:  string;
    public Headers: HttpHeaders;
    public FormHeaders: HttpHeaders;
    public graphHeaders: HttpHeaders;
    public MediaHeaders: HttpHeaders;
    public mediaFetchHeader: HttpHeaders;
    public mediaPostHeader: HttpHeaders;
    public token:  string;
    public HostURL:  string;
    public MediaURL: string;

    public  constructor() {
        this.DomainURL  =  environment.WEBAPIURL;
        this.HostURL  =  window.location.host;

        const headerDict  =  {
            'Content-Type':  'application/json',
            'Accept':  'application/json',
            'Access-Control-Allow-Origin':  '*',
            'Access-Control-Allow-Methods':  'GET, POST, PATCH, PUT, DELETE, OPTIONS',
         };

        const ftpHeaderDict = {
            'Accept':  'application/json',
            'Access-Control-Allow-Origin':  '*',
            'Access-Control-Allow-Methods':  'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        }

        const requestOptions  =  {
            headers:  new  HttpHeaders(headerDict),
        };

        this.Headers  =  requestOptions.headers; 
        this.FormHeaders = new HttpHeaders(ftpHeaderDict);      
    }
} 