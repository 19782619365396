import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';

import { HeaderbarComponent, SidebarComponent } from './shared/shared.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomePageComponent } from './module/home/home.module';
import { UserComponent } from './module/admin/admin.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SiteComponent } from './module/utilities/utilities.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { UnitComponent } from './module/utilities/unit/unit.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { UnitVesselComponent } from './module/utilities/unit-vessel/unit-vessel.component';
import { DialogModule } from 'primeng/dialog';
import { CalcinerSetupComponent } from './module/wizards/calciner-setup/calciner-setup.component';
import { UnitVesselZoneComponent } from './module/utilities/unit-vessel-zone/unit-vessel-zone.component';
import { AlertModalComponent } from './shared/components/alert-modal/alert-modal.component';
import { NominalLiningComponent } from './module/utilities/nominal-lining/nominal-lining.component';
import { OutageComponent } from './module/utilities/outage/outage.component';
import { EstimateRefractoryMaterialsComponent } from './module/utilities/estimate-refractory-materials/estimate-refractory-materials.component';
import { FutureOutageSetupComponent } from './module/wizards/future-outage-setup/future-outage-setup.component';
import { CategoryProductComponent } from './module/utilities/category-product/category-product.component';
import { InspectionComponent } from './module/data-entry/inspection/inspection.component';
import { ProductComponent } from './module/utilities/product/product.component';
import { DailyUsageComponent } from './module/data-entry/daily-usage/daily-usage.component';
import { VesselComponent } from './module/utilities/vessel/vessel.component';
import { ZoneComponent } from './module/utilities/zone/zone.component';
import { VendorComponent } from './module/utilities/vendor/vendor.component';
import { ShutdownExecutionComponent } from './module/wizards/shutdown-execution/shutdown-execution.component';
import { ShutdownReviewComponent } from './module/wizards/shutdown-review/shutdown-review.component';
import { BulkLoaderComponent } from './module/data-entry/bulk-loader/bulk-loader.component';
import { ReportsComponent } from './module/reports/reports.component';
import { ProductTypeComponent } from './module/utilities/product-type/product-type.component';
import { FlatoutEditorComponent } from './module/data-entry/flatout-editor/flatout-editor.component';
import { FlatoutUploaderComponent } from './module/data-entry/flatout-uploader/flatout-uploader.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUrl,
      authority: 'https://login.microsoftonline.com/' + environment.tenantId + '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          //comment out this line to check authentication step inside msal  
          //console.log(message);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(environment.WEBAPIURL , environment.scope);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: []
    }
  };
}



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderbarComponent,
    SidebarComponent,
    HomePageComponent,
    SiteComponent,
    OutageComponent,
    UnitComponent,
    UnitVesselComponent,
    UnitVesselZoneComponent,
    VesselComponent,
    ZoneComponent,
    VendorComponent,
    CalcinerSetupComponent,
    AlertModalComponent,
    NominalLiningComponent,
    EstimateRefractoryMaterialsComponent,
    FutureOutageSetupComponent,
    CategoryProductComponent,
    InspectionComponent,
    ProductComponent,
    DailyUsageComponent,
    ShutdownExecutionComponent,
    ShutdownReviewComponent,
    BulkLoaderComponent,
    ReportsComponent,
    ProductTypeComponent,
    FlatoutEditorComponent,
    FlatoutUploaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MenuModule,
    AppRoutingModule,
    NgbModule,
    MsalModule,
    RippleModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    InputTextModule,
    CheckboxModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    ButtonModule,
    RadioButtonModule,
    SelectButtonModule,
    InputTextareaModule,
    DropdownModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    HttpClientModule,
    FontAwesomeModule,
    InputSwitchModule,
    InputNumberModule,
    CalendarModule,
    DialogModule,
    GalleriaModule,
    ImageModule,
    TooltipModule,
    FileUploadModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  if (environment.environmentName != 'Prod') { console.log("Loading translations") }
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
