export class BatchUsageProductViewModel {
  id: number;  
  batchUsageId: number;
  productId: number;
  productPosition: string;
  qty: number;
  productCost: number;
  batchNumber: number;  
}

export class ApplicationMethod {
  id: number;
  name: string;  
}
