import { Component, OnInit } from '@angular/core';
import { AccessLevel, ShellService } from "../../services/shell.service";
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css', './sidebar.component.responsive.css']
})

export class SidebarComponent implements OnInit {
  menuStructure: any;
  secondaryMenuStructure: any;

  menuReady: boolean;
  subMenuName = "";

  currentUrl: string = "";

  // Authentication
  userRole = "";

  // Eye Candy
  secondaryMenuPositionOffset: number = 0;

  constructor(private shellService: ShellService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      switch(accessLevel) {
        case AccessLevel.Administrator:
          this.userRole = "Administrator";
          break;
        case AccessLevel.BasicUser:
          this.userRole = "Basic User";
          break;
        case AccessLevel.Contractor:
          this.userRole = "Contractor";
          break;
        case AccessLevel.ReadOnly:
          this.userRole = "ReadOnly";
          break;
      }
      this.shellService.getStaticMenuItems().then((response) => {
        this.menuStructure = response;
        this.shellService.sidebarVisibilityChange.subscribe((ready) => {
          this.menuReady = ready;
          this.subMenuName = "";
          this.secondaryMenuStructure = undefined;
        })
      })
    })
  }

  openSubMenu(name: string) {
    if (this.subMenuName != name) {
      this.subMenuName = name;
    } else {
      this.subMenuName = "";
    }
    this.secondaryMenuStructure = undefined;
  }

  stopClickPropagation(event: Event): void {
    event.stopPropagation()
  }
  routeNavigation(pageRoute: string, event: any) {
    this.stopClickPropagation(event);
    this.router.navigateByUrl("/" + pageRoute);
    this.secondaryMenuStructure = undefined;
    this.shellService.toggleSidebarVisibility();
  }

  showSecondaryMenu(data: any, event: any): void {
    this.stopClickPropagation(event);
    this.secondaryMenuPositionOffset = event.target.offsetTop - 8;
    this.secondaryMenuStructure = undefined;
    setTimeout(() => {
      this.secondaryMenuStructure = data;
    }, 200);
  }

  checkUrl(url: string, excludeUrl: string = ""): boolean {
    var token = "";
    if (url.includes("/*")) {
      token = url.replace("/*", "");
      return this.currentUrl.includes(token);
    }
    if (excludeUrl.includes("/*")) {
      token = excludeUrl.replace("/*", "");
      return this.currentUrl.replace(token, "") == url;
    }
    return this.currentUrl == token;
  }

  showHelpDocument() {
    const PDF = '../../../../assets/docs/manual.pdf'
    window.open(PDF)
  }
}
