import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Country } from 'src/app/module/utilities/models/country';
import { CountryService } from 'src/app/module/utilities/services/country.service';
import { Outage, OutageService, Site, SiteService, Unit, UnitService } from 'src/app/module/utilities/utilities.module';
import { environment } from 'src/environments/environment';
import { Config } from '../../models/config.model';
import { ShellService } from '../../services/shell.service';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  footerConfig: Config;
  countryFlagURI: string = "../../../assets/i18n/flags/"
  countryFlagExt: string = ".png"
  countryFlag: string;

  currentUrl = ""

  constructor(private shellService: ShellService,
              private countryService: CountryService,
              private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.footerConfig = new Config();
    this.footerConfig.version = environment.version;

    this.shellService.globalSiteUnitOutageSet.subscribe((data) => {
      this.footerConfig.siteName = data.siteName;
      this.setFlag(data.countryId);
      this.footerConfig.unitName = data.unitName;
      this.footerConfig.outageName = data.outageName;
    })

    this.shellService.getAccessLevel().then((accessLevel) => {
      if (accessLevel > 0) {
        let siteUnitOutageObject = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutageObject.siteId > 0) {
          this.footerConfig.siteName = siteUnitOutageObject.siteName;
          this.setFlag(siteUnitOutageObject.countryId);
        }
        if (siteUnitOutageObject.unitId > 0) {
          this.footerConfig.unitName = siteUnitOutageObject.unitName;
        }
        if (siteUnitOutageObject.outageId > 0) {
          this.footerConfig.outageName = siteUnitOutageObject.outageName;
        }
      }
    })

    this.shellService.refreshFooterSubject.subscribe((outage) => {
      this.footerConfig.outageName = outage.name;
    })
  }

  setFlag(countryID: number) {
    this.countryService.getCountries().subscribe((response: Country[]) => {
      if (response) {
        var country = response.filter(c => c.id == countryID)[0];
        this.countryFlag = country ? country.code : "";
      }
    });
  }

  clearConfigSelection(openModal: boolean = false) {
    this.footerConfig = new Config();
    this.footerConfig.version = environment.version;
    this.shellService.getAccessLevel().then((accessLevel) => {
      if (accessLevel > 0) {
        this.countryFlag = "";
        if (this.currentUrl === '/') {
          this.shellService.resetConfig(false);
        } else if (this.currentUrl === '/nominal-lining' || this.currentUrl === '/calciner-setup/nominal-lining') {
          this.shellService.resetConfig(false);
          this.shellService.loadGlobalSiteUnitOutage(false, false);
        } else {
          this.shellService.resetConfig(true);
        }
      }
    })
  }
}
