import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class UniversalTimeService {

  constructor() { }

  getUTCNow(): Date {
    return moment(new Date()).add(-(moment().utcOffset()), 'm').toDate()
  }

  convertFromUTC(utc: any, format: string = ""): any {
    if (format === "") {
      return moment(new Date(utc)).local();
    }
    return moment(new Date(utc)).local().format(format);
  }
}
