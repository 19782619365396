import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { catchError } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';
import { Product } from '../models/product';
import { CategoryType } from '../models/categoryType';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  appReqHeader = new AppHttpHeader();
  productAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/Product';
  productCategoryAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/ProductCategory';
  productTypeAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/ProductType';
  vendorAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/Vendor';
  
  constructor(private http: HttpClient, private shellService: ShellService) { }

  getProducts(countryId: number) {
    var query = "";    
    if (countryId) {
      query = query + "/" + countryId;
    }
    return this.http.get<Product[]>(this.productAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getProductsByProductType(productType: string, countryId: number) {
    var query = "";
    if (productType) {
      query = "/" + productType;
    }
    if (countryId) {
      query = query + "/" + countryId;
    }
    return this.http.get<Product[]>(this.productTypeAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getProductTypes() {
    return this.http.get<CategoryType[]>(this.productTypeAccessPointUrl,
      { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getCategoryByProductType(productTypeId: number) {
    var query = "";   
    if (productTypeId) {
      query = "";
      query = "/" + productTypeId+ "/Category";
    }
    return this.http.get<any>(this.productTypeAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err)));    
  }

  getProductByCategory(productCategoryId: number, countryId:number) {
    var query = "";
    if (productCategoryId) {
      query = "";
      query = "/" + productCategoryId + "/" + countryId + "/Product";
    }
    return this.http.get<any>(this.productCategoryAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  getVendor() {
    return this.http.get<any>(this.vendorAccessPointUrl,
      { headers: this.appReqHeader.Headers }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

  searchProducts(filterProductObj: any) {
    return this.http.post<any>(this.productAccessPointUrl +'/SearchProducts', filterProductObj, { headers: this.appReqHeader.Headers }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

  getCategoryAndVendor(productTypeId: number) {
    var query = "";
    if (productTypeId) {
      query = "/" + productTypeId + "/Category";
    }

    let categories = this.http.get<any>(this.productTypeAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
    
    let vendors = this.http.get<any>(this.vendorAccessPointUrl,
      { headers: this.appReqHeader.Headers }).pipe(
        catchError(err => this.shellService.handleError(err))
    );

    return forkJoin([categories, vendors]);
  }

  addProduct(productObj: any) {
    return this.http.post<any>(this.productAccessPointUrl, productObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateProduct(productId:number,productObj: any) {
    var query = "";
    if (productId) {
      query = "/" + productId;
    }
    return this.http.patch<any>(this.productAccessPointUrl + query, productObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addProductCost(productCostObj: any) {
    return this.http.post<any>(this.productAccessPointUrl + '/AddProductCost', productCostObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateProductCost(productCostId: number, productCostObj: any) {
    var query = "";
    if (productCostId) {
      query = "/" + productCostId;
    }
    return this.http.patch<any>(this.productAccessPointUrl + query+'/ProductCost', productCostObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteProduct(productId: number) {
    var query = "";
    if (productId) {
      query = "/" + productId;
    }
    return this.http.delete<any>(this.productAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
