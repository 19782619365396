import { NgModule } from '@angular/core';
import { RouterModule, Routes, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccessLevel, ShellService } from './shared/services/shell.service'
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Site } from './module/utilities/models/site';
import { Unit } from './module/utilities/models/unit';
import { Outage } from './module/utilities/models/outage';
import { SiteService, UnitService } from './module/utilities/utilities.module';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  title = 'Refractory Management System';
  sideBarVisible: boolean = false;
  isIframe = false;
  loginDisplay = false;
  isOffline: boolean = false;
  failureCount: number = 0;
  private readonly _destroying$ = new Subject<void>();

  loggedinUserName: string = "RMS USER";
  _profileInitials: string = "USER";
  userImage: boolean = true;
  responseData: string = "";
  errorMessage: string = "";
  currentUrl = "";

  /*Page loading variables */
  public loading: boolean = false;
  public launcherExpand: boolean = true;

  // Global Parameters
  isSiteUnitOutageModalOpen = false
  showGlobalOutageDropdown = true
  modalLoading: boolean = false;

  globalSites: Site[]
  globalUnits: Unit[]
  globalOutages: Outage[]

  selectedGlobalSite: Site | any
  selectedGlobalUnit: Unit | any
  selectedGlobalOutage: Outage | any

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private activatedRoute: ActivatedRoute,
    private shellService: ShellService,
    public translate: TranslateService,
    private primengConfig: PrimeNGConfig,
    // For data
    private siteService: SiteService,
    private unitService: UnitService,
    private router: Router) {
    this.isOffline = false;
    this.sideBarVisible = this.shellService.isSidebarVisible;

    this.shellService.sidebarVisibilityChange.subscribe((value) => {
      this.sideBarVisible = value;
    });

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    this.translate.use("en");

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.primengConfig.ripple = true;
   
    this.shellService.loadGlobalSiteUnitOutage();
    this.shellService.globalLoadingSubject.subscribe((showLoading) => {
      this.loading = showLoading;
    });
    this.shellService.globalSiteUnitOutageUnset.subscribe((showOutage) => {
      this.showGlobalOutageDropdown = showOutage;
      this.openSiteUnitOutageDialog();
    });

    this.pingCycle();
  }

  private swipeCoord: [number, number] = [0, 0]
  private swipeTime: number = 0
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      // Check horizontal swipe
      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        this.shellService.getAccessLevel().then((accessLevel) => {
          if (accessLevel > AccessLevel.NoAccess) {
            this.shellService.toggleSidebarVisibility(direction[0] < 0);
          }
        })
      }
    }
  }

  public getData() {
    this.loading = true;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  redirectToHome() {
  }

  // to switch or change language
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  showLauncher() {
    this.launcherExpand = true;
  }

  hideLauncher() {
    this.launcherExpand = false;
  }

  loadMenu() {

  }

  closeSideBar(): void {
    this.shellService.toggleSidebarVisibility()
  }

  onGlobalSiteChange(site: any): void {
    this.selectedGlobalUnit = undefined;
    this.globalUnits = [];
    this.siteService.getUnitBySiteId(site.id).subscribe(
      response => {
        for (var unit in response) {
          this.globalUnits.push(response[unit]);
        }
        let unitId = Number(this.activatedRoute.snapshot.queryParamMap.get('unitId'))
        if (unitId > 0) {
          let requestedUnit = this.globalUnits.find((unit) => { return unit.unitId === unitId })
          if (requestedUnit) {
            this.selectedGlobalUnit = requestedUnit
            this.onGlobalUnitChange(requestedUnit)
          }
          if (this.currentUrl.includes ("calciner-setup/nominal-lining") ||
            this.currentUrl.includes ("shutdown-execution/inspection")) {
            if (this.activatedRoute.snapshot.queryParamMap.get('vesselId') == null && 
            this.activatedRoute.snapshot.queryParamMap.get('outageId') == null) {
              var qIndex = this.currentUrl.indexOf("?");
              console.log (this.currentUrl.substring (0, qIndex));
              window.history.replaceState({}, document.title, "/#" + this.currentUrl.substring (0, qIndex));
            }
          } else {
            var qIndex = this.currentUrl.indexOf("?");
            console.log (this.currentUrl.substring (0, qIndex));
            window.history.replaceState({}, document.title, "/#" + this.currentUrl.substring (0, qIndex));
          }
        }
      }
   
    );
    this.shellService.setSite(site);
  }

  onGlobalUnitChange(unit: any): void {
    this.selectedGlobalOutage = undefined;
    if (this.showGlobalOutageDropdown) {
      this.globalOutages = [];
      this.unitService.getOutageByUnitId(unit.unitId).subscribe(
        response => {
          for (var outage in response) {
            if (this.currentUrl === '/estimate-refractory-materials' || this.currentUrl === '/future-outage-setup/estimate-refractory-materials') {
              var checkOutageName = response[outage].name.split('-');
              var unitoutageNameType = checkOutageName[1].trim();

              if (unitoutageNameType != 'Nominal Lining') {

                this.globalOutages.push(response[outage]);
              }
            }
            else {
              this.globalOutages.push(response[outage]);
            }
          }
          let queryOutage=Number(this.activatedRoute.snapshot.queryParamMap.get('outageId'))
          if(queryOutage>0){
            let requiredOutage = this.globalOutages.find(outage=>{return outage.id===queryOutage})
            if(requiredOutage){
              this.selectedGlobalOutage=requiredOutage
              this.onGlobalOutageChange(requiredOutage)
            }
            if (this.currentUrl.includes ("calciner-setup/nominal-lining") ||
            this.currentUrl.includes ("shutdown-execution/inspection")) {
              if (this.activatedRoute.snapshot.queryParamMap.get('vesselId') == null) {
                var qIndex = this.currentUrl.indexOf("?");
                console.log (this.currentUrl.substring (0, qIndex));
                window.history.replaceState({}, document.title, "/#" + this.currentUrl.substring (0, qIndex));
              }
            } else {
              var qIndex = this.currentUrl.indexOf("?");
              console.log (this.currentUrl.substring (0, qIndex));
              window.history.replaceState({}, document.title, "/#" + this.currentUrl.substring (0, qIndex));
            }
          }
        }
      );
    }
    this.shellService.setUnit(unit);
  }

  onGlobalOutageChange(outage: any): void {
    if (this.showGlobalOutageDropdown) {
      this.shellService.setOutage(outage);
    }
  }

  openSiteUnitOutageDialog(): void {
    if (!this.isSiteUnitOutageModalOpen) {
      this.modalLoading = true;
      this.isSiteUnitOutageModalOpen = true;
      this.siteService.getSites().subscribe(
        response => {
          this.selectedGlobalSite = undefined;
          this.globalSites = [];
          this.selectedGlobalUnit = undefined;
          this.globalUnits = [];
          this.selectedGlobalOutage = undefined;
          this.globalOutages = []
          for (var site in response) {
            this.globalSites.push(response[site]);
          }
          let siteId = Number(this.activatedRoute.snapshot.queryParamMap.get('siteId'))
          if (siteId > 0) {
            let requestedSite = this.globalSites.find((site) => {
              return site.id === siteId;
            });
            if (requestedSite) {
              this.selectedGlobalSite = requestedSite;
              this.onGlobalSiteChange(requestedSite);
            }
            if (
              this.currentUrl.includes('calciner-setup/nominal-lining') ||
              this.currentUrl.includes('shutdown-execution/inspection')
            ) {
              if (
                this.activatedRoute.snapshot.queryParamMap.get('vesselId') ==
                  null &&
                this.activatedRoute.snapshot.queryParamMap.get('outageId') ==
                  null &&
                this.activatedRoute.snapshot.queryParamMap.get('unitId') ==
                  null
              ) {
                var qIndex = this.currentUrl.indexOf('?');
                console.log(this.currentUrl.substring(0, qIndex));
                window.history.replaceState(
                  {},
                  document.title,
                  '/#' + this.currentUrl.substring(0, qIndex)
                );
              }
            } else {
              var qIndex = this.currentUrl.indexOf('?');
              console.log(this.currentUrl.substring(0, qIndex));
              window.history.replaceState(
                {},
                document.title,
                '/#' + this.currentUrl.substring(0, qIndex)
              );
            }
          }
          this.modalLoading = false;
        }
      );
    }
  }

  closeSiteUnitOutageDialog(): void {
    this.isSiteUnitOutageModalOpen = false;
    this.shellService.siteUnitOutageModalClosed();
    window.location.reload();
  }

  pingCycle(): void {
    if (environment.environmentName != 'Local') {
      interval(30000).subscribe(() => {
        this.ping();
      });
    }
  }

  ping(): void {
    try {
      var gotResponse = false;
      if (environment.environmentName != 'Prod' && environment.environmentName != 'Test') { console.log("Pinging API server...") }
      var currentTime = new Date().getTime();
      var sub = this.shellService.ping().subscribe((res) => {
        if (environment.environmentName != 'Prod' && environment.environmentName != 'Test') { console.log("Request reached API server after " + (new Date(res as string).getTime() - currentTime) + "ms") }
        gotResponse = true;
        this.isOffline = false;
      }, (err) => {
        if (environment.environmentName != 'Prod') { console.log(err) }
        if (err) {
          this.isOffline = err.status != 200;
        }
        gotResponse = !this.isOffline;
      }, () => {
        if (environment.environmentName != 'Prod') { console.log("Got response after " + (new Date().getTime() - currentTime) + "ms") }
        gotResponse = true;
      })
      setTimeout(() => {
        sub.unsubscribe();
        if (!gotResponse) {
          if (environment.environmentName != 'Prod') { console.log("Got no response from API server") }
          this.failureCount++;
          if (this.failureCount > 2) {
            this.isOffline = true;
          }
        } else {
          this.failureCount = 0;
        }
      }, 10000);
    } catch (e) {
      if (environment.environmentName != 'Prod') { console.log("Got error response " + e) }
      this.failureCount++;
    }
  }
}
