// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { FooterComponent } from './footer/footer.component';
export * from './components/footer/footer.component';
export * from './components/headerbar/headerbar.component';
export * from './components/sidebar/sidebar.component';
export * from './helper/app-http-header';
export * from './services/alert-modal.service';
/*
The shared module contains classes and resources which are used in more than one dynamically loaded module/component. 
By always loading with the application the shared components are ready whenever a module requests them.
*/

// @NgModule({
//   declarations: [
//     FooterComponent
//   ],
//   imports: [
//     CommonModule
//   ]
// })
// export class SharedModule { }
