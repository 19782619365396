import { Site } from "src/app/module/utilities/models/site";
import { Unit } from "src/app/module/utilities/models/unit";
import { Outage } from "src/app/module/utilities/utilities.module";

export class Config {
    version: string;
    country: string;
    siteName: string;
    unitName: string;
    outageName: string
    constructor(){
        this.siteName = "";
        this.unitName = "";
        this.outageName = "";
    }

}