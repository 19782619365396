export class PaginatedResult<T> {
    result: T;
    pagination: Pagination;
}

interface Pagination {
    TotalCount:number,
    PageSize:number,
    CurrentPage:number,
    TotalPages:Number,
    HasNext:boolean,
    HasPrevious:boolean
}