import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShellService } from 'src/app/shared/services/shell.service';
import { AppHttpHeader } from 'src/app/shared/shared.module';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  appReqHeader = new AppHttpHeader();
  accessPointUrl: string = this.appReqHeader.DomainURL + 'api/Inspection';
  constructor(private http: HttpClient, private shellService: ShellService) { }

  getInspectionComments(vesselId: number, zoneId: number, unitId: number, outageId: number) {
    return this.http.get(this.accessPointUrl + "/GetNotes?vesselId=" + vesselId.toString() +                                           
                                                 "&zoneId=" + zoneId.toString() +
                                                 "&unitId=" + unitId.toString() +
                                                 "&outageId=" + outageId.toString(), { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  setInspectionComments(vesselId: number, zoneId: number, unitId: number, outageId: number,
                        inspectionComment: string, notesForFutureOutage: string) {
    var requestObject = {
      inspectionComment: inspectionComment,
      futureOutageNotes: notesForFutureOutage
    }
    return this.http.post<number>(this.accessPointUrl + "/SetNotes?vesselId=" + vesselId.toString() +                                           
                                                  "&zoneId=" + zoneId.toString() +
                                                  "&unitId=" + unitId.toString() +
                                                  "&outageId=" + outageId.toString(),
                                                  requestObject,
    { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err)))
  }

  getAllRecords(vesselId: number = 0, outageId: number) {
    var query = "?";
    if (vesselId != 0) {
      query += "vesselId=" + vesselId.toString() + "&"
    }
    query += "outageId=" + outageId.toString()
    return this.http.get(this.accessPointUrl + "/GetAllVesselInspections" + query,  { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteRecord(vesselId: number, zoneId: number, unitId: number, outageId: number) {
    return this.http.delete(this.accessPointUrl + "/DeleteVesselInspection" +
                                           "?vesselId=" + vesselId.toString() +                                           
                                           "&zoneId=" + zoneId.toString() +
                                           "&unitId=" + unitId.toString() +
                                           "&outageId=" + outageId.toString(), { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getPictures(inspectionId: number): Observable<any> {
    return this.http.get(this.accessPointUrl + "/" + inspectionId.toString() + "/Picture", { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  uploadPicture(inspectionId: number, files: File[], vesselId: number, zoneId: number, unitId: number, outageId: number, inspectionComment: string, notesForFutureOutage: string) {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) { //pass multiple image in formdata
      formData.append('files', files[i], files[i].name);
    }
    formData.append('inspectionComment', inspectionComment);
    formData.append('futureOutageComment', notesForFutureOutage);
    return this.http.post<number>(this.accessPointUrl + "/" + inspectionId + "/" + unitId + "/" + outageId + "/" + vesselId + "/" + zoneId + "/UploadPicture",
    formData, { headers: this.appReqHeader.FormHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deletePicture(id: number) {
    return this.http.delete(this.accessPointUrl + "/DeletePicture?id=" + id, { headers: this.appReqHeader.FormHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getAvailableZonesByVessel(unitId: number, outageId: number, vesselId: number){
    return this.http.get<any>(this.accessPointUrl + "/GetAvailableZones?unitId="+unitId+"&outageId="+outageId+"&vesselId="+vesselId, { headers: this.appReqHeader.FormHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
