import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export * from './home-page/home-page.component';

// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule
//   ]
// })
// export class HomeModule { }
