import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { Key } from 'readline';
import { catchError, map } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';
import { AppHttpHeader } from '../../../shared/shared.module';
import { PaginatedResult } from '../../utilities/models/paginatedResult';
import { ExcelWorkbookFile } from '../models/ExcelWorkbook';

@Injectable({
  providedIn: 'root'
})
export class BulkLoaderService {
  arrayList: any;
  appReqHeader = new AppHttpHeader();
  accessPointUrl: string = this.appReqHeader.DomainURL + 'api/BulkUpload';
  //emit events to components which injected the service
  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();

  constructor(private http: HttpClient, private shellService: ShellService) { }

  createExcelDropdownArray(dataList: any) {
    var tempArray = [];
    for (var i = 0; i < dataList.length; i++) {
      tempArray.push(dataList[i].name.replace(/\s+/g, ''));
    }
    var arrayList = new Array(tempArray);
    arrayList.join(',');
    this.arrayList = arrayList.toString();
    return this.arrayList;
  }

  createWorkbook(list: any, globalData: any, uploadMode: string) {
    let workbook = new Workbook();
    let sheet;
    var type = "";
    if (uploadMode == "daily-usage") {
      sheet = workbook.addWorksheet("Daily Usage");
      type = "dailyUsage";
    } else {
      sheet = workbook.addWorksheet("Man Hour Cost");
      type = "manHours";
    }
    sheet.views = [
      {state: 'frozen', ySplit: 5}
    ];
    // Create hidden master data sheet
    let buffer_sheet = workbook.addWorksheet("Master Data");
    buffer_sheet.state = 'hidden';

    // Fill master data sheet
    for (let i = 1; i <= list.vessels.length; i++) {
      buffer_sheet.getCell('A' + i).value = list.vessels[i - 1].name;
    }

    for (let i = 1; i <= list.zones.length; i++) {
      buffer_sheet.getCell('B' + i).value = list.zones[i - 1].name;
    }

    for (let i = 1; i <= list.shifts.length; i++) {
      buffer_sheet.getCell('D' + i).value = list.shifts[i - 1].name;
    }

    sheet.getRow(1).values=['Site']
    sheet.getRow(2).values=['Unit']
    sheet.getRow(3).values = ['Outage']

    sheet.getCell('B1').value = globalData.siteName;
    sheet.getCell('B2').value = globalData.unitName;
    sheet.getCell('B3').value = globalData.outageName;

    const length = 10000;

    for (let i = 6; i < length; i++) {
      sheet.getCell(`A${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ["'Master Data'!$A$1:$A$" + list.vessels.length]
      }
    }

    for (let i = 6; i < length; i++) {
      sheet.getCell(`B${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ["'Master Data'!$B$1:$B$" + list.zones.length]
      }
    }

    for (let i = 6; i < length; i++) {
      sheet.getCell(`C${i}`).dataValidation = {
        type: 'date',
        allowBlank: true,
        formulae: [new Date(1960, 0, 1)]
      }
    }

    for (let i = 6; i < length; i++) {
      sheet.getCell(`D${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ["'Master Data'!$D$1:$D$" + list.shifts.length]
      }
    }

    var titleCells: String[] = []
    
    if (type == 'manHours') {
      sheet.getRow(5).values = ['Vessel', 'Zone', 'Install', 'Shift', 'Job Type', 'On Site Hours', 'On Site Cost', 'Off Site Hours', 'Off Site Cost', 'Equip/Fixer Cost', 'Total Labour Cost', 'Overhead Profit', 'Total Cost'];
      sheet.getRow(5).values = [
        { 'richText': [{ 'text': 'Vessel' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Zone' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Install Date' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Shift' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Job Type' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Job No.' }] },
        { 'richText': [{ 'text': 'Shell Repair Area' }] },
        { 'richText': [{ 'text': 'Shell Repair Mat Type' }] },
        { 'richText': [{ 'text': 'Shell Repair Cost' }] },
        { 'richText': [{ 'text': 'On Site Hours' }] },
        { 'richText': [{ 'text': 'On Site Cost' }] },
        { 'richText': [{ 'text': 'Off Site Hours' }] },
        { 'richText': [{ 'text': 'Off Site Cost' }] },
        { 'richText': [{ 'text': 'Equip/Fixer Cost' }] },
        { 'richText': [{ 'text': 'Mobilisation Cost' }] },
        { 'richText': [{ 'text': 'Overhead Profit' }] },
        { 'richText': [{ 'text': 'Installer (Vendor)' }] },
        { 'richText': [{ 'text': 'Install Crew' }] },
      ]
      sheet.autoFilter = { from: 'A5', to: 'R5' };
      sheet.getColumn(1).width = 16;
      sheet.getColumn(2).width = 20;
      sheet.getColumn(3).width = 14;
      sheet.getColumn(4).width = 12;
      sheet.getColumn(5).width = 16;
      sheet.getColumn(6).width = 10;
      sheet.getColumn(7).width = 18;
      sheet.getColumn(8).width = 20;
      sheet.getColumn(9).width = 18;
      sheet.getColumn(9).numFmt = '"$"#, ##0.00;';
      sheet.getColumn(10).width = 15;
      sheet.getColumn(10).numFmt = '0;';
      sheet.getColumn(11).width = 15;
      sheet.getColumn(11).numFmt = '"$"#, ##0.00;';
      sheet.getColumn(12).width = 15;
      sheet.getColumn(12).numFmt = '0;';
      sheet.getColumn(13).width = 15;
      sheet.getColumn(13).numFmt = '"$"#, ##0.00;';
      sheet.getColumn(14).width = 16;
      sheet.getColumn(14).numFmt = '"$"#, ##0.00;';
      sheet.getColumn(15).width = 18;
      sheet.getColumn(15).numFmt = '"$"#, ##0.00;';
      sheet.getColumn(16).width = 18;
      sheet.getColumn(16).numFmt = '"$"#, ##0.00;';
      sheet.getColumn(17).width = 16;
      sheet.getColumn(18).width = 16;
      
      titleCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'];

      for (let i = 1; i <= list.jobTypes.length; i++) {
        buffer_sheet.getCell('E' + i).value = list.jobTypes[i - 1].name;
      }

      for (let i = 6; i < length; i++) {
        sheet.getCell(`E${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ["'Master Data'!$E$1:$E$" + list.jobTypes.length]
        }
      }

      for (let i = 6; i < length; i++) {
        sheet.getCell(`F${i}`).dataValidation = {
          type: 'whole',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`G${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`I${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`J${i}`).dataValidation = {
          type: 'whole',
          allowBlank: true,
          formulae: [0, 100000],
        }

        sheet.getCell(`K${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`L${i}`).dataValidation = {
          type: 'whole',
          allowBlank: true,
          formulae: [0, 100000],
        }

        sheet.getCell(`M${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`N${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`O${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }

        sheet.getCell(`P${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }
      }
    }
    else if (type == 'dailyUsage') {
      sheet.getRow(5).values = [
        { 'richText': [{ 'text': 'Vessel' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Zone' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Install Date' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Shift' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Product' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Product Type' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Product Position' }, { 'font': { 'color' : { argb: 'FFFF0000' } }, 'text': '*' }] },
        { 'richText': [{ 'text': 'Anchors Used' }] },
        { 'richText': [{ 'text': 'Kgs Used' }] },
        { 'richText': [{ 'text': 'Cost' }] }
      ]
      sheet.autoFilter = { from: 'A5', to: 'H5' }
      sheet.getColumn(1).width = 16;
      sheet.getColumn(2).width = 20;
      sheet.getColumn(3).width = 14;
      sheet.getColumn(4).width = 12;
      sheet.getColumn(5).width = 28;
      sheet.getColumn(6).width = 16;
      sheet.getColumn(7).width = 18;
      sheet.getColumn(8).width = 16;
      sheet.getColumn(9).width = 14;
      sheet.getColumn(10).numFmt = '"$"#, ##0.00;';
      titleCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

      for (let i = 1; i <= list.products.length; i++) {
        buffer_sheet.getCell('E' + i).value = list.products[i - 1].name;
      }

      for (let i = 6; i < length; i++) {
        sheet.getCell(`E${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ["'Master Data'!$E$1:$E$" + list.products.length]
        }
      }

      for (let i = 1; i <= list.productTypes.length; i++) {
        buffer_sheet.getCell('F' + i).value = list.productTypes[i - 1].name;
      }

      for (let i = 6; i < length; i++) {
        sheet.getCell(`F${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ["'Master Data'!$F$1:$F$" + list.productTypes.length]
        }
      }

      for (let i = 6; i < length; i++) {
        sheet.getCell(`G${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['"Backup, Hotface"'],//will be hardcoded
        }
      }
      // Anchors used
      for (let i = 6; i < length; i++) {
        sheet.getCell(`H${i}`).dataValidation = {
          type: 'whole',
          allowBlank: true,
          formulae: [0, 1000000],
        }
      }
      // Kgs used
      for (let i = 6; i < length; i++) {
        sheet.getCell(`I${i}`).dataValidation = {
          type: 'whole',
          allowBlank: true,
          formulae: [0, 1000000],
        }
      }
      // Cost
      for (let i = 6; i < length; i++) {
        sheet.getCell(`J${i}`).dataValidation = {
          type: 'decimal',
          allowBlank: true,
          formulae: [0, 1000000],
        }
      }
    }

    // Legend
    sheet.mergeCells('F3:J3');
    sheet.getCell('J3').value = { 'richText': [{ 'font': { 'color': { argb: 'FFFF0000' } }, 'text': '*' }, { 'font': { 'color': { argb: 'FF666666' } }, 'text': ' Mandatory' }] };
    sheet.getCell('J3').alignment = { vertical: 'middle', horizontal: 'right' }

    // Formatting
    for (let i = 1; i < 4; i++) {
      sheet.getCell('A' + i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF165788' }
      }
      sheet.getCell('A' + i).border = {
        right: { style: 'thick', color: { argb: 'FFF3CB14' } }
      }
      sheet.getCell('A' + i).font = {
        color: { argb: 'FFFFFFFF' }
      }
      sheet.getCell('B' + i).font = {
        color: { argb: 'FF165788' },
        bold: true
      }
    }

    for (let i = 0; i < titleCells.length; i++) {
      sheet.getCell(titleCells[i] + '5').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF03827E'}
      }

      sheet.getCell(titleCells[i] + '5').font = {
        color: {argb: 'FFFFFFFF'},
        bold: true
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("style", "visibility: hidden;");
      link.download = this.getFileNameString(type, globalData);
      link.innerHTML = "Click";
      link.click();
    })
  }

  private getFileNameString (type: string, globalData: any): string {
    var file_name = type == 'dailyUsage' ? 'DailyUsageSample_' : 'ManHoursSample_'
    file_name += globalData.siteName.replace(/[^a-z0-9]/gi, '_');
    file_name += '_' + globalData.outageName.replace(/[^a-z0-9-]/gi, '').replace('-', '_');
    var now = new Date();
    file_name += '_' + now.getFullYear() + String(now.getMonth() + 1).padStart(2, '0') + String(now.getDate() + 1).padStart(2, '0');
    file_name += '-' + String(now.getUTCHours() + 1).padStart(2, '0') + String(now.getUTCMinutes() + 1).padStart(2, '0');
    return file_name + '.xlsx';
  }

  getBulkLoaderLogs(pageNumber?: number, pageSize?: number) {
    const paginatedResult:PaginatedResult<any[]> =new PaginatedResult<any[]>();
    let params=new HttpParams();
    if(pageNumber!=null&&pageSize!=null){
      params=params.append('PageNumber',pageNumber.toString());
      params=params.append('PageSize',pageSize.toString());
    }
    
    return this.http.get<any>(this.accessPointUrl, { observe:'response', params, headers: this.appReqHeader.Headers }).pipe(
      map(response=>{
        paginatedResult.result=response.body != null ? response.body : [];
        if(response.headers.get('X-Pagination')!=null) {
          paginatedResult.pagination=JSON.parse(response.headers.get('X-Pagination') || '{}');
        }

        return paginatedResult;
      }), catchError (this.shellService.handleError)
    );
  }

  getLatestBulkLoaderLog() {
    return this.http.get<any>(this.accessPointUrl + "/Latest", { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  downloadBulkLoaderExcel(bkLogId: number) {
    this.http.get<ExcelWorkbookFile>(this.accessPointUrl + "/" + bkLogId + "/download", { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    ).subscribe((response) => {
      let blob = new Blob([this.base64ToArrayBuffer(response.data)], { type: response.mime });
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("style", "visibility: hidden;");
      link.download = response.name;
      link.innerHTML = "Click";
      link.click();
    });
  }

  base64ToArrayBuffer(base64: any) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  uploadBulkLoaderExcel(files: File[], bulkLoaderLogs:any) {
    let fileToUpload = files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    var query = "";
    if (bulkLoaderLogs.outageId) {
      query = "/" + bulkLoaderLogs.outageId;
    }
    if (bulkLoaderLogs.bulkLoaderType) {
      query = query + "/" + bulkLoaderLogs.bulkLoaderType;
    }
    //if (bulkLoaderLogs.loadDate) {
    //  query = query + "/" + bulkLoaderLogs.loadDate;
    //}
    return this.http.post<number>(this.accessPointUrl + query,formData, { headers: this.appReqHeader.FormHeaders }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

}
