export class Outage {
  id: number;
  name: string;
  outageDate: string;
  outageStartDate: Date;
  outageEndDate: Date;
  outageTypeId: number;
  siteName: string;
  siteUnitID: number;
  unitName: string;
  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  comments: string;
  generalComments: string;
}
