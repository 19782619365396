import { Compiler, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shutdown-review',
  templateUrl: './shutdown-review.component.html',
  styleUrls: ['./shutdown-review.component.css']
})
export class ShutdownReviewComponent implements OnInit {

  @ViewChild("wizardContainer", { read: ViewContainerRef }) wizardContainer: any;
  
  page: string = "";
  routes = [
    'estimates-vs-actual',
    'outage-cost'
  ]
  constructor(private route: ActivatedRoute, private compiler: Compiler) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params.comp === pg)) {
        this.page = params.comp;
      }
    });
  }

  ngOnInit(): void {
  }

}
