import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { catchError } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {

  appReqHeader = new AppHttpHeader();
  accessPointUrl: string = this.appReqHeader.DomainURL + 'api/AppSetting';
  
  constructor(private http: HttpClient, private shellService: ShellService) { }


  getSpacing(countryId: number, paramName: number) {
    var query = "";
    if (countryId) {
      query = "/" + countryId;
    }
    if (paramName) {
      query = query + "/" + paramName;
    }
    return this.http.get<any>(this.accessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getAppsettings(countryId: number, paramName: number) {
    var query = "";
    if (countryId) {
      query = "/" + countryId;
    }
    if (paramName) {
      query = query + "/" + paramName;
    }
    return this.http.get<any>(this.accessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getAnchorDensity(countryId: number, paramName: number) {
    var query = "";
    if (countryId) {
      query = "/" + countryId;
    }
    if (paramName) {
      query = query + "/" + paramName;
    }
    return this.http.get<any>(this.accessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
