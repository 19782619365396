import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessLevel, GlobalSiteUnitOutage, ShellService } from 'src/app/shared/services/shell.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  showCost = true;

  constructor(private route: ActivatedRoute, private shellService: ShellService) { }

  @Input() wizard_link = '';
  @ViewChild('reportFrame') iframe: ElementRef;
  accessLevel: AccessLevel;

  showShowCostControl = false

  page: string = "";

  map = new Map<string, string>();
  showCostMap = new Map<string, boolean>();

  ngOnInit(): void {
    environment.report_maps.forEach(map_item => {
      this.map.set(map_item.uri, map_item.id)
      this.showCostMap.set(map_item.uri, map_item.showCostOption == undefined ? false : map_item.showCostOption)
    });

    this.shellService.globalSiteUnitOutageSet.subscribe((data) => {
      this.setReportView();
    })
  }

  ngAfterViewInit() {
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
      this.route.params.subscribe(params => {
        if (this.map.has(params.uri)) {
          this.page = params.uri;
          var showcontrol = this.showCostMap.get(params.uri)
          this.showShowCostControl = showcontrol == undefined ? false : showcontrol
          this.setReportView();
          this.wizard_link = '';
        }
      });
      if (this.wizard_link.length > 0) {
        this.page = this.wizard_link;
        var showcontrol = this.showCostMap.get(this.page)
        this.showShowCostControl = showcontrol == undefined ? false : showcontrol
        this.setReportView();
      }
    })
  }

  private getRandomDigit(max: number): number {
    return Math.round(Math.random() * max);
  }

  private findNextNumber(n: number): number {
    for (let i = 1; i < 4; i++) {
      if ((i + n) % 3 == 0) {
        return i;
      }
    }
    return 0;
  }

  private generateToken(isAdmin: boolean): string {
    var token = "";
    var random_numbers = []
    for (let i = 0; i < 8; i++) {
      random_numbers.push(this.getRandomDigit(9.0));
    }
    var random_index = (this.getRandomDigit(3.0) * 2 ) + 1;
    var random_number_sum = 0;
    for (let i = 0; i < 4; i++) {
      if (((i * 2) + 1) != random_index) {
        random_number_sum += random_numbers[(i * 2) + 1];
      }
    }
    var nextNumber = this.findNextNumber(random_number_sum);
    if (!isAdmin) {
      if (nextNumber == 0) {
        nextNumber++;
      } else {
        nextNumber--;
      }
    }
    random_numbers[random_index] = nextNumber;
    random_numbers.forEach(n => {
      token += n.toString();
    });
    return token;
  }

  setReportView() {
    var globalDetails = this.shellService.loadGlobalSiteUnitOutage()
    
    this.iframe.nativeElement.src = "https://app.powerbi.com/rdlEmbed?reportId=" +
                                    this.map.get(this.page) +
                                    "&autoAuth=true&ctid=" +
                                    environment.tenantId +
                                    "&rdl:reportView=pageView" +
                                    "&rp:Site=" + globalDetails.siteId +
                                    "&rp:SiteUnit=" + globalDetails.unitId +
                                    "&rp:OutageDate=" + globalDetails.outageId +
                                    "&rp:IncludeCost=" + this.generateToken(this.accessLevel == AccessLevel.Administrator && this.showCost);
  }

}
