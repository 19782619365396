import { Component, OnInit } from '@angular/core';
import { GlobalSiteUnitOutage, ShellService } from 'src/app/shared/services/shell.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'flatout-editor',
  templateUrl: './flatout-editor.component.html',
  styleUrls: ['./flatout-editor.component.css']
})
export class FlatoutEditorComponent implements OnInit {

  constructor(private shellService: ShellService) { }

  siteUnitOutage: GlobalSiteUnitOutage | undefined = undefined;

  ngOnInit(): void {
    this.siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage();

    if (this.siteUnitOutage.outageId > 0) {
      this.openFlatoutEditor();
    }

    this.shellService.globalSiteUnitOutageSet.subscribe((obj) => {
      this.siteUnitOutage = obj;
      this.openFlatoutEditor();
    })
  }
  
  openFlatoutEditor() {
    if (this.siteUnitOutage) {
      var url = environment.flatoutUrl + "?" +
        "siteId=" + this.siteUnitOutage?.siteId +
        "&unitId=" + this.siteUnitOutage?.unitId;

      if (!this.siteUnitOutage?.outageName.includes("Nominal Lining")) {
        url += "&outageId=" + this.siteUnitOutage?.outageId;
      }
      window.open(url, "_blank");
    }
  }
}
