import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { catchError, map } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';
import { UnitVesselZoneViewModel } from '../models/zone';
import { PaginatedResult } from '../models/paginatedResult';

@Injectable({
  providedIn: 'root'
})

export class UnitVesselZoneService {

  appReqHeader = new AppHttpHeader();  
  zoneUrl = this.appReqHeader.DomainURL + 'api/Zone';
  zoneMapUrl = this.appReqHeader.DomainURL + 'api/ZoneMap';  

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getZones() {
    return this.http.get<any>(this.zoneUrl, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  search(searchZoneObj: any, pageNumber?: number, pageSize?: number) {
    const paginatedResult:PaginatedResult<any[]> =new PaginatedResult<any[]>();
    let params=new HttpParams();
    if(pageNumber!=null&&pageSize!=null){
      params=params.append('PageNumber',pageNumber.toString());
      params=params.append('PageSize',pageSize.toString());
    }
    return this.http.post<any>(this.zoneMapUrl +'/SearchUnitVesselZones', searchZoneObj, {observe:'response', params, headers: this.appReqHeader.Headers}).pipe(
      map(response=>{
        paginatedResult.result=response.body != null ? response.body : [];
        if(response.headers.get('X-Pagination')!=null) {
          paginatedResult.pagination=JSON.parse(response.headers.get('X-Pagination') || '{}');
        }
        return paginatedResult;
      }), catchError(err => this.shellService.handleError(err))
    );
  }

  addZone(zoneViewModel: any) {
    return this.http.post<any>(this.zoneMapUrl, zoneViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateZone(params: any, patchZoneViewModel: any, isZoneChange: boolean) {
    var query = "";
    if (params.zoneId) {
      query = query + "/" + params.zoneId;
    }
    if (params.vesselId) {
      query = query + "/Vessel/" + params.vesselId;
    }
    if (params.unitId) {
      query = query + "/Unit/" + params.unitId +"/"+isZoneChange;
    }
    return this.http.patch<any>(this.zoneMapUrl + query, patchZoneViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteZoneMap(unitId: number,vesselId: number, zoneId: number) {
    var query = "";
    if (unitId) {
      query = query + "/" + unitId;
    }
    if (vesselId) {
      query = query + "/Vessel/" + vesselId;
    }
    if (zoneId) {
      query = query + "/Zone/" + zoneId;
    }
    return this.http.delete<any>(this.zoneMapUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
