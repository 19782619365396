import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AccessLevel, ShellService } from 'src/app/shared/services/shell.service';
import { AlertModalService } from 'src/app/shared/shared.module';
import { BulkLoaderService } from '../../data-entry/services/bulk-loader.service';
import { Vendor } from '../models/vendor';
import { VendorService } from '../services/vendor.service';
import { VendorDialogViewModel } from '../view-models/VendorDialogViewModel';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  //#region variable declaration
  vendors: Vendor[];
  cols: any[];
  first: number = 0;
  loading: boolean = false;
  isFilterModeOn: boolean = false;
  vendorFilterName: string = "";
  vendorFilterDescription: string = "";
  id: number;
  isActive: boolean = true; //default is true
  deleteObj: any;
  accessLevel: AccessLevel;

  vendorDialogModel: VendorDialogViewModel = new VendorDialogViewModel();

  //#endregion
  constructor(private vendorService: VendorService,
              private alertService: AlertModalService,
              private shellService: ShellService,
              private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.loadVendorGridData();
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' }];
  }

  resetFilterControls() {
    this.vendorFilterName = "";
    this.vendorFilterDescription = "";
    this.isActive = true;
    this.loadVendorGridData();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }

  //#region grid data binding API call
  loadVendorGridData() {
    this.loading = true;
    this.vendors = [];
    var filterData = {
      vendorName: this.vendorFilterName,
      description: this.vendorFilterDescription,
      active: this.isActive
    }
    this.vendorService.searchVendors(filterData).subscribe(response => {
      if (response) {
        this.first = 0;
        this.vendors = response;
      }
      this.headerInitialize();
      this.loading = false;
    })
  }

  // Vendor Dialog
  openVendorDialog(rowData: any = null): void {
    this.resetVendorDialog();
    if (rowData != null) {
      this.vendorDialogModel.id = rowData.id;
      this.vendorDialogModel.vendorName = rowData.name;
      this.vendorDialogModel.vendorDescription = rowData.description;
      this.vendorDialogModel.isUpdate = true;
      this.vendorDialogModel.originalData = JSON.parse(JSON.stringify(this.vendorDialogModel));
    } else {
      this.vendorDialogModel.isUpdate = false;
    }

    this.vendorDialogModel.isOpen = true;
  }

  resetVendorDialog(): void {
    this.vendorDialogModel.vendorName = "";
    this.vendorDialogModel.vendorDescription = "";
    this.vendorDialogModel.isVendorNameEmpty = false;
  }

  addVendor(): void {
    if (this.vendorDialogModel.vendorName == "") {
      this.vendorDialogModel.isVendorNameEmpty = true;
      return;
    }

    var vendorObj = {
      name: this.vendorDialogModel.vendorName,
      description: this.vendorDialogModel.vendorDescription,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.vendorService.addVendor(vendorObj).subscribe((response) => {
      this.alertService.openAlert("Success!", "Vendor added successfully", "success");
      this.shellService.showLoadingIndicator(false);
      this.vendorDialogModel.isOpen = false;
      this.loadVendorGridData();
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
      this.vendorDialogModel.isOpen = false;
    })
  }

  updateVendor() {
    if (this.vendorDialogModel.vendorName == "") {
      this.vendorDialogModel.isVendorNameEmpty = true;
      return;
    }

    var originalData = this.vendorDialogModel.originalData;
    var batchPatchModel = this.bindVendorPatchArrayObj(originalData);
    if (batchPatchModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.vendorService.patchUpdate(originalData.id, batchPatchModel).subscribe(response => {
        this.vendorDialogModel.isOpen = false;
        this.alertService.openAlert("Success!", "Vendor updated successfully!", "success");
        this.loadVendorGridData();
        this.shellService.showLoadingIndicator(false);
      }, error => {
        this.vendorDialogModel.isOpen = false;
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  tempPatchArrayObj: any = []
  bindVendorPatchArrayObj(originalData: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (originalData.vendorName != this.vendorDialogModel.vendorName) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", this.vendorDialogModel.vendorName));
    }

    if (originalData.vendorDescription != this.vendorDialogModel.vendorDescription) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("description", this.vendorDialogModel.vendorDescription));
    }
    
    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  deleteVendor(rowData: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this vendor?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var patchArray = [
          {
            "value": false,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.vendorService.patchUpdate(rowData.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.alertService.openAlert("Deleted!", "Vendor deleted successfully", "success");
          this.loadVendorGridData();
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this vendor?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var patchArray = [
          {
            "value": true,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.vendorService.patchUpdate(obj.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.alertService.openAlert("Activated!", "Vendor reactivated successfully", "success");
          this.loadVendorGridData();
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }
}
