import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ShellService } from 'src/app/shared/services/shell.service';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { Vessel } from '../models/vessel';

@Injectable({
  providedIn: 'root'
})
export class VesselService {
  appReqHeader = new AppHttpHeader();
  vesselAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/Vessel';
  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchVessels(searchObject: any) {
    return this.http.post<any>(this.vesselAccessPointUrl +'/SearchVessel', searchObject, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  
  addVessel(vesselObj: any) {
    return this.http.post<any>(this.vesselAccessPointUrl, vesselObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(vesselId: number, patchVesselViewModel: any) {
    var query = "";
    if (vesselId) {
      query = "/" + vesselId;
    }
    return this.http.patch<any>(this.vesselAccessPointUrl + query, patchVesselViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
