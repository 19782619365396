import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { AccessLevel, ShellService } from 'src/app/shared/services/shell.service';
import { constProduct } from '../../../shared/constant';
import { AlertModalService } from '../../../shared/shared.module';
import { Product } from '../../utilities/models/product';
import { Vessel } from '../../utilities/models/vessel';
import { Zone } from '../../utilities/models/zone';
import { ProductService } from '../../utilities/services/product.service';
import { VendorService } from '../../utilities/services/vendor.service';
import { UnitService } from '../../utilities/utilities.module';
import { ApplicationMethod, BatchUsageProductViewModel } from '../models/dailyUsage';
import { DailyUsageService } from '../services/daily-usage.service';
import { BatchUsageDialogViewModel } from '../view-models/batchusage-dialog-viewmodel';

@Component({
  selector: 'app-daily-usage',
  templateUrl: './daily-usage.component.html',
  styleUrls: ['./daily-usage.component.css']
})
export class DailyUsageComponent implements OnInit {
  loading = false;

  filterVessels: Vessel[]
  filterZones: Zone[]
  filteredVessel: Vessel | any
  filteredZone: Zone | any

  isVesselNameEmpty = true;
  isZoneNameEmpty = false;

  expandedDailyUsageId: number = 0;
  // Expanded Row Footer Section
  totalAnchorCost: number = 0;
  totalRefractoryCost: number = 0;

  accessLevel: AccessLevel = AccessLevel.BasicUser;

  dailyUsageCols: any[] = [
    { field: "installationDate", header: "Installation Date", width: "10%", format: 'MM/dd/yyyy', min_width: '136px' },
    { field: "shiftId", header: "Shift", width: "6%", min_width: '40px' },
    { field: "jobTypeId", header: "Job Type", width: "7%", min_width: '86px' },
    { field: "internalLabourInstManHours", header: "On Site Hours", width: "9%", sum: "totalOnSiteHours", min_width: '120px' },
    { field: "internalLabourCost", header: "On Site Cost", width: "9%", sum: "totalInternalLabourCost", min_width: '108px' },
    { field: "externalLabourInstManHours", header: "Off Site Hours", width: "9%", sum: "totalOffSiteHours", min_width: '120px' },
    { field: "externalLabourCost", header: "Off Site Cost", width: "9%", sum: "totalExternalLabourCost", min_width: '110px' },
    { field: "equipCost", header: "Equip / Fixer Cost", width: "10%", sum: "totalEquipCost", min_width: '144px' },
    { field: "totalLabourCost", header: "Total Labour Cost", width: "10%", sum: "totalLabourCost", min_width: '144px' },
    { field: "ohProfit", header: "Overhead Profit", width: "9%", sum: "totalOHProfit", min_width: '132px' },
    { field: "totalCost", header: "Total Cost", width: "7%", sum: "totalCost", min_width: '92px' }
  ]

  anchorBackupCols = [
    { field: 'product', header: 'Backup Anchors', width: this.accessLevel > AccessLevel.Contractor ? '33%' : '30%' },
    { field: 'qty', header: 'Anchors Used', width: this.accessLevel > AccessLevel.Contractor ? '20%' : '10%' },
    { field: 'productCost', header: 'Cost($)', width: this.accessLevel > AccessLevel.Contractor ? '30%' : '20%' }
  ];

  anchorHotfaceCols = [
    { field: 'product', header: 'Hotface Anchors', width: this.accessLevel > AccessLevel.Contractor ? '33%' : '30%' },
    { field: 'qty', header: 'Anchors Used', width: this.accessLevel > AccessLevel.Contractor ? '20%' : '10%' },
    { field: 'productCost', header: 'Cost($)', width: this.accessLevel > AccessLevel.Contractor ? '30%' : '20%' }
  ];

  refractoryBackupCols = [
    { field: 'product', header: 'Backup Refractory', width: this.accessLevel > 2 ? '33%' : '30%' },
    { field: 'qty', header: 'Kgs Used', width: this.accessLevel > 2 ? '20%' : '10%' },
    { field: 'productCost', header: 'Cost($)', width: this.accessLevel > 2 ? '30%' : '20%' }
  ];

  refractoryHotfaceCols = [
    { field: 'product', header: 'Hotface Refractory', width: this.accessLevel > 2 ? '33%' : '30%' },
    { field: 'qty', header: 'Kgs Used', width: this.accessLevel > 2 ? '20%' : '10%' },
    { field: 'productCost', header: 'Cost($)', width: this.accessLevel > 2 ? '30%' : '20%' }
  ];

  dailyUsageGridData: any;
  pageNumber: number = 1;
  pageSize: number = 8;
  first: number = 0;
  totalCount: number = 0;
  gridCalculations: any;
  @ViewChild('dt') table_component: Table;

  anchorHotfaceData: any;
  anchorBackupData: any;
  refractoryHotfaceData: any;
  refractoryBackupData: any;

  viewingRefractoryTab = false;

  shellRepairArea: number = 0;
  shellRepairMatType: string = "";
  shellRepairCost: number = 0;

  originalShellRepairArea: number = 0;
  originalShellRepairMatType: string = "";
  originalShellRepairCost: number = 0;

  // Dialogs
  batchUsageDialog: BatchUsageDialogViewModel = new BatchUsageDialogViewModel();

  constructor(private shellService: ShellService,
    private unitService: UnitService,
    private dailyUsageService: DailyUsageService,
    private productService: ProductService,
    private confirmationService: ConfirmationService,
    private alertService: AlertModalService) { }

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    });

    var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
    this.getVesselsForFilter(siteUnitOutageObj.unitId);

    this.shellService.globalSiteUnitOutageSet.subscribe((obj) => {
      this.getVesselsForFilter(obj.unitId);
      this.filteredZone = null;
      this.filterZones = [];
    })
    this.anchorRefractoryObj = new BatchUsageProductViewModel();

    this.getShifts();
    this.getAllJobTypes();
  }

  getVendors(selectDefault: any): void {
    this.productService.getVendor().subscribe((response) => {
      this.batchUsageDialog.installers = response;
      if (selectDefault) {
        this.batchUsageDialog.installer = this.batchUsageDialog.installers.find(x => x.id == selectDefault)
      }
    });
  }

  getVesselsForFilter(unitId: number = 0): void {
    this.shellService.showLoadingIndicator(true);
    this.unitService.getVesselByUnitId(unitId).subscribe(response => {
      if (response) {
        this.filterVessels = response;
        this.filteredVessel = undefined;
        this.shellService.showLoadingIndicator(false);
      }
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  getZonesForFilter(unitId: number, vesselId: number): void {
    this.filterZones = [];
    this.unitService.getZoneBySiteUnitVesselId(unitId, vesselId).subscribe(response => {
      if (response) {
        this.filterZones = response;
      }
    })
  }

  getZonesForModal(unitId: number, vesselId: number): void {
    this.batchUsageDialog.zones = [];
    this.unitService.getZoneBySiteUnitVesselId(unitId, vesselId).subscribe(response => {
      if (response) {
        this.batchUsageDialog.zones = response;
      }
    })
  }

  onChangeVessel(vessel: any) {
    var unitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
    this.getZonesForFilter(unitId, vessel.id);
    this.isZoneNameEmpty = true;
    this.filteredZone = undefined;
    this.isVesselNameEmpty = false;
  }

  onChangeVesselForModal(vessel: any) {
    var unitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
    this.getZonesForModal(unitId, vessel.id);
    this.batchUsageDialog.selectedZone = undefined;
  }

  onChangeZone(event: any) {
    this.isZoneNameEmpty = false;
    if (this.table_component) { this.table_component.reset(); }
    var globalData = this.shellService.loadGlobalSiteUnitOutage();
    this.loadGridCalculations(globalData.unitId, globalData.outageId);
  }

  getAllJobTypes() {
    this.dailyUsageService.getJobTypes().subscribe((response) => {
      this.batchUsageDialog.jobTypes = response;
    })
  }

  getShifts() {
    this.dailyUsageService.getShifts().subscribe((response) => {
      this.batchUsageDialog.shiftOptions = response;
    })
  }

  getJobTypeString(jobTypeId: any) {
    if (jobTypeId > 0) {
      var obj = this.batchUsageDialog.jobTypes.find(x => x.id == jobTypeId);
      if (obj != undefined) {
        return obj.name;
      }
    }
    return "";
  }

  getShiftString(shiftId: any) {
    if (shiftId > 0) {
      var obj = this.batchUsageDialog.shiftOptions.find(x => x.id == shiftId);
      if (obj != undefined) {
        return obj.name;
      }
    }
    return "";
  }

  switchTab(switchIt: boolean): void {
    this.viewingRefractoryTab = switchIt;
    if (this.viewingRefractoryTab) {
      this.loadProductData("Refractory", "Hotface");
      this.loadProductData("Refractory", "Backup");
    } else {
      this.loadProductData("Anchor", "Hotface");
      this.loadProductData("Anchor", "Backup");
    }
  }

  validateFilterSelection(): boolean {
    var isValid = true;

    var vesslName = this.filteredVessel ? this.filteredVessel.name : "";
    if (vesslName) {
      this.isVesselNameEmpty = false;
    } else {
      this.isVesselNameEmpty = true;
      isValid = false;
    }

    var zone = this.filteredZone ? this.filteredZone.name : "";
    if (zone) {
      this.isZoneNameEmpty = false;
    } else {
      this.isZoneNameEmpty = true;
      isValid = false;
    }

    return isValid;
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (event != undefined && event.first != undefined && event.rows != undefined) {
      this.pageNumber = Math.ceil(event.first / event.rows) + 1 //determine the pageeeno and send that to backend 
      this.pageSize = event.rows;
      var globalData = this.shellService.loadGlobalSiteUnitOutage();
      this.loadGridData(globalData.unitId, globalData.outageId);
    }
  }

  loadGridData(unitId: number, outageId: number) {
    if (!this.validateFilterSelection()) {
      return;
    }
    var searchObj = {
      unitId: unitId,
      outageId: outageId,
      vesselId: this.filteredVessel ? this.filteredVessel.id : undefined,
      zoneId: this.filteredZone ? this.filteredZone.id : undefined,
      active: true
    }
    this.shellService.showLoadingIndicator(true);
    this.dailyUsageService.searchBatchUsage(searchObj, this.pageNumber, this.pageSize).subscribe((response) => {
      if (response) {
        this.dailyUsageGridData = response.result;
        this.totalCount = response.pagination.TotalCount;
      }
      this.shellService.showLoadingIndicator(false);
    })
  }

  loadGridCalculations(unitId: number, outageId: number) {
    if (!this.validateFilterSelection()) {
      return;
    }
    var searchObj = {
      unitId: unitId,
      outageId: outageId,
      vesselId: this.filteredVessel ? this.filteredVessel.id : undefined,
      zoneId: this.filteredZone ? this.filteredZone.id : undefined,
      active: true
    }

    this.shellService.showLoadingIndicator(true);
    this.dailyUsageService.getCalculations(searchObj).subscribe((response) => {
      if (response) {
        this.gridCalculations = response;
      }
      this.shellService.showLoadingIndicator(false);
    })
  }

  onExpandDailyUsage(event: any) {
    this.shellRepairArea = event.data.shellRepairArea;
    this.shellRepairCost = event.data.shellRepairCost;
    this.shellRepairMatType = event.data.shellRepairMatType;
    this.originalShellRepairArea = this.shellRepairArea;
    this.originalShellRepairCost = this.shellRepairCost;
    this.originalShellRepairMatType = this.shellRepairMatType;

    this.expandedDailyUsageId = event.data.id;
    this.anchorBackupData = undefined;
    this.anchorHotfaceData = undefined;
    this.refractoryHotfaceData = undefined;
    this.refractoryBackupData = undefined;
    this.viewingRefractoryTab = false;
    this.loadProductData("Refractory", "Hotface");
    this.loadProductData("Refractory", "Backup");
    this.loadProductData("Anchor", "Hotface");
    this.loadProductData("Anchor", "Backup");
  }

  getAnchorTotalCost(): number {
    var sum = 0;
    if (this.anchorBackupData && this.anchorBackupData.length) {
      for (let i = 0; i < this.anchorBackupData.length; i++) {
        sum += this.anchorBackupData[i]['productCost'];
      }
    }
    if (this.anchorHotfaceData && this.anchorHotfaceData.length) {
      for (let i = 0; i < this.anchorHotfaceData.length; i++) {
        sum += this.anchorHotfaceData[i]['productCost'];
      }
    }
    this.totalAnchorCost = sum;
    return sum;
  }

  getRefractoryTotalCost(): number {
    var sum = 0;
    if (this.refractoryBackupData && this.refractoryBackupData.length) {
      for (let i = 0; i < this.refractoryBackupData.length; i++) {
        sum += this.refractoryBackupData[i]['productCost'];
      }
    }
    if (this.refractoryHotfaceData && this.refractoryHotfaceData.length) {
      for (let i = 0; i < this.refractoryHotfaceData.length; i++) {
        sum += this.refractoryHotfaceData[i]['productCost'];
      }
    }
    this.totalRefractoryCost = sum;
    return sum;
  }

  loadProductData(productType: string, productPosition: string) {
    var searchObj = {
      productType: productType,
      productPosition: productPosition,
      active: true
    }
    if (this.anchorBackupData == undefined ||
      this.anchorHotfaceData == undefined ||
      this.refractoryBackupData == undefined ||
      this.refractoryHotfaceData == undefined) {
      this.loading = true;
      this.dailyUsageService.searchBatchUsageProduct(searchObj, this.expandedDailyUsageId).subscribe((response) => {
        this.loading = false;
        // console.log(response)
        if (productType == "Anchor") {
          if (productPosition == "Hotface") {
            this.anchorHotfaceData = response != null ? response : [];
          } else {
            this.anchorBackupData = response != null ? response : [];
          }
        } else {
          if (productPosition == "Hotface") {
            this.refractoryHotfaceData = response != null ? response : [];
          } else {
            this.refractoryBackupData = response != null ? response : [];
          }
        }
      })
    }
  }

  openBatchUsageDialog(rowData: any) {
    this.resetBatchUsageDialog();
    if (rowData != null) {
      this.batchUsageDialog.isUpdate = true;
      this.batchUsageDialog.id = rowData.id;
      this.batchUsageDialog.selectedVessel = this.filteredVessel;
      this.onChangeVesselForModal(this.filteredVessel);
      this.batchUsageDialog.selectedZone = this.filteredZone;
      this.batchUsageDialog.installDate = new Date(rowData.installationDate);
      this.batchUsageDialog.internalLabourCost = rowData.internalLabourCost;
      this.batchUsageDialog.internalLabourInstManHours = rowData.internalLabourInstManHours;
      this.batchUsageDialog.externalLabourCost = rowData.externalLabourCost;
      this.batchUsageDialog.externalLabourInstManHours = rowData.externalLabourInstManHours;
      this.batchUsageDialog.comments = rowData.comments;
      this.batchUsageDialog.crew = rowData.crewMembers;
      this.batchUsageDialog.jobNo = rowData.jobNumber;
      this.batchUsageDialog.selectedShift = this.batchUsageDialog.shiftOptions.find(x => x.id == rowData.shiftId);
      this.batchUsageDialog.selectedJobType = this.batchUsageDialog.jobTypes.find(x => x.id == rowData.jobTypeId);
    } else {
      this.batchUsageDialog.isUpdate = false;
    }
    this.getVendors(rowData != null ? rowData.vendorId : undefined);
    this.batchUsageDialog.originalData = JSON.parse(JSON.stringify(this.batchUsageDialog));
    this.batchUsageDialog.opened = true;
  }

  resetBatchUsageDialog(): void {
    if (this.batchUsageDialog.isUpdate == false) {
      this.batchUsageDialog.selectedVessel = undefined;
      this.batchUsageDialog.selectedZone = undefined;
    } 
    this.batchUsageDialog.installDate = new Date();
    this.batchUsageDialog.installer = undefined;
    this.batchUsageDialog.crew = undefined;
    this.batchUsageDialog.jobNo = undefined;
    this.batchUsageDialog.internalLabourCost = 0;
    this.batchUsageDialog.internalLabourInstManHours = 0;
    this.batchUsageDialog.externalLabourCost = 0;
    this.batchUsageDialog.externalLabourInstManHours = 0;
    this.batchUsageDialog.comments = undefined;
    this.batchUsageDialog.selectedShift = this.batchUsageDialog.shiftOptions[0];
    this.batchUsageDialog.selectedJobType = undefined;
    this.batchUsageDialog.isOnSiteCostEmpty = false;
    this.batchUsageDialog.isOnSiteHoursEmpty = false;
    this.batchUsageDialog.isOffSiteCostEmpty = false;
    this.batchUsageDialog.isOffSiteHoursEmpty = false;
    this.batchUsageDialog.isVesselNameEmpty = false;
    this.batchUsageDialog.isZoneNameEmpty = false;
    this.batchUsageDialog.isInstallerEmpty = false;
    this.batchUsageDialog.isJobTypeEmpty = false;
  }

  validateBatchUsage(): boolean {
    var isValid = true;

    if (this.batchUsageDialog.selectedVessel != undefined) {
      this.batchUsageDialog.isVesselNameEmpty = false;
    } else {
      this.batchUsageDialog.isVesselNameEmpty = true;
      isValid = false;
    }

    if (this.batchUsageDialog.selectedZone != undefined) {
      this.batchUsageDialog.isZoneNameEmpty = false;
    } else {
      this.batchUsageDialog.isZoneNameEmpty = true;
      isValid = false;
    }
    if (this.batchUsageDialog.installer != undefined) {
      this.batchUsageDialog.isInstallerEmpty = false;
    } else {
      this.batchUsageDialog.isInstallerEmpty = true;
      isValid = false;
    }

    if (this.batchUsageDialog.selectedJobType != undefined) {
      this.batchUsageDialog.isJobTypeEmpty = false;
    } else {
      this.batchUsageDialog.isJobTypeEmpty = true;
      isValid = false;
    }

    var isOnSiteCostFilled = false;
    var isOnSiteHourFilled = false;
    var isOffSiteCostFilled = false;
    var isOffSiteHourFilled = false;
    if ((this.batchUsageDialog.internalLabourInstManHours > 0 && this.batchUsageDialog.internalLabourCost > 0) &&
      (this.batchUsageDialog.externalLabourInstManHours > 0 && this.batchUsageDialog.externalLabourCost > 0)) {
      this.batchUsageDialog.isOnSiteHoursEmpty = false;
      this.batchUsageDialog.isOnSiteCostEmpty = false;
      this.batchUsageDialog.isOffSiteCostEmpty = false;
      this.batchUsageDialog.isOffSiteHoursEmpty = false;
      isOnSiteCostFilled = true;
      isOnSiteHourFilled = true;
      isOffSiteCostFilled = true;
      isOffSiteHourFilled = true;
    } else {
      var fieldNotFilled = 0;
      if (this.batchUsageDialog.internalLabourInstManHours > 0) {
        this.batchUsageDialog.isOnSiteCostEmpty = true;
        this.batchUsageDialog.isOnSiteHoursEmpty = false;
        isOnSiteHourFilled = true;
      }
      if (this.batchUsageDialog.internalLabourCost > 0) {
        this.batchUsageDialog.isOnSiteHoursEmpty = true;
        this.batchUsageDialog.isOnSiteCostEmpty = false;
        isOnSiteCostFilled = true;
      }
      if (this.batchUsageDialog.internalLabourCost < 1 && this.batchUsageDialog.internalLabourInstManHours < 1) {
        this.batchUsageDialog.isOnSiteHoursEmpty = false;
        this.batchUsageDialog.isOnSiteCostEmpty = false;
        fieldNotFilled++;
      }
      if (this.batchUsageDialog.externalLabourInstManHours > 0) {
        this.batchUsageDialog.isOffSiteCostEmpty = true;
        this.batchUsageDialog.isOffSiteHoursEmpty = false;
        isOffSiteHourFilled = true;
      }
      if (this.batchUsageDialog.externalLabourCost > 0) {
        this.batchUsageDialog.isOffSiteHoursEmpty = true;
        this.batchUsageDialog.isOffSiteCostEmpty = false;
        isOffSiteCostFilled = true;
      }
      if (this.batchUsageDialog.externalLabourInstManHours < 1 && this.batchUsageDialog.externalLabourCost < 1) {
        this.batchUsageDialog.isOffSiteHoursEmpty = false;
        this.batchUsageDialog.isOffSiteCostEmpty = false;
        fieldNotFilled++;
      }
      if (fieldNotFilled > 1) {
        this.batchUsageDialog.isOnSiteHoursEmpty = true;
        this.batchUsageDialog.isOnSiteCostEmpty = true;
        this.batchUsageDialog.isOffSiteHoursEmpty = true;
        this.batchUsageDialog.isOffSiteCostEmpty = true;
        isValid = false;
      }
      if ((isOnSiteCostFilled == true && isOnSiteHourFilled == false) || (isOnSiteCostFilled == false && isOnSiteHourFilled == true)) {
        isValid = false;
      }
      if ((isOffSiteCostFilled == true && isOffSiteHourFilled == false) || (isOffSiteCostFilled == false && isOffSiteHourFilled == true)) {
        isValid = false;
      }     
    }
    return isValid;
  }

  addBatchUsage() {
    if (!this.validateBatchUsage()) {
      return;
    }
    var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
    var batchObj = {
      siteUnitId: siteUnitOutageObj.unitId,
      outageId: siteUnitOutageObj.outageId,
      vesselId: this.batchUsageDialog.selectedVessel.id,
      zoneId: this.batchUsageDialog.selectedZone.id,
      vendorId: this.batchUsageDialog.installer ? this.batchUsageDialog.installer.id : 0,
      installationDate: this.batchUsageDialog.installDate,
      shiftId: this.batchUsageDialog.selectedShift.id,
      crewMembers: this.batchUsageDialog.crew,
      comments: this.batchUsageDialog.comments,
      shellRepairArea: 0,
      shellRepairMatType: "",
      shellRepairCost: 0,
      internalLabourInstManHours: this.batchUsageDialog.internalLabourInstManHours,
      internalLabourCost: this.batchUsageDialog.internalLabourCost,
      externalLabourInstManHours: this.batchUsageDialog.externalLabourInstManHours,
      externalLabourCost: this.batchUsageDialog.externalLabourCost,
      equipCost: 0,
      mobilisationCost: 0,
      ohProfit: 0,
      jobTypeId: this.batchUsageDialog.selectedJobType.id,
      jobNumber: this.batchUsageDialog.jobNo,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.dailyUsageService.addBatchUsage(batchObj).subscribe((response) => {
      this.shellService.showLoadingIndicator(false);
      this.alertService.openAlert("Success", "Batch Usage added successfully!", "success");
      if (this.table_component) { this.table_component.reset(); }
      this.batchUsageDialog.opened = false;
      var globalData = this.shellService.loadGlobalSiteUnitOutage();
      this.loadGridCalculations(globalData.unitId, globalData.outageId);
    }, (error) => {
      this.batchUsageDialog.opened = false;
      this.shellService.showLoadingIndicator(false);
    })
  }

  deleteBatchUsage(id: any) {
    this.confirmationService.confirm({
      message: 'Delete daily usage and related product data?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.shellService.showLoadingIndicator(true);
        this.dailyUsageService.deleteBatchUsage(id).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.alertService.openAlert("Deleted!", "Daily usage and related product data deleted successfully", "success");
          if (this.table_component) { this.table_component.reset(); }
          var globalData = this.shellService.loadGlobalSiteUnitOutage();
          this.loadGridCalculations(globalData.unitId, globalData.outageId);
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

  //#region Batch usage product
  dialogProductType: string = "";
  dialogProductPosition: string = "";
  anchorRefractoryObj: any;
  isProductDataLoaded: boolean = false;
  isRefractoryProductDataLoaded: boolean = false;
  products: Product[];
  refractoryProducts: Product[];
  selectedProduct: any = [];
  isApplicationMethodLoaded: boolean = false;
  applicationMethods: ApplicationMethod[];
  selectedApplicationMethod: any;

  anchorRefractoryDialog = {
    opened: false,
    isUpdate: false,
    isProductEmpty: false,
    isAnchorKgEmpty:false
  }

  resetAnchorRefractoryDialog() {
    this.selectedProduct = undefined;
    this.anchorRefractoryObj = new BatchUsageProductViewModel();
    this.isApplicationMethodLoaded = false;
    this.anchorRefractoryDialog.isProductEmpty = false;
    this.anchorRefractoryDialog.isAnchorKgEmpty = false;
  }

  toggleAnchorRefractoryDialog(productType: string, productPosition: string, productName?: string, applicationId?: number, addMode: boolean = true) {
    this.anchorRefractoryDialog.opened = !this.anchorRefractoryDialog.opened;
    this.dialogProductType = productType;
    this.dialogProductPosition = productPosition;
    if (addMode) {
      this.anchorRefractoryObj = new BatchUsageProductViewModel();
      this.selectedProduct = [];
      this.selectedApplicationMethod = [];
      this.anchorRefractoryDialog.isUpdate = false;
      this.anchorRefractoryDialog.isProductEmpty = false;
      this.anchorRefractoryDialog.isAnchorKgEmpty = false;
    }
    if (productType == 'Anchor' && this.isProductDataLoaded == false) {
      this.loadProductDropdownData('Anchor', productName);
    } else {
      if (this.isProductDataLoaded == true) {
        this.bindAnchorProducts(productName);
      }
    }
    if (productType == 'Refractory' && this.isRefractoryProductDataLoaded == false) {
      this.loadProductDropdownData('Refractory', productName);
    } else {
      if (this.isRefractoryProductDataLoaded == true) {
        this.bindRefractoryProduct(productName);
      }
    }
    if (productType == 'Refractory' && this.isApplicationMethodLoaded == false) {
      this.loadApplicationMethod(applicationId);
    } else {
      if (this.isApplicationMethodLoaded == true) {
        this.bindSelectedApplcationMethod(applicationId);
      }
    }
  }

  anchorRefractoryId: number;
  editAnchorRefractoryRow(data: any, type: string) {
    this.anchorRefractoryObj = new BatchUsageProductViewModel();
    this.selectedProduct = [];
    this.selectedApplicationMethod = [];
    this.anchorRefractoryDialog.isUpdate = true;
    this.anchorRefractoryId = data.id;
    var productType = "";
    var productPosition = "";
    if (type == 'anchorBackup') {
      productType = constProduct.anchorType;
      productPosition = constProduct.positionBackup;
    }
    else if (type == 'anchorHotface') {
      productType = constProduct.anchorType;
      productPosition = constProduct.positionHotface;
    }
    else if (type == 'refractoryBackup') {
      productType = constProduct.refractoryType;
      productPosition = constProduct.positionBackup;
    }
    else if (type == 'refractoryHotface') {
      productType = constProduct.refractoryType;
      productPosition = constProduct.positionHotface;
    }

    if (productType == 'Refractory') {
      this.anchorRefractoryObj.batchNumber = data.batchNumber;
    }
    this.anchorRefractoryObj.qty = data.qty;
    this.anchorRefractoryObj.productActive = data.productActive;
    this.anchorRefractoryObj.productCost = data.productCost;
    this.toggleAnchorRefractoryDialog(productType, productPosition, data.product, data.applicationMethodId, false);
    this.setBatchProductRowData(data.id, data.product, data.applicationMethodId);
  }

  batchProductGridRowData: any;
  setBatchProductRowData(id: number, productName: string, applicationMethodId?: number) {
    this.batchProductGridRowData = {
      id: id,
      productName: productName,
      applicationMethodId: applicationMethodId,
      qty: this.anchorRefractoryObj.qty,
      productCost: this.anchorRefractoryObj.productCost,
      batchNumber: this.anchorRefractoryObj.batchNumber,
      productActive: this.anchorRefractoryObj.productActive
    }
  }

  bindAnchorProducts(productName?: string) {
    if (this.products) {
      let rowIndex = this.products.findIndex(x => x.name == productName);
      this.selectedProduct = this.products[rowIndex];
    }
  }

  bindRefractoryProduct(productName?: string) {
    if (this.refractoryProducts) {
      let rowIndex = this.refractoryProducts.findIndex(x => x.name == productName);
      this.selectedProduct = this.refractoryProducts[rowIndex];
    }
  }

  bindSelectedApplcationMethod(id?: number) {
    if (this.applicationMethods) {
      let rowIndex = this.applicationMethods.findIndex(x => x.id == id);
      this.selectedApplicationMethod = this.applicationMethods[rowIndex];
    }
  }

  loadProductDropdownData(productType: string, productName?: string) {
    var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
    this.productService.getProductsByProductType(productType, siteUnitOutageObj.countryId).subscribe(response => {
      if (productType == 'Anchor') {
        this.products = [];
        this.products = response;
        this.isProductDataLoaded = true;
        if (productName) {
          this.bindAnchorProducts(productName);
        }
      }
      if (productType == 'Refractory') {
        this.refractoryProducts = [];
        this.refractoryProducts = response;
        this.isRefractoryProductDataLoaded = true;
        if (productName) {
          this.bindRefractoryProduct(productName);
        }
      }
    })
  }

  loadApplicationMethod(applicationId: any) {
    this.dailyUsageService.getApplicationMethod().subscribe(response => {
      this.applicationMethods = response as ApplicationMethod[];
      this.isApplicationMethodLoaded = true;
      if (applicationId != undefined) {
        let rowIndex = this.applicationMethods.findIndex(x => x.id == applicationId);
        this.selectedApplicationMethod = this.applicationMethods[rowIndex];
      }
    })
  }

  anchorRefractoryDialogControlChange(event: any, name: string) {
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var quantity = this.anchorRefractoryObj.qty;
    if (name == 'qty') {
      quantity = event.value;
    }
    var costPerUnit = 0;
    var productCost = quantity * costPerUnit;
    if (this.dialogProductType == 'Anchor') {
      if (this.products.length > 0) {
        var row = this.products.find(r => r.name == product);
        costPerUnit = row ? row.costPerUnit : 0;
      }
    }
    else {
      if (this.dialogProductType == 'Refractory') {
        if (this.refractoryProducts.length > 0) {
          var row = this.refractoryProducts.find(r => r.name == product);
          costPerUnit = row ? row.costPerUnit : 0;
        }
      }
    }
   
    if (costPerUnit != 0) {
      productCost = quantity * costPerUnit;
      this.anchorRefractoryObj.productCost = productCost ? productCost : 0;
    }
  }

  saveShellRepairData() {
    var batchPatchModel = this.bindShellPatchArrayObj();
    if (batchPatchModel.length > 0) {
      this.dailyUsageService.updateBatchUsage(this.expandedDailyUsageId, batchPatchModel).subscribe(response => {
        this.batchUsageDialog.opened = false;
        this.alertService.openAlert("Success!", "Batch Usage updated successfully!", "success");
      }, error => {
        this.batchUsageDialog.opened = false;
      })
    }
  }

  validateBatchUsageProducts(): boolean {
    var isValid = true;
    var name = this.selectedProduct ? this.selectedProduct.name : "";
    if (name) {
      this.anchorRefractoryDialog.isProductEmpty = false;
    } else {
      if (this.anchorRefractoryDialog.isUpdate && !this.batchProductGridRowData.active) {
        this.anchorRefractoryDialog.isProductEmpty = false;
      } else {
        this.anchorRefractoryDialog.isProductEmpty = true;
        isValid = false;
      }
    }
    var qty= this.anchorRefractoryObj.qty ? this.anchorRefractoryObj.qty : 0
    if (qty > 0) {
      this.anchorRefractoryDialog.isAnchorKgEmpty = false;
    } else {
      this.anchorRefractoryDialog.isAnchorKgEmpty = true;
      isValid = false;
    }
    
    return isValid;
  }

  saveAnchorRefractoryDetails() {
    if (!this.validateBatchUsageProducts()) {
      return;
    }

    let anchorRefractoryViewModel = {
      id: 0,
      batchUsageId: this.expandedDailyUsageId,
      productId: this.selectedProduct.id,
      productPosition: this.dialogProductPosition,
      qty: this.anchorRefractoryObj.qty ? this.anchorRefractoryObj.qty : 0,
      productCost: this.anchorRefractoryObj.productCost,
      batchNumber: this.anchorRefractoryObj.batchNumber,
      applicationMethodId: this.selectedApplicationMethod ? this.selectedApplicationMethod.id : null
    }
    this.dailyUsageService.addAnchorRefractoryBatchProductDetails(anchorRefractoryViewModel).subscribe(response => {
      this.isApplicationMethodLoaded = false;
      this.isProductDataLoaded = false;
      this.anchorRefractoryDialog.opened = false;
      this.alertService.openAlert("success!", "Saved successfully!", "success");
      this.resetAnchorRefractoryDialog();
      this.bindAnchorRefractoryProductData(this.dialogProductType, anchorRefractoryViewModel.productPosition); //refresh the grid      
      this.dialogProductType = "";
      this.dialogProductPosition = "";
    }, error => {
      this.dialogProductType = "";
      this.dialogProductPosition = "";
      this.isApplicationMethodLoaded = false;
      this.isProductDataLoaded = false;
      this.anchorRefractoryDialog.opened = false;
      this.resetAnchorRefractoryDialog();
    })
  }

  bindAnchorRefractoryProductData(productType: string, productPosition: string) {
    var searchObj = {
      productType: productType,
      productPosition: productPosition,
      active: true
    }
    this.loading = true;
    this.dailyUsageService.searchBatchUsageProduct(searchObj, this.expandedDailyUsageId).subscribe((response) => {
      this.loading = false;
      if (productType == "Anchor") {
        if (productPosition == "Hotface") {
          this.anchorHotfaceData = response != null ? response : [];
        } else {
          this.anchorBackupData = response != null ? response : [];
        }
      } else {
        if (productPosition == "Hotface") {
          this.refractoryHotfaceData = response != null ? response : [];
        } else {
          this.refractoryBackupData = response != null ? response : [];
        }
      }
    })
  }

  tempPatchArrayObj: any = []
  bindBatchPatchArrayObj(rowData: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (this.filteredVessel.name != this.batchUsageDialog.selectedVessel.name) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("vesselId", this.batchUsageDialog.selectedVessel.id));
    }
    if (this.filteredZone.name != this.batchUsageDialog.selectedZone.name) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("zoneId", this.batchUsageDialog.selectedZone.id));
    }
    if (rowData.installDate != this.batchUsageDialog.installDate) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("installationDate", this.batchUsageDialog.installDate));
    }
    if (rowData.selectedShift == null ? true : (rowData.selectedShift.name != this.batchUsageDialog.selectedShift.name)) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("shiftId", this.batchUsageDialog.selectedShift.id));
    }
    if (rowData.installer != this.batchUsageDialog.installer) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("vendorId", this.batchUsageDialog.installer.id));
    }
    if (rowData.crew != this.batchUsageDialog.crew) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("crewMembers", this.batchUsageDialog.crew));
    }
    if (rowData.jobNo != this.batchUsageDialog.jobNo) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("jobNumber", this.batchUsageDialog.jobNo));
    }
    if (rowData.selectedJobType != this.batchUsageDialog.selectedJobType) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("jobTypeId", this.batchUsageDialog.selectedJobType.id));
    }
    if (rowData.internalLabourInstManHours != this.batchUsageDialog.internalLabourInstManHours) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("internalLabourInstManHours", this.batchUsageDialog.internalLabourInstManHours));
    }
    if (rowData.internalLabourCost != this.batchUsageDialog.internalLabourCost) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("internalLabourCost", this.batchUsageDialog.internalLabourCost));
    }
    if (rowData.externalLabourInstManHours != this.batchUsageDialog.externalLabourInstManHours) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("externalLabourInstManHours", this.batchUsageDialog.externalLabourInstManHours));
    }
    if (rowData.externalLabourCost != this.batchUsageDialog.externalLabourCost) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("externalLabourCost", this.batchUsageDialog.externalLabourCost));
    }
    if (rowData.comments != this.batchUsageDialog.comments) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("comments", this.batchUsageDialog.comments));
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  bindShellPatchArrayObj() {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (this.shellRepairArea != this.originalShellRepairArea) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("shellRepairArea", this.shellRepairArea));
    }
    if (this.shellRepairCost != this.originalShellRepairCost) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("shellRepairCost", this.shellRepairCost));
    }
    if (this.shellRepairMatType != this.originalShellRepairMatType) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("shellRepairMatType", this.shellRepairMatType));
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  updateBatchDetails() {
    if (!this.validateBatchUsage()) {
      return;
    }
    var originalData = this.batchUsageDialog.originalData;
    var batchPatchModel = this.bindBatchPatchArrayObj(originalData);
    if (batchPatchModel.length > 0) {
      this.dailyUsageService.updateBatchUsage(originalData.id, batchPatchModel).subscribe(response => {
        this.batchUsageDialog.opened = false;
        this.batchUsageDialog.isUpdate = false;
        this.alertService.openAlert("Success!", "Batch Usage updated successfully!", "success");
        if (this.table_component) { this.table_component.reset(); }
        var globalData = this.shellService.loadGlobalSiteUnitOutage();
        this.loadGridCalculations(globalData.unitId, globalData.outageId);
      }, error => {
        this.batchUsageDialog.opened = false;
      })
    }
  }

  tempProductPatchArrayObj: any = [];
  bindBatchProductPatchObject(batchProduct: any) {
    this.tempProductPatchArrayObj = [];
    var patchArrayObj = [];
    if (batchProduct.productName != this.batchProductGridRowData.productName && batchProduct.productName != "" && batchProduct.productId > 0) {
      this.tempProductPatchArrayObj.push(this.shellService.createPatchArray("productId", batchProduct.productId))
    }
    if (batchProduct.qty != this.batchProductGridRowData.qty) {
      this.tempProductPatchArrayObj.push(this.shellService.createPatchArray("qty", batchProduct.qty))
    }
    if (batchProduct.productCost != this.batchProductGridRowData.productCost) {
      this.tempProductPatchArrayObj.push(this.shellService.createPatchArray("productCost", batchProduct.productCost))
    }

    if (this.dialogProductType == 'Refractory') {
      if (batchProduct.batchNumber != this.batchProductGridRowData.batchNumber) {
        this.tempProductPatchArrayObj.push(this.shellService.createPatchArray("batchNumber", batchProduct.batchNumber))
      }
      if (batchProduct.applicationMethodId != this.batchProductGridRowData.applicationMethodId) {
        this.tempProductPatchArrayObj.push(this.shellService.createPatchArray("applicationMethodId", batchProduct.applicationMethodId))
      }
    }

    if (this.tempProductPatchArrayObj.length > 0) {
      for (var i = 0; i < this.tempProductPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempProductPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  updateAnchorRefractoryDetails() {
    if (!this.validateBatchUsageProducts()) {
      return;
    }
    var batchProduct = {
      id: this.anchorRefractoryId,
      productName: this.selectedProduct ? this.selectedProduct.name : "",
      productId: this.selectedProduct ? this.selectedProduct.id : 0,
      productPosition: this.dialogProductPosition,
      applicationMethodId: this.selectedApplicationMethod ? this.selectedApplicationMethod.id : 0,
      qty: this.anchorRefractoryObj.qty,
      productCost: this.anchorRefractoryObj.productCost,
      batchNumber: this.anchorRefractoryObj.batchNumber,
    }
    var batchProductViewModel = this.bindBatchProductPatchObject(batchProduct);
    if (batchProductViewModel.length > 0) {
      this.dailyUsageService.updateAnchorRefractoryBatchProduct(batchProduct.id, batchProductViewModel).subscribe(response => {
        this.isApplicationMethodLoaded = false;
        this.isProductDataLoaded = false;
        this.anchorRefractoryDialog.opened = false;
        this.alertService.openAlert("success!", "Updated successfully!", "success");
        this.resetAnchorRefractoryDialog();
        this.bindAnchorRefractoryProductData(this.dialogProductType, batchProduct.productPosition); //refresh the grid
        this.dialogProductType = "";
        this.dialogProductPosition = "";
      }, error => {
        this.dialogProductType = "";
        this.dialogProductPosition = "";
        this.isApplicationMethodLoaded = false;
        this.isProductDataLoaded = false;
        this.anchorRefractoryDialog.opened = false;
        this.resetAnchorRefractoryDialog();
      })
    } 
  }

  deleteAnchorRefractoryRow(obj: any, productType: string, productPosition: string) {
    this.confirmationService.confirm({
      message: 'Delete ' + productType.toLowerCase() + ' ' + productPosition.toLowerCase() + ' product data?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.shellService.showLoadingIndicator(true);
        this.dailyUsageService.deleteAnchorRefractoryProduct(obj.id).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.alertService.openAlert("Deleted!", productType + ' ' + productPosition.toLowerCase() + ' product data deleted successfully', "success");
          this.bindAnchorRefractoryProductData(productType, productPosition); //refresh the grid
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }
  //#endregion
}
