import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AccessLevel, ShellService } from 'src/app/shared/services/shell.service';
import { AlertModalService } from 'src/app/shared/shared.module';
import { ProductTypeService } from '../services/product-type.service';
import { ProductTypeDialogViewModel } from '../view-models/ProductTypeDialogViewModel';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.css']
})
export class ProductTypeComponent implements OnInit {

  //#region variable declaration
  productTypes: any;
  cols: any[];
  first: number = 0;
  loading: boolean = false;
  isFilterModeOn: boolean = false;
  productTypeFilterName: string = "";
  id: number;
  isActive: boolean = true; //default is true
  deleteObj: any;
  accessLevel: AccessLevel;

  productTypeDialogModel: ProductTypeDialogViewModel = new ProductTypeDialogViewModel();

  //#endregion
  constructor(private productTypeService: ProductTypeService,
    private alertService: AlertModalService,
    private shellService: ShellService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
   this.loadProductTypeGridData();
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'name', header: 'Name' }];
  }

  resetFilterControls() {
    this.productTypeFilterName = "";
    this.isActive = true;
    this.loadProductTypeGridData();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }

  //#region grid data binding API call
  loadProductTypeGridData() {
    this.loading = true;
    this.productTypes = [];
    var filterData = {
      name: this.productTypeFilterName,
      active: this.isActive
    }
    this.productTypeService.searchProductType(filterData).subscribe(response => {
      if (response) {
        this.first = 0;
        this.productTypes = response;
      }
      this.headerInitialize();
      this.loading = false;
    })
  }

  // Product Type Dialog
  openProductTypeDialog(rowData: any = null): void {
    this.resetProductTypeDialog();
    if (rowData != null) {
      this.productTypeDialogModel.id = rowData.id;
      this.productTypeDialogModel.name = rowData.name;
      this.productTypeDialogModel.isUpdate = true;
      this.productTypeDialogModel.isVesselZone = rowData.isVesselZone;
      this.productTypeDialogModel.originalData = JSON.parse(JSON.stringify(this.productTypeDialogModel));
    } else {
      this.productTypeDialogModel.isUpdate = false;
      this.productTypeDialogModel.isVesselZone = true; //Vessel level is default set as true
    }
    this.productTypeDialogModel.isOpen = true;
  }

  resetProductTypeDialog(): void {
    this.productTypeDialogModel.name = "";
    this.productTypeDialogModel.isProductTypeEmpty = false;
  }

  addProductType(): void {
    if (this.productTypeDialogModel.name == "") {
      this.productTypeDialogModel.isProductTypeEmpty = true;
      return;
    }

    var productTypeObj = {
      name: this.productTypeDialogModel.name,
      isVesselZone: this.productTypeDialogModel.isVesselZone,
      active: true,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.productTypeService.addProductType(productTypeObj).subscribe((response) => {
      this.alertService.openAlert("Success!", "Product Type added successfully", "success");
      this.shellService.showLoadingIndicator(false);
      this.productTypeDialogModel.isOpen = false;
      this.loadProductTypeGridData();
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
      this.productTypeDialogModel.isOpen = false;
    })
  }

  updateProductType() {
    if (this.productTypeDialogModel.name == "") {
      this.productTypeDialogModel.isProductTypeEmpty = true;
      return;
    }

    var originalData = this.productTypeDialogModel.originalData;
    var patchModel = this.bindProductTypePatchArrayObj(originalData);
    if (patchModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.productTypeService.updateProductType(originalData.id, patchModel).subscribe(response => {
        this.productTypeDialogModel.isOpen = false;
        this.alertService.openAlert("Success!", "Product Type updated successfully!", "success");
        this.loadProductTypeGridData();
        this.shellService.showLoadingIndicator(false);
      }, error => {
        this.productTypeDialogModel.isOpen = false;
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  tempPatchArrayObj: any = []
  bindProductTypePatchArrayObj(originalData: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (originalData.name != this.productTypeDialogModel.name) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", this.productTypeDialogModel.name));
    }

    if (originalData.isVesselZone != this.productTypeDialogModel.isVesselZone) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("isVesselZone", this.productTypeDialogModel.isVesselZone));
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  deleteProductType(rowData: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this product type?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var patchArray = [
          {
            "value": false,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.productTypeService.updateProductType(rowData.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.alertService.openAlert("Deleted!", "Product Type deleted successfully", "success");
          this.loadProductTypeGridData();
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this product type?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var patchArray = [
          {
            "value": true,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.productTypeService.updateProductType(obj.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.alertService.openAlert("Activated!", "Product Type reactivated successfully", "success");
          this.loadProductTypeGridData();
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

}
