import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { catchError } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})

export class ProductTypeService {

  appReqHeader = new AppHttpHeader();
  productTypeAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/ProductType';
  
  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchProductType(filterProductTypeObj: any) {
    return this.http.post<any>(this.productTypeAccessPointUrl + '/SearchProductTypes', filterProductTypeObj, { headers: this.appReqHeader.Headers }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

  addProductType(productTypeObj: any) {
    return this.http.post<any>(this.productTypeAccessPointUrl, productTypeObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateProductType(productTypeId:number,productTypeObj: any) {
    var query = "";
    if (productTypeId) {
      query = "/" + productTypeId;
    }
    return this.http.patch<any>(this.productTypeAccessPointUrl + query, productTypeObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
