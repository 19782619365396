import { Injectable } from '@angular/core';
import { 
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { errorMessage } from 'src/app/shared/constant';
import { ShellService } from 'src/app/shared/services/shell.service';

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[]
  }
}

@Injectable({
    providedIn: 'root'
  })
export class RoleGuardService implements CanActivate {

  constructor(private authService: MsalService, private shellService: ShellService) {}
  
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    let account: Account = this.authService.instance.getAllAccounts()[0];

    if (!account.idTokenClaims?.roles) {
      this.shellService.showErrorScreen(errorMessage.rolesUndefined);
      return false;
    } else if (!account.idTokenClaims?.roles?.includes(expectedRole)) {
      this.shellService.showErrorScreen(errorMessage.unauthorized);
      return false;
    }
    return true;
  }
}