export const constDateObj={
  dateFormat: "MM/dd/yyyy"
}

export const defaultOutageType: number = 2

export const constProduct = {
  anchorType: "Anchor",
  refractoryType: "Refractory",
  positionBackup:"Backup",
  positionHotface:"Hotface",
}

export const errorMessage = {
  msalTimeout: "MSAL authorization timed out. RMS Access Denied! Please contact support.",
  rolesUndefined: "You do not currently have access to RMS. Please contact the administrator for access",
  unauthorized: "You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.",
  notFound: "Lost? Redirecting to Home..."
}

export const ProductTypeCode = {
  Anchor: 1,
  Refractory:2
}
