import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Unit, UnitViewModel } from '../models/unit';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShellService } from '../../../shared/services/shell.service';
import { Outage } from '../utilities.module';
import { Vessel } from '../models/vessel';


@Injectable({
  providedIn: 'root'
})
export class UnitService {
  appReqHeader = new AppHttpHeader();
  unitAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/Unit';
  fuelTypeAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/FuelType';

  //emit events to components which injected the service
  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();
  refreshUnitGridSubject = new Subject<any>();

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getUnits() {
    return this.http.get<Unit[]>(this.unitAccessPointUrl, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getUnitById(unitId: number) {
    return this.http.get<Unit>(this.unitAccessPointUrl + "/" + unitId.toString(), { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  filterUnits(FilterUnits: any) {
    return this.http.post<Unit[]>(this.unitAccessPointUrl +'/SearchUnits', FilterUnits, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }


  getFuelType() {
    return this.http.get<any>(this.fuelTypeAccessPointUrl, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addUnit(unitViewModel: any) {
    return this.http.post<number>(this.unitAccessPointUrl, unitViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateUnit(unitId: number, patchUnitViewModel: any) {
    var query = "";
    if (unitId) {
      query = "/" + unitId;
    }
    return this.http.patch<any>(this.unitAccessPointUrl + query, patchUnitViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteUnit(unitId: number) {
    var query = "";    
    if (unitId) {
      query = query + "/" + unitId;
    }
    return this.http.delete<any>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getOutageByUnitId(unitId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId + "/Outages";
    }
    return this.http.get<Outage[]>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getVesselByUnitId(unitId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId + "/Vessels";
    }
    return this.http.get<Vessel[]>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getNominalOutageByUnitId(unitId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId + "/NominalOutage";
    }
    return this.http.get<Outage>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getZoneBySiteUnitVesselId(unitId: number, vesselId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId;
    }
    if (vesselId != undefined) {
      query = query + "/Vessel/" + vesselId + "/Zones";
    }
    return this.http.get<any>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
