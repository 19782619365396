import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Site } from '../models/site';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Unit } from '../models/unit';
import { ShellService } from '../../../shared/services/shell.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  appReqHeader = new AppHttpHeader();
  siteAccessPointUrl: string = this.appReqHeader.DomainURL + 'api/Site';
  //emit events to components which injected the service
  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();
  refreshSiteGridSubject = new Subject<any>();

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getStaticSites() {
    return this.http.get<any>('assets/site.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => { return data; });
  }

  getUnitBySiteId(siteId: number) {
    var query = "";
    if (siteId != undefined) {
      query = "/" + siteId + "/Units";
    }
    return this.http.get<Unit[]>(this.siteAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getSites() {
    return this.http.get<Site[]>(this.siteAccessPointUrl, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getSiteById(siteId: number) {
    return this.http.get<Site>(this.siteAccessPointUrl + "/" + siteId.toString(), { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  searchSites(filterSites: any) {
    return this.http.post<Site[]>(this.siteAccessPointUrl +'/SearchSites', filterSites, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addSites(siteObj: any) {
    return this.http.post<any>(this.siteAccessPointUrl, siteObj, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(siteId: number, patchSiteViewModel: any) {
    var query = "";
    if (siteId) {
      query = "/" + siteId;
    }
    return this.http.patch<any>(this.siteAccessPointUrl + query, patchSiteViewModel, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteSite(id: number,) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.delete<any>(this.siteAccessPointUrl + query, { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
