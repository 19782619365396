// model used for dropdown
export class Unit {
  unitId: number; 
  unitName: string;
}

export class UnitViewModel {
  id: number;
  siteId: number;
  siteName: string;
  name: string;
  yearBuilt: number;
  markNumber: number;
  ratedCapacity: number;
  normalProduct: string;
  currentCapacity: number;
  averageVelocity: number;
  fuelTypeId: number;
  fuelType: string;
  averageFuelRate: number;
  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  active: boolean;
}
