import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { errorMessage } from '../../constant';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router) { }
  errorMessage: string =  errorMessage.notFound
  ngOnInit(): void {
    setTimeout(()=>{ this.router.navigate(['/']) }, 4000)
  }

}
