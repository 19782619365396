export class Zone {
  id: number;
  name: string;
}

export class UnitVesselZoneViewModel {
  siteId: number;
  siteName: string;
  siteActive: boolean;
  unitId: number;
  unitName: string;
  unitActive: boolean;
  vesselId: number;
  vesselName: string;
  vesselActive: boolean;
  zoneId: number;
  zoneName: string;
  zoneActive: boolean;
  operatingTemperature: number;
  particulateLoading: string;
  velocity: number;
  shellTemperature: number;
  hfGeometricCalcFactor: number;
  bkGeometricCalcFactor: number;
  mor: number;
  coldCrush: number;
  plc: number;
  abrasion: number;
  density: number;
  thermalShock: number;
  thermalExpansion: number;
  active: boolean;
  inactiveDate: Date;
  createdBy: string;
}

export class VesselZoneResponseViewModel {
  id: number;
  vesselUnit: UnitVesselZoneViewModel;
  name: string;
  active: boolean;
  modifiedBy: string;
}


