import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AccessLevel, ShellService } from 'src/app/shared/services/shell.service';
import { AlertModalService } from 'src/app/shared/shared.module';
import { Zone } from '../models/zone';
import { ZoneService } from '../services/zone.service';
import { ZoneDialogViewModel } from '../view-models/ZoneDialogViewModel';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.css']
})
export class ZoneComponent implements OnInit {
  accessLevel: AccessLevel;
  loading = false;
  zones: Zone[];
  cols: any;
  first: number = 0;
  isFilterModeOn = false;

  zoneFilterName : string;
  zoneFilterDescription: string;
  isActive = true;

  zoneDialogModel: ZoneDialogViewModel = new ZoneDialogViewModel();

  constructor(private zoneService: ZoneService, private shellService: ShellService,
    private alertService: AlertModalService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.headerInitialize();
    this.loadZoneGridData();
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' }];
  }

  resetFilterControls() {
    this.zoneFilterName = "";
    this.zoneFilterDescription = "";
    this.isActive = true;
    this.loadZoneGridData();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }

  //#region grid data binding API call
  loadZoneGridData() {
    this.loading = true;
    this.zones = [];
    var filterData = {
      name: this.zoneFilterName,
      description: this.zoneFilterDescription,
      active: this.isActive
    }
    this.zoneService.searchZones(filterData).subscribe(response => {
      if (response) {
        this.first = 0;
        this.zones = response;
      } else {
        this.zones = [];
      }
      this.loading = false;
    })
  }

  // Zone Dialog
  openZoneDialog(rowData: any = null): void {
    this.resetZoneDialog();
    if (rowData != null) {
      this.zoneDialogModel.id = rowData.id;
      this.zoneDialogModel.zoneName = rowData.name;
      this.zoneDialogModel.zoneDescription = rowData.description;
      this.zoneDialogModel.isUpdate = true;
      this.zoneDialogModel.originalData = JSON.parse(JSON.stringify(this.zoneDialogModel));
    } else {
      this.zoneDialogModel.isUpdate = false;
    }

    this.zoneDialogModel.isOpen = true;
  }

  resetZoneDialog(): void {
    this.zoneDialogModel.zoneName = "";
    this.zoneDialogModel.zoneDescription = "";
    this.zoneDialogModel.isZoneNameEmpty = false;
  }

  addZone(): void {
    if (this.zoneDialogModel.zoneName == "") {
      this.zoneDialogModel.isZoneNameEmpty = true;
      return;
    }

    var zoneObj = {
      name: this.zoneDialogModel.zoneName,
      description: this.zoneDialogModel.zoneDescription,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.zoneService.addZone(zoneObj).subscribe((response) => {
      this.alertService.openAlert("Success!", "Zone added successfully", "success");
      this.shellService.showLoadingIndicator(false);
      this.zoneDialogModel.isOpen = false;
      this.loadZoneGridData();
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
      this.zoneDialogModel.isOpen = false;
    })
  }

  updateZone() {
    if (this.zoneDialogModel.zoneName == "") {
      this.zoneDialogModel.isZoneNameEmpty = true;
      return;
    }

    var originalData = this.zoneDialogModel.originalData;
    var batchPatchModel = this.bindZonePatchArrayObj(originalData);
    if (batchPatchModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.zoneService.patchUpdate(originalData.id, batchPatchModel).subscribe(response => {
        this.zoneDialogModel.isOpen = false;
        this.alertService.openAlert("Success!", "Zone updated successfully!", "success");
        this.loadZoneGridData();
        this.shellService.showLoadingIndicator(false);
      }, error => {
        this.zoneDialogModel.isOpen = false;
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  tempPatchArrayObj: any = []
  bindZonePatchArrayObj(originalData: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (originalData.name != this.zoneDialogModel.zoneName) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", this.zoneDialogModel.zoneName));
    }

    if (originalData.zoneDescription != this.zoneDialogModel.zoneDescription) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("description", this.zoneDialogModel.zoneDescription));
    }
    
    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  deleteZone(rowData: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this zone?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var patchArray = [
          {
            "value": false,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.zoneService.patchUpdate(rowData.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.loadZoneGridData();
          this.alertService.openAlert("Deleted!", "Zone deleted successfully", "success");
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

  reactivateZone(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this zone?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        var patchArray = [
          {
            "value": true,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.zoneService.patchUpdate(obj.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.loadZoneGridData();
          this.alertService.openAlert("Activated!", "Zone reactivated successfully", "success");
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }
}
