import { Component, OnInit } from '@angular/core';
import { ShellService } from '../../services/shell.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBars, faAlignJustify
} from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { roles } from '../../../core/auth/app-role.const';

@Component({
  selector: 'app-headerbar',
  templateUrl: './headerbar.component.html',
  styleUrls: ['./headerbar.component.css', './headerbar.component.responsive.css']
})
export class HeaderbarComponent implements OnInit {
  faBars = faBars;
  faAlignJustify = faAlignJustify;
  environmentName = "Redirect URI Not setup";
  currentUrl = '';
  showFlatoutPreview = false;
  currentPage: string = "";
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];

  private readonly _destroying$ = new Subject<void>();

  constructor(private shellService: ShellService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.currentPage = "Home"
        }
        
        if (event.url === '/403') {
          this.currentPage = "Access Denied"
        }
        
        if (event.url.includes('/calciner-setup')) {
          this.currentPage = "Equipment Setup"
        } else if (event.url === '/site') {
          this.currentPage = "Site"
        } else if (event.url === '/unit') {
          this.currentPage = "Site-Unit"
        } else if (event.url === '/unit-vessel') {
          this.currentPage = "Site-Unit-Vessel"
        } else if (event.url === '/unit-vessel-zone') {
          this.currentPage = "Site-Unit-Vessel-Zone"
        } else if (event.url === '/nominal-lining') {
          this.currentPage = "Nominal Lining"
        } else if (event.url === '/flatout-uploader') {
          this.currentPage = "Flatout Upload"
        }

        if (event.url.includes('/future-outage-setup')) {
          this.currentPage = "Outage Setup"
        } else if (event.url === '/outage') {
          this.currentPage = "Outages"
        } else if (event.url === '/estimate-refractory-materials') {
          this.currentPage = "Estimate Refractory Materials"
        }

        if (event.url === '/product') {
          this.currentPage = "Product Information"
        }

        if (event.url === '/category-product') {
          this.currentPage = "Product Categories"
        }

        if (event.url.includes('/shutdown-execution')) {
          this.currentPage = "Shutdown Execution"
          this.showFlatoutPreview = event.url.includes('flatout');
        } else if (event.url === '/inspection') {
          this.currentPage = "Inspection"
        } else if (event.url === '/dailyusage') {
          this.currentPage = "Daily Usage by Installation"
        } else if (event.url === '/bulk-loader') {
          this.currentPage = "Bulk Upload"
        } else if (event.url === '/flatout-editor') {
          this.currentPage = "Flatout Editor"
        }

        this.showFlatoutPreview = event.url.includes('/flatout')

        if (event.url.includes('/reports/')) {
          this.currentPage = "Reports"
        } else if (event.url.includes('/shutdown-review/')) {
          this.currentPage = "Shutdown Review"
        }

        if (event.url === '/vendor') {
          this.currentPage = "Vendors"
        }

        if (event.url === '/vessel') {
          this.currentPage = "Vessels"
        }

        if (event.url === '/zone') {
          this.currentPage = "Zones"
        }
        if (event.url === '/product-type') {
          this.currentPage = "Product Type"
        } 
      }
    });

  }
  ngOnInit(): void {
    this.currentUrl = window.location.href;
    this.environmentName = environment.environmentName;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result?.payload) {
          const payload: AuthenticationResult = result.payload as AuthenticationResult
          this.msalService.instance.setActiveAccount(payload.account);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
          this.setUI()
      });
    this.shellService.preventUserAccess.subscribe((message) => {
      this.router.navigateByUrl("/403");
    })
  }

  setUI(retries: number = 4) {
    setTimeout(() => {
      if (this.msalService.instance.getActiveAccount() != null) {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(this.msalService.instance.getActiveAccount()?.idTokenClaims);
        let userName = this.msalService.instance.getActiveAccount()?.username
        if (userName) {
          this.setUserDataInLocalStorage(userName);
        }
      } else if (retries > 0) {
        this.setUI(retries - 1)
      } else {
        return;
      }
    }, 500);
  }
  logOut() {
    this.msalService.logout();
  }
  toggleSideBar() {
    if (this.dataSource[3]['value']) {
      this.shellService.toggleSidebarVisibility()
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this.dataSource = [
      { id: 1, claim: "Display Name", value: claims ? claims['name'] : null },
      { id: 2, claim: "User Principal Name (UPN)", value: claims ? claims['preferred_username'] : null },
      { id: 3, claim: "OID", value: claims ? claims['oid'] : null },
      { id: 4, claim: "Roles", value: claims ? claims['roles'] : null }
    ];
    const available_Roles = [roles.Admin, roles.BasicUser, roles.Contractor, roles.ReadOnly]
    var role = '';
    for (let i = 0; i < available_Roles.length; i++)
    {
      if (claims['roles'].includes(available_Roles[i]))
      {
        role = available_Roles[i];
        break;
      }
    }
    this.shellService.setRoles(role); //setting the current role in shell service 
  }
  
  setUserDataInLocalStorage(userName: string) {
    localStorage.setItem("user", userName)
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  showHelpDocument() {
    const PDF = '../../../../assets/docs/manual.pdf'
    window.open(PDF)
  }
}
