import { Component, OnInit } from '@angular/core';
import { AccessLevel, GlobalSiteUnitOutage, ShellService } from 'src/app/shared/services/shell.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'flatout-uploader',
  templateUrl: './flatout-uploader.component.html',
  styleUrls: ['./flatout-uploader.component.css']
})
export class FlatoutUploaderComponent implements OnInit {

  constructor(private shellService: ShellService) { }

  siteUnitOutage: GlobalSiteUnitOutage | undefined = undefined;

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      if (accessLevel != AccessLevel.Administrator) {
        this.shellService.showErrorScreen("You don't have access to Flatout Uploader");
      }
    });

    this.siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);

    if (this.siteUnitOutage.unitId > 0) {
      this.openFlatoutEditor();
    }

    this.shellService.globalSiteUnitOutageSet.subscribe((obj) => {
      this.siteUnitOutage = obj;
      this.openFlatoutEditor();
    })
  }

  openFlatoutEditor() {
    if (this.siteUnitOutage) {
      var url = environment.flatoutUrl + "upload?" +
        "siteId=" + this.siteUnitOutage?.siteId +
        "&unitId=" + this.siteUnitOutage?.unitId;
      
      window.open(url, "_blank");
    }
  }
}
