import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppHttpHeader } from 'src/app/shared/shared.module';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Country } from '../models/country';
import { ShellService } from '../../../shared/services/shell.service';

@Injectable({
  providedIn: 'root'
})

export class CountryService {

  appReqHeader = new AppHttpHeader();
  baseURL = this.appReqHeader.DomainURL;
  accessPointUrl: string = this.baseURL + 'api/Country';

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getCountries() {
    return this.http.get<Country[]>(this.accessPointUrl , { headers: this.appReqHeader.Headers }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
