import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './module/home/home.module';
import { UserComponent } from './module/admin/admin.module';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuardService } from './core/auth/role-guard.service';
import { roles } from './core/auth/app-role.const';
import { SiteComponent } from './module/utilities/site/site.component';
import { UnitComponent } from './module/utilities/unit/unit.component';
import { UnitVesselComponent } from './module/utilities/unit-vessel/unit-vessel.component';
import { CalcinerSetupComponent } from './module/wizards/calciner-setup/calciner-setup.component';
import { UnitVesselZoneComponent } from './module/utilities/unit-vessel-zone/unit-vessel-zone.component';
import { NominalLiningComponent } from './module/utilities/nominal-lining/nominal-lining.component';
import { OutageComponent } from './module/utilities/outage/outage.component';
import { EstimateRefractoryMaterialsComponent } from './module/utilities/estimate-refractory-materials/estimate-refractory-materials.component';
import { NotFoundComponent } from './shared/error-pages/not-found/not-found.component';
import { FutureOutageSetupComponent } from './module/wizards/future-outage-setup/future-outage-setup.component';
import { CategoryProductComponent } from './module/utilities/category-product/category-product.component';
import { InspectionComponent } from './module/data-entry/inspection/inspection.component';
import { AccessDeniedComponent } from './shared/error-pages/access-denied/access-denied.component';
import { ProductComponent } from './module/utilities/product/product.component';
import { DailyUsageComponent } from './module/data-entry/daily-usage/daily-usage.component';
import { VesselComponent } from './module/utilities/vessel/vessel.component';
import { ZoneComponent } from './module/utilities/zone/zone.component';
import { VendorComponent } from './module/utilities/vendor/vendor.component';
import { ShutdownExecutionComponent } from './module/wizards/shutdown-execution/shutdown-execution.component';
import { ShutdownReviewComponent } from './module/wizards/shutdown-review/shutdown-review.component';
import { BulkLoaderComponent } from './module/data-entry/bulk-loader/bulk-loader.component';
import { ReportsComponent } from './module/reports/reports.component';
import { ProductTypeComponent } from './module/utilities/product-type/product-type.component';
import { FlatoutEditorComponent } from './module/data-entry/flatout-editor/flatout-editor.component';
import { FlatoutUploaderComponent } from './module/data-entry/flatout-uploader/flatout-uploader.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: "full",
    canActivate: [
      MsalGuard,
    ],
  },
  {
    path: 'user-access',
    component: UserComponent,
    canActivate: [
      MsalGuard,
      RoleGuardService,
    ],
    data: {
      expectedRole: roles.Admin
    }
  },
  {
    // Needed for hash routing
    path: 'state',
    component: HomePageComponent
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HomePageComponent
  },
  {
    // Needed for hash routing
    path: 'error',
    component: HomePageComponent
  },
  {
    path: '403',
    component: AccessDeniedComponent
  },
  { path: 'site', component: SiteComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'outage', component: OutageComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'unit', component: UnitComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'unit-vessel', component: UnitVesselComponent, canActivate: [MsalGuard] },
  { path: 'unit-vessel-zone', component: UnitVesselZoneComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'vessel', component: VesselComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'zone', component: ZoneComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'vendor', component: VendorComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'nominal-lining', component: NominalLiningComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'estimate-refractory-materials', component: EstimateRefractoryMaterialsComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'calciner-setup/:comp', component: CalcinerSetupComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'future-outage-setup/:comp', component: FutureOutageSetupComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'shutdown-execution/:comp', component: ShutdownExecutionComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'shutdown-review/:comp', component: ShutdownReviewComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'category-product', component: CategoryProductComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'product', component: ProductComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'product-type', component: ProductTypeComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: 'inspection', component: InspectionComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: 'flatout-editor', component: FlatoutEditorComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: 'flatout-uploader', component: FlatoutUploaderComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: 'dailyusage', component: DailyUsageComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: 'bulk-loader', component: BulkLoaderComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: 'reports/:uri', component: ReportsComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: '**', component: NotFoundComponent, pathMatch: "full"}
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabled' : 'disabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
