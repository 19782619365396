export class VesselDialogViewModel {
  isOpen: boolean;
  isUpdate: boolean;

  id: number;
  vesselName: string;
  vesselDescription: string;
  
  originalData: VesselDialogViewModel;

  isVesselNameEmpty: boolean;
}