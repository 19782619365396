import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { AccessLevel, ShellService } from 'src/app/shared/services/shell.service';
import { AlertModalService } from 'src/app/shared/shared.module';
import { CategoryType } from '../models/categoryType';
import { ProductCategory } from '../models/productCategory';
import { CategoryProductService } from '../services/category-product.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-category-product',
  templateUrl: './category-product.component.html',
  styleUrls: ['./category-product.component.css']
})
export class CategoryProductComponent implements OnInit {
  // Filter
  isActive = true
  isFilterModeOn = false
  filterCategoryName = ""
  filterCategoryType: CategoryType | any
  categoryTypes: CategoryType[]

  // Grid
  loading = false
  cols: any[]
  first: number = 0;
  categories: ProductCategory[]
  pageSize: number = 8
  pageNo: number = 1
  totalCount: number = 0
  @ViewChild('dt') table_component: Table;

  // Dialog
  originalCategoryData: {
    name: string,
    desc: string,
    type: CategoryType
  }
  
  categoryId: number = 0
  categoryName = ""
  selectedCategoryType: any
  categoryDescription = ""

  accessLevel: AccessLevel
  
  constructor(private categoryService: CategoryProductService,
              private productService: ProductService,
              private shellService: ShellService,
              private alertService: AlertModalService,
              private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })
    // Get filter data
    this.getCategoryTypes();
    this.cols = [
      { field: 'productTypeName', header: 'Category Type'},
      { field: 'category', header: 'Category'},
      { field: 'description', header: 'Description'}
    ]
  }

  getCategoryTypes(): void {
    this.shellService.showLoadingIndicator(true);
    this.categoryTypes = [];
    this.productService.getProductTypes().subscribe ((response) => {
      this.categoryTypes = response;
      this.shellService.showLoadingIndicator(false);
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  // Filter
  showFilter(): void {
    this.isFilterModeOn = true
  }

  closeFilter(): void {
    this.isFilterModeOn = false
  }

  filterRecords(field?: string, order?: number): void {
    var queryObject = {
      active: this.isActive,
      productTypeId: this.filterCategoryType ? this.filterCategoryType.id : 0,
      categoryName: this.filterCategoryName
    }
    this.loading = true;
    this.first = 0;
    this.categoryService.searchCategories(queryObject, this.pageNo, this.pageSize, field, order).subscribe ((response) => {
      if (response) {
        // console.log(response);
        this.categories = response.result;
        this.totalCount = response.pagination.TotalCount;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  onLazyLoad(event:LazyLoadEvent)
  {
    if (event != undefined && event.first != undefined && event.rows != undefined) {
      this.pageNo=Math.ceil(event.first/event.rows)+1 //determine the pageeeno and send that to backend 
      this.pageSize=event.rows;
      if (event.multiSortMeta != null && event.multiSortMeta != undefined) {
        this.filterRecords (event.multiSortMeta[0].field, event.multiSortMeta[0].order);
      } else {
        this.filterRecords ();
      }
    }
  }

  resetFilterControls(): void {
    this.filterCategoryName = "";
    this.filterCategoryType = undefined;
    this.isActive = true;
    this.categories = [];
    this.first = 0;
  }

  // Grid
  editRow(data: any): void {
    this.toggleCategoryDialog(true);
    this.categoryId = data.id;
    this.categoryName = data.category;
    this.categoryDescription = data.description;
    this.selectedCategoryType = this.categoryTypes.filter(c => c.name == data.productTypeName)[0];

    this.originalCategoryData = {
      name: this.categoryName,
      desc: this.categoryDescription,
      type: this.selectedCategoryType
    }
  }

  deleteRow(data: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this product category?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        let userName = localStorage.getItem('user');
        let modifiedBy = userName ? userName : "";
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", false))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
    
        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }
        
        var siteViewModel = patchArrayObj;
       
        if (siteViewModel.length > 0 && data.id) {
          this.shellService.showLoadingIndicator(true);
          this.categoryService.patchUpdate(data.id, siteViewModel).subscribe(response => {
            this.alertService.openAlert("Deleted!", "Product Category deleted successfully!", "success");
            this.shellService.showLoadingIndicator(false);
            if(this.table_component) { this.table_component.reset(); }
          }, error => {
            this.alertService.openAlert("Error!", error, "error");
          })
        }
      }
    });
  }

  reactivateRow(data: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this product category?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        let userName = localStorage.getItem('user');
        let modifiedBy = userName ? userName : "";
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
    
        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }
        
        var siteViewModel = patchArrayObj;
       
        if (siteViewModel.length > 0 && data.id) {
          this.shellService.showLoadingIndicator(true);
          this.categoryService.patchUpdate(data.id, siteViewModel).subscribe(response => {
            this.alertService.openAlert("Activated!", "Product Category activated successfully!", "success");
            this.shellService.showLoadingIndicator(false);
            if(this.table_component) { this.table_component.reset(); }
          }, error => {
            this.alertService.openAlert("Error!", error, "error");
          })
        }
      }
    });
  }

  // Dialogs
  categoryDialog = {
    opened: false,
    isUpdate: false,
    isTypeEmpty: false,
    isCategoryNameEmpty: false,
    isDescriptionEmpty: false
  }

  toggleCategoryDialog(update: boolean = false): void {
    this.categoryDialog.opened = !this.categoryDialog.opened;
    this.categoryDialog.isUpdate = update;
    this.resetCategoryDialog();
  }

  validateCategoryDialog(): boolean {
    var isValid = true;
    let catType = this.selectedCategoryType ? this.selectedCategoryType.name : "";
    if (catType) {
      this.categoryDialog.isTypeEmpty = false;
    } else {
      isValid = false;
      this.categoryDialog.isTypeEmpty = true;
    }
    var catName = this.categoryName.trim();
    if (catName) {
      this.categoryDialog.isCategoryNameEmpty = false;
    } else {
      this.categoryDialog.isCategoryNameEmpty = true;
      isValid = false;
    }
    var desc = this.categoryDescription.trim();
    if (desc)  {
      this.categoryDialog.isDescriptionEmpty = false;
    } else {
      this.categoryDialog.isDescriptionEmpty = true;
      isValid = false;
    }
    return isValid;
  }

  addCategory(): void {
    if (!this.validateCategoryDialog()) {
      return;
    }
    let catObj = {
      id: 0,
      category: this.categoryName,
      description: this.categoryDescription,
      productTypeId: this.selectedCategoryType.id,
      productTypeName: this.selectedCategoryType.name,
      createdDate: new Date()     
    }
    this.categoryService.addCategory(catObj).subscribe(response => {
      var catTypeId = this.selectedCategoryType.id;
      this.toggleCategoryDialog();
      this.alertService.openAlert("Success!", "Saved Successfully!", "success");
      this.filterCategoryType = this.categoryTypes.filter(x => x.id == catTypeId)[0];
      if(this.table_component) { this.table_component.reset(); }
    }, error => {
      this.toggleCategoryDialog();
      this.alertService.openAlert("Error!", error, "error");
    })
  }

  editCategory(): void {
    if (!this.validateCategoryDialog()) {
      return;
    }

    var patchArray = this.makePatchObject();
    if (patchArray.length > 0) {
      this.categoryService.patchUpdate(this.categoryId, patchArray).subscribe ((response) => {
        this.toggleCategoryDialog();
        this.alertService.openAlert("Success!", "Updated Successfully!", "success");
        if(this.table_component) { this.table_component.reset(); }
      }, (error) => {
        this.toggleCategoryDialog();
        this.alertService.openAlert("Error!", error, "error");
        if(this.table_component) { this.table_component.reset(); }
      })
    }
  }

  makePatchObject(): any[] {
    var tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (this.categoryName != this.originalCategoryData.name) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("category", this.categoryName))
    }
    if (this.categoryDescription != this.originalCategoryData.desc) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("description", this.categoryDescription))
    }
    if (this.selectedCategoryType.id != this.originalCategoryData.type.id) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("productTypeId", this.selectedCategoryType.id))
    }  

    if (tempPatchArrayObj.length > 0) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
      for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  resetCategoryDialog(): void {
    this.selectedCategoryType = undefined;
    this.categoryName = "";
    this.categoryDescription = "";
    this.categoryDialog.isCategoryNameEmpty = false;
    this.categoryDialog.isDescriptionEmpty = false;
    this.categoryDialog.isTypeEmpty = false;
  }
}
