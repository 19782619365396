import { Vendor } from "../../utilities/models/vendor";
import { JobType } from "./jobtype-viewmodel";
import { Shift } from "./shift-viewmodel";

export class BatchUsageDialogViewModel{
  id: number;
  opened: boolean = false;
  isUpdate: boolean = false;
  vessels: any;
  zones: any;
  selectedVessel: any;
  selectedZone: any;
  shiftOptions: Shift[];
  selectedShift: Shift | any;
  installDate: Date = new Date();
  installer: Vendor | any;
  installers: Vendor[];
  crew: string | any;
  jobNo: string | any;
  jobTypes: JobType[];
  selectedJobType: any;
  internalLabourInstManHours: number;
  internalLabourCost: number;
  externalLabourInstManHours: number;
  externalLabourCost: number;
  comments: string | any;

  // Validation
  isVesselNameEmpty: boolean = false;
  isZoneNameEmpty: boolean = false;
  isInstallerEmpty: boolean = false;
  isCrewEmpty: boolean = false;
  isJobNoEmpty: boolean = false;
  isJobTypeEmpty: boolean = false;
  isOnSiteHoursEmpty: boolean = false;
  isOnSiteCostEmpty: boolean = false;
  isOffSiteHoursEmpty: boolean = false;
  isOffSiteCostEmpty: boolean = false;

  // Original Data
  originalData: BatchUsageDialogViewModel;
}