import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteComponent } from './site/site.component';
import { TableModule } from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
export * from './site/site.component';
export { Site } from './models/site';
export { Unit } from './models/unit';
export { Outage} from './models/outage';
export { SiteService } from './services/site.service';
export { UnitService } from './services/unit.service';
export { OutageService } from './services/outage.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TableModule,
    BrowserAnimationsModule,
    MultiSelectModule,
    FormsModule,
    HttpClientModule
  ]
})

export class UtilitiesModule { }
