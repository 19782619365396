import { Component, OnInit } from '@angular/core';
import { Site } from '../models/site';
import { SiteService } from '../services/site.service';
import { SelectItem, SelectItemGroup, PrimeNGConfig, LazyLoadEvent, ConfirmationService } from "primeng/api";
import { AlertModalService } from '../../../shared/services/alert-modal.service';
import { AccessLevel, ShellService } from '../../../shared/services/shell.service';
import { Outage } from '../models/outage';
import { OutageService } from '../services/outage.service';
import { Unit } from '../models/unit';
import { formatDate } from '@angular/common';
import { defaultOutageType } from 'src/app/shared/constant';
import { UniversalTimeService } from 'src/app/shared/services/universal-time.service';

@Component({
  selector: 'outage',
  templateUrl: './outage.component.html',
  styleUrls: ['./outage.component.css']
})
export class OutageComponent implements OnInit {
  accessLevel: AccessLevel;
  //#region variable declaration
  sites: Site[];
  units: Unit[];
  unitsForSelection: Unit[];
  outages: Outage[];
  cols: any[];
  loading: boolean = false;
  selectedFilterSite: any;
  selectedFilterUnit: any;
  isFilterModeOn: boolean = false;
  siteFilterName: string = "";
  id: number;
  isActive: boolean = true; //default is true
  deleteObj: any;

  // Pagination
  pageNo: number = 1;
  pageSize: number = 8;
  totalCount: number = 0;
  first: number = 0;
  firstTimeFilterUsed: boolean = false;

  // Edit dialog data
  outageNameModifed = "";
  editOutageName: string = "";
  editOutageComment: string = "";
  editStartDate: Date = new Date();
  editEndDate: Date = new Date();
  editing: boolean = false;
  siteName: string = "";
  unitName: string = "";
  selectedSite: any;
  selectedUnit: any;
  name = "";
  comments = "";
  outageStartDate = new Date();
  outageEndDate = new Date();

  filterObject = {
    siteId: "",
    unitId: "",
    active: true
  }
  //#endregion
  constructor(private siteService: SiteService,
    private outageService: OutageService,
    private alertService: AlertModalService,
    private shellService: ShellService,
    private confirmationService: ConfirmationService,
    private timeService: UniversalTimeService) { }

  ngOnInit(): void {    
    this.shellService.getAccessLevel().then((accessLevel) => {
      this.accessLevel = accessLevel;
    })   
    this.siteService.getSites().subscribe(response => {
      this.sites = response;
      let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
      if (siteUnitOutage.siteId > 0) {
        this.selectedFilterSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
        this.getUnitsForFilter(this.selectedFilterSite.id);
      } else {
        this.filterRecords(undefined);
      }
    });
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'name', header: 'Name', width: this.accessLevel > AccessLevel.Contractor ? '14%' : '20%'},
      { field: 'unitName', header: 'Unit', width: '15%' },
      { field: 'siteName', header: 'Site', width: '15%' },
      { field: 'outageStartDate', header: 'Outage Start Date', width: this.accessLevel > AccessLevel.Contractor ? '20%' : '25%', format: 'L' },
      { field: 'outageEndDate', header: 'Outage End Date' , width: this.accessLevel > AccessLevel.Contractor ? '20%' : '25%', format: 'L'}];
  }

  getFormattedDate(date: any, format: string) {
    if (!date) {
      return ""
    }
    return this.timeService.convertFromUTC(date, format);
  }
  //#region grid data binding API call
  loadOutageGridData(field?: string, order?: number) {
    this.loading = true;
    this.first = 0;
    this.outages = []
    this.filterObject.siteId = this.selectedFilterSite ? this.selectedFilterSite.id + "" : "";
    this.filterObject.unitId = this.selectedFilterUnit ? this.selectedFilterUnit.unitId + "" : "";
    this.filterObject.active = this.isActive;
    this.outageService.getOutage(this.filterObject,
        this.pageNo,
        this.pageSize,
        field,
        order).subscribe(response => {
      if (response) {
        // console.log(response);
        this.outages = response.result;
        this.totalCount = response.pagination.TotalCount;
      }
      this.headerInitialize();
      this.loading = false;
      this.firstTimeFilterUsed = true;
    })      
  }
  onLazyLoad(event:LazyLoadEvent)
  {
    if (this.firstTimeFilterUsed) {
      if (event != undefined && event.first != undefined && event.rows != undefined) {
        this.pageNo=Math.ceil(event.first/event.rows)+1 //determine the pageeeno and send that to backend 
        this.pageSize=event.rows;
        if (event.multiSortMeta != null && event.multiSortMeta != undefined) {
          this.loadOutageGridData (event.multiSortMeta[0].field, event.multiSortMeta[0].order);
        } else {
          this.loadOutageGridData ();
        }
      }
    }
  }
  //#endregion

  //#region filter methods
  filterRecords(dt: any) {
    if (dt != undefined && dt.multiSortMeta != null && dt.multiSortMeta != undefined) {
      this.loadOutageGridData (dt.multiSortMeta[0].field, dt.multiSortMeta[0].order);
    } else {
      this.loadOutageGridData ();
    }
  }

  resetFilterControls() {
    this.siteFilterName = "";
    this.selectedFilterSite = [];
    this.selectedFilterUnit = [];
    this.isActive = true;
    this.loadOutageGridData();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }
  //#endregion

  //#region modal popup
  outageDialog = {
    opened: false,
    isSiteEmpty: false,
    isUnitEmpty: false,
    isOutageNameEmpty: false,
    isCommentEmpty: false,
    isStartDateEmpty: false,
    isEndDateEmpty:false
  }

  toggleoutageDialog() {
    this.editing = false;
    this.outageDialog.opened = !this.outageDialog.opened;
    this.resetOutageDialog();
  }

  getUnitsBySite(siteId: number) {
    this.siteService.getUnitBySiteId(siteId).subscribe(response => {
      this.unitsForSelection = response;
      // console.log(response)
    });
  }

  getUnitsForFilter(siteId: number) {
    this.siteService.getUnitBySiteId(siteId).subscribe(response => {
      this.units = response;
      let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
      if (siteUnitOutage.unitId > 0) {
        this.selectedFilterUnit = this.units.filter(u => u.unitId == siteUnitOutage.unitId)[0];
        this.filterRecords(undefined);
      }
    });
  }

  updateName() {
    this.outageNameModifed = this.selectedUnit.unitName + " - " + formatDate(new Date(this.editStartDate),
                            'dd/MM/yyyy', 'en-AU');
    if (this.editOutageName.length > 0) {
      this.outageNameModifed = this.outageNameModifed + " [" + this.editOutageName + "]";
    }
    // console.log(this.outageNameModifed)
  }

  editRow(obj: any) {
    this.editing = true;
    this.outageDialog.opened = true;
    this.id = obj.id;
    this.siteName = obj.siteName;
    this.unitName = obj.unitName;
    this.name = obj.name;
    this.comments = obj.comments;
    this.editOutageComment = obj.comments;
    
    this.selectedSite = this.sites.filter(item => {
      return item.name == obj.siteName;
    })[0];
    this.siteService.getUnitBySiteId(this.selectedSite.id).subscribe(response => {
      this.unitsForSelection = response;
      console.log(response)
      this.selectedUnit = this.unitsForSelection.filter(item => {
        return item.unitName == obj.unitName;
      })[0];
    });
    this.outageStartDate = new Date(this.timeService.convertFromUTC(obj.outageStartDate));
    this.outageEndDate = new Date(this.timeService.convertFromUTC(obj.outageEndDate));
    this.editStartDate = new Date(this.timeService.convertFromUTC(obj.outageStartDate));
    this.editEndDate = new Date(this.timeService.convertFromUTC(obj.outageEndDate));
    this.outageNameModifed = obj.unitName + " - " + formatDate(new Date(this.editStartDate), 'dd/MM/yyyy', 'en-AU');
    // Check if suffix is already added
    if (obj.name.includes(this.outageNameModifed + " [")) {
      this.outageNameModifed += " [";
      this.editOutageName = obj.name.replace(this.outageNameModifed, "");
      this.editOutageName = this.editOutageName.replace("]", "");
      this.outageNameModifed = this.outageNameModifed + this.editOutageName + "]";
    } else if (obj.name.includes(this.outageNameModifed)) {
      // Suffix not present but check if the name is the format: unit-date already
      this.editOutageName = "";
    } else {
      // No suffix but the name is completely out of format
      this.editOutageName = obj.name;
    }
  }

  resetOutageDialog() {
    this.editOutageName = "";
    this.editStartDate = new Date();
    this.editEndDate = new Date();
    if (!this.editing) {
      let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
      if (siteUnitOutage.siteId > 0) {
        this.selectedSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
        this.siteService.getUnitBySiteId(this.selectedSite.id).subscribe(response => {
          this.unitsForSelection = response;
          this.selectedUnit = this.unitsForSelection.filter(u => u.unitId == siteUnitOutage.unitId)[0];
          this.updateName();
        });
      }
    }
    this.editOutageComment = "";
    this.outageDialog.isSiteEmpty = false;
    this.outageDialog.isUnitEmpty = false;
    this.outageDialog.isStartDateEmpty = false;
    this.outageDialog.isEndDateEmpty = false;
  }

  updateOutageDetails() {
    if (!this.validateoutageDialog()) {
      return;
    }
    let userName = localStorage.getItem('user');
    let outageObj: Outage = {
      id: this.id,
      name: this.editOutageName,
      comments: this.editOutageComment,
      outageTypeId: defaultOutageType,
      siteName: this.selectedSite.name,
      unitName: this.selectedUnit.unitName,
      siteUnitID: this.selectedUnit.unitId == 0 ? 3 : this.selectedUnit.unitId,
      outageStartDate: new Date(Date.UTC(this.editStartDate.getFullYear(),
                                          this.editStartDate.getMonth(),
                                          this.editStartDate.getDate())),
      outageDate: "",
      outageEndDate: new Date(Date.UTC(this.editEndDate.getFullYear(),
                                        this.editEndDate.getMonth(),
                                        this.editEndDate.getDate())),
      createdBy: userName ? userName : "",
      createdDate: new Date(),
      modifiedBy: userName ? userName : "",
      generalComments: "",
    }

    var patchModel = this.bindOutagePatchObject(outageObj);

    if (patchModel.length > 0){
      this.outageService.patchUpdate(outageObj.id, patchModel).subscribe(response => {
        this.toggleoutageDialog();
      this.alertService.openAlert("Success!", "Updated successfully!", "success");
      this.loadOutageGridData();
      }, error => {
        this.toggleoutageDialog();
        this.loadOutageGridData();
      })
    }

  }

  addOutageDetails() {
    if (!this.validateoutageDialog()) {
      return;
    }
    
    let userName = localStorage.getItem('user');
    let outageObj: Outage = {
      id: 0,
      name: this.outageNameModifed,
      comments: "",
      siteName: this.selectedSite.name,
      unitName: this.selectedUnit.unitName,
      siteUnitID: this.selectedUnit.unitId,
      outageTypeId: defaultOutageType,
      outageStartDate: new Date(Date.UTC(this.editStartDate.getFullYear(),
                                          this.editStartDate.getMonth(),
                                          this.editStartDate.getDate())),
      outageDate: "",
      outageEndDate: new Date(Date.UTC(this.editEndDate.getFullYear(),
                                        this.editEndDate.getMonth(),
                                        this.editEndDate.getDate())),
      createdBy: userName ? userName : "",
      createdDate: new Date(),
      modifiedBy: "",
      generalComments: ""
    }
    this.outageService.addOutage(outageObj).subscribe(response => {
      this.toggleoutageDialog();
    this.alertService.openAlert("Success!", "Added successfully!", "success");
    this.loadOutageGridData();
    }, error => {        
      this.toggleoutageDialog();
      this.loadOutageGridData();
    })
  }

  //create array only for modified fields
  tempPatchArrayObj: any = [];
  bindOutagePatchObject(outage: Outage) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (outage.siteName != this.siteName) {
      this.tempPatchArrayObj.push(this.createPatchObject("siteName", outage.siteName));
    }
    if (outage.unitName != this.unitName) {
      this.tempPatchArrayObj.push(this.createPatchObject("unitName", outage.unitName));
    }
    if (outage.name != this.name) {
      this.tempPatchArrayObj.push(this.createPatchObject("name", this.outageNameModifed));
    }
    if (outage.comments != this.comments) {
      this.tempPatchArrayObj.push(this.createPatchObject("comments", outage.comments));
    }
    if (outage.outageStartDate != this.outageStartDate){
      this.tempPatchArrayObj.push(this.createPatchObject("outageStartDate", outage.outageStartDate));
    }
    if (outage.outageEndDate != this.outageEndDate){
      this.tempPatchArrayObj.push(this.createPatchObject("outageEndDate", outage.outageEndDate));
    }

    this.tempPatchArrayObj.push(this.createPatchObject("modifiedDate", new Date()))

    if (this.tempPatchArrayObj.length > 1) {
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }

    return patchArrayObj;
  }

  createPatchObject(columnName: string, value: any) {
    return [
      {
        "value": value,
        "path": "/" + columnName,
        "op": "replace"
      }
    ]
  }

  validateoutageDialog() {
    var isValid = true;
    let site = this.selectedSite ? this.selectedSite.name : "";
    if (site) {
      this.outageDialog.isSiteEmpty = false;
    } else {
      isValid = false;
      this.outageDialog.isSiteEmpty = true;
    }
    let unit = this.selectedUnit ? this.selectedUnit.unitName : "";
    if (unit) {
      this.outageDialog.isUnitEmpty = false;
    } else {
      isValid = false;
      this.outageDialog.isUnitEmpty = true;
    }
    let startDate = this.editStartDate ? this.editStartDate : "";
    if (startDate) {
      this.outageDialog.isStartDateEmpty = false;
    } else {
      isValid = false;
      this.outageDialog.isStartDateEmpty = true;
    }
    let endDate = this.editEndDate ? this.editEndDate : "";
    if (endDate) {
      this.outageDialog.isEndDateEmpty = false;
    } else {
      isValid = false;
      this.outageDialog.isEndDateEmpty = true;
    }
    if ((this.editOutageComment == null || this.editOutageComment === "") && this.editing) {
      this.outageDialog.isCommentEmpty = true;
      isValid = false;
    }
    return isValid;
  }
  //#endregion
  
  deleteConfirmation = {
    opened: false
  }

  deleteRow(obj: any) {
    this.deleteObj = {};
    this.deleteObj = {
      id: obj.id
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this outage?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.deleteConfirm();
      }
    });
  }

  deleteConfirm() {
    if (this.deleteObj.id) {
      this.tempPatchArrayObj = [];
      var patchArrayObj = [];
      this.tempPatchArrayObj.push(this.createPatchObject("modifiedDate", new Date()));
      this.tempPatchArrayObj.push(this.createPatchObject("active", false));
      if (this.tempPatchArrayObj.length > 1) {
        for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(this.tempPatchArrayObj[i][0]);
        }
      }
      this.outageService.patchUpdate(this.deleteObj.id, patchArrayObj).subscribe(response => {
        this.close();
          this.alertService.openAlert("Deleted!", "Outage deleted successfully!", "success");
          this.loadOutageGridData();
      }, error => {
        this.close();
      })
    }
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this outage?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'p-button-success p-button-sm',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        this.tempPatchArrayObj = [];
        var patchArrayObj = [];
        this.tempPatchArrayObj.push(this.createPatchObject("modifiedDate", new Date()));
        this.tempPatchArrayObj.push(this.createPatchObject("active", true));
        if (this.tempPatchArrayObj.length > 1) {
          for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
            patchArrayObj.push(this.tempPatchArrayObj[i][0]);
          }
        }
        this.outageService.patchUpdate(obj.id, patchArrayObj).subscribe(response => {
          this.close();
            this.alertService.openAlert("Activated!", "Outage reactivated successfully!", "success");
            this.loadOutageGridData();
        }, error => {
          this.close();
        })
      }
    });
  }

  close() {
    this.deleteConfirmation.opened = false;
    this.deleteObj = {};
  }
}
