export class SearchViewModel{
  unitId: number;
  outageId: number;
  vesselId: number;
  zoneId: number;
  productType: string;
  productPosition: string;
  active: boolean;
}

export class AnchorRefractoryViewModel {
  id: number;
  vesselEstimateRevisionId?: number = 0;
  siteUnitId: number;
  outageId: number;
  vesselId: number;
  zoneId: number;
  productId: number;
  productPosition: string;
  area: number;
  thickness: number;
  anchorSpacing: string;
  qty: number;
  productCost: number;
  usageFactor: number;
  volume: number;
  baseWeight: number;
  createdDate: Date;
  active: boolean;
}

export class ZoneCalcFactorViewModel {
  hfGeometricCalcFactor: number;
  bkGeometricCalcFactor: number;
}

export class VesselProductViewModel {
  id: number;
  siteUnitId: number;
  outageId: number;
  vesselId: number;
  productId: number;
  productCategoryId: number;
  quantity: number;
  labourhours: number;
  amountWorker: number;
  cost: number;
  createdDate: Date;
  active: boolean;
}
